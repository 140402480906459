import React from 'react';
import ResourcesCard from './ResourcesCard';
import '../../css/education.scss';
import NoGoals from '../Goals/NoGoals';
import rightellipse from "../../assets/images/rightEllipse.png"
import leftellipse from "../../assets/images/leftEllipse.png"
class EducationCardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isToggleOn: {}};

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(resource) {
    this.setState(prevState => ({
      isToggleOn: {
        ...this.state.isToggleOn,
        [resource.id]: !this.state.isToggleOn[resource.id]
      }
    }));
  }

  render() {
   


    const {
      renderBookmarks,
      postBookmarkIds,
      selectedDisorder,
      bookmarksData,
      setBookmarks,
      bookmarksIds,
      setBookmarksIds,
      lang,
      filters,
      data,
      resourcesData,
      bookmarks,
      loading,
      setBookmarksData,
      isVideo,
      i18n,
      setLoading,
      setBookmarkLoading,
      isBookMarked,
      bookmarkLoading,
      t
    } = this.props;

   
    return (
      <div className="cards-row2222">
        {loading ? (
          <div
            className="d-flex justify-content-center"
            style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only">{t('summaryPage.loading')}</span>
            </div>
          </div>
        ) : (
            (data.length > 0 ? 
              <div className='cards-row'  id="accordion">
              {data.map(resource => (
              <div
                // className={`resources-card highlight ${
                // isVideo ? 'resource-card-video' : ''
                // } button-bottom-grid ${
                // resource?.qaulifiers?.type?.resourceType
                // || 'default'
                // }-icon`}
                className={`resources-card 
                ${isVideo ? 'resource-card-video' : ''}  button-bottom-grid`
                }
                    // data-toggle="collapse" href={`#collapseOne-${resource.id}`}
              >
                <div className='botton-right-image'>
                  <img
                      src={rightellipse}
                      className="right-ellipse-send-action-plan green-leaf-image"
                    />
                    <img
                      src={leftellipse}
                      className="left-ellipse-send-action-plan gray-leaf-image"
                    />
                </div>
                <ResourcesCard
                  i18n={i18n}
                  isVideo={isVideo}
                  postBookmarkIds={postBookmarkIds}
                  resource={resource}
                  disorder={selectedDisorder}
                  bookmarks={bookmarks}
                  setBookmarks={setBookmarks}
                  bookmarksIds={bookmarksIds}
                  setBookmarksIds={setBookmarksIds}
                  lang={lang === 'en' ? 'en' : 'french'}
                  setLoading={setLoading}
                  setBookmarkLoading={setBookmarkLoading}
                  bookmarkLoading={bookmarkLoading}
                />
              </div>
            ))}
            </div>
            :
          <div
            className="d-flex justify-content-center"
            style={{ height:'100vh',marginTop:'3rem', flexDirection: 'column' }}
          >
            <NoGoals msg={t('education.noResources')} showButton={false} />
            {/* <h3>{t('education.noResources')}</h3> */}
          </div>
          )
        )
        }
      </div>
    )
  }
}

export default EducationCardList;
