const store = require("store")
const lang = store.get("lang") ? store.get("lang") : "en"

export const disorderNames = {
  Depression: {
    en: "Depression",
    fr: "La dépression",
  },
  "General Anxiety Disorder": {
    en: "General Anxiety Disorder",
    fr: "Trouble Anxieux Général",
  },
  ADHD: {
    en: "ADHD",
    fr: "TDAH",
  },
  "Alcohol and Substance Abuse": {
    en: "Alcohol and Substance Abuse",
    fr: "Abus d'alcool et de substances",
  },
  "Anger Problems": {
    en: "Anger Problems",
    fr: "Problèmes de colère",
  },
  "Eating Disorder": {
    en: "Eating Disorder",
    fr: "Trouble de l'alimentation",
  },
  "Sleep Disorder (Insomnia)": {
    en: "Sleep Disorder (Insomnia)",
    fr: "Trouble du sommeil (insomnie)",
  },
  "Mania/Hypomania": {
    en: "Bipolar Disorder",
    fr: "Trouble bipolaire",
  },
  "Panic Disorder": {
    en: "Panic Disorder",
    fr: "Trouble panique",
  },
  PTSD: {
    en: "PTSD",
    fr: "SSPT",
  },
  "Social Anxiety": {
    en: "Social Anxiety",
    fr: "Anxiété sociale",
  },
  OCD: {
    en: "OCD",
    fr: "TOC",
  },
  "Obsessive Compulsive Disorder": {
    en: "Obsessive Compulsive Disorder",
    fr: "Trouble obsessionnel compulsif",
  },
  "Adjustment Disorder": {
    en: "Adjustment Disorder",
    fr: "Trouble d'adaptation",
  },
}

export const listData1 = [
  {
    disorder: "Depression",
    symptoms: {
      en: [
        {
          heading: "Thoughts",
          list: [
            "Trouble concentrating",
            "Trouble making decisions",
            "Trouble remembering",
            "Thoughts of harming yourself",
            "I'm a failure.",
            "I'm worthless.",
            "I'm a burden.",
            "Delusions and/or hallucinations can also occur in cases of severe depression",
          ],
        },
        {
          heading: "Physical problems",
          list: [
            "Tiredness or lack of energy",
            "Unexplained aches and pains",
            "Headaches",
            "Changes in appetite",
            "Digestive problems",
            "Weight loss",
            "Weight gain",
            "Changes in sleep - sleeping too little or too much",
            "Sexual problems",
          ],
        },
        {
          heading: "Feelings",
          list: [
            "Sadness",
            "Hopelessness",
            "Guilt",
            "Moodiness",
            "Restlessness, irritability",
            "Loss of interest in friends, family, and favorite activities, including sex",
          ],
        },
        {
          heading: "Behaviours",
          list: [
            "Withdrawing from people",
            "Substance abuse",
            "Missing work, school or other commitments",
            "Attempts to harm yourself",
          ],
        },
      ],
      fr: [
        {
          heading: "Pensées",
          list: [
            "Problème de concentration",
            "Difficulté à prendre des décisions",
            "Problème de mémoire",
            "Pensées de vous faire du mal",
            "Je suis une erreur.",
            "Je ne vaux rien.",
            "Je suis un fardeau.",
            "Des idées délirantes et/ou des hallucinations peuvent également survenir en cas de dépression sévère",
          ],
        },
        {
          heading: "Problèmes physiques",
          list: [
            "Fatigue ou manque d'énergie",
            "Douleurs et douleurs inexpliquées",
            "Maux de tête",
            "Changements d'appétit",
            "Problèmes digestifs",
            "Perte de poids",
            "Gain de poids",
            "Changements de sommeil - dormir trop peu ou trop",
            "Problèmes sexuels",
          ],
        },
        {
          heading: "Sentiments",
          list: [
            "Tristesse",
            "Désespoir",
            "Culpabilité",
            "Humeur changeante",
            "Agitation, irritabilité",
            "Perte d'intérêt pour les amis, la famille et les activités favorites, y compris le sexe",
          ],
        },
        {
          heading: "Comportements",
          list: [
            "Se retirer des gens",
            "Abus de substances",
            "Absence au travail, à l'école ou à d'autres engagements",
            "Tente de se faire du mal",
          ],
        },
      ],
    },
    talktherapy: {
      en: [
        "Cognitive Behavioural Therapy (CBT)",
        "Interpersonal Therapy (IPT)",
        "Mindfulness Based Cognitive Therapy (MBCT)",
        "Psychodynamic Therapy",
        "Problem-Solving Therapy",
      ],
      fr: [
        "Thérapie cognitivo-comportementale (TCC)",
        "Thérapie interpersonnelle (TIP)",
        "Thérapie cognitive basée sur la pleine conscience (MBCT)",
        "Thérapie psychodynamique",
        "Thérapie de résolution de problèmes",
      ],
    },
    selfcaretips: {
      en: [
        "Exercise can relieve symptoms",
        "Recommended website: moodgym.anu.edu.au (A free interactive site for depression based on CBT and IPT)",
        "Recommended book: Mind Over Mood: Change How You Feel by Changing the Way You Think, by Dennis",
      ],
      fr: [
        "L'exercice peut soulager les symptômes",
        "Site Web recommandé: moodgym.anu.edu.au (Un site interactif gratuit pour la dépression basé sur la TCC et la TIP)",
        "Livre recommandé: Mind Over Mood: Change How You Feel by Changing the Way You Think, par Dennis",
      ],
    },
    supports: {
      en: [
        "Mood Disorders Association of Ontario",
        "Depression Hurts",
        "Everyday Health",
        "Relief - Depression",
      ],
      fr: [
        "Association des troubles de l'humeur de l'Ontario",
        "La dépression fait mal",
        "Santé quotidienne",
        "Relief - Dépression",
      ],
    },
  },

  {
    disorder: "Mania/Hypomania",
    symptoms: {
      en: [
        {
          list: [
            "Abnormally upbeat, jumpy or wired",
            "Increased activity, energy or agitation",
            "Exaggerated sense of well-being and self-confidence (euphoria)",
            "Decreased need for sleep",
            "Unusual talkativeness",
            "Racing thoughts",
            "Distractibility",
            "Poor decision-making — for example, going on buying sprees, taking sexual risks or making foolish investments",
            "Delusion of grandeur – for example believing that you are the messiah or have special powers",
          ],
        },
      ],
      fr: [
        {
          list: [
            "Anormalement joyeux, nerveux ou tendu",
            "Augmentation de l'activité, de l'énergie ou de l'agitation",
            "Sens exagéré de bien-être et de confiance en soi (euphorie)",
            "Besoin de sommeil réduit",
            "Parler de manière inhabituelle",
            "Pensées rapides",
            "Distractibilité",
            "Mauvaise prise de décision — par exemple, faire des achats compulsifs, prendre des risques sexuels ou faire des investissements insensés",
            "Délire de grandeur – par exemple, croire que vous êtes le messie ou que vous avez des pouvoirs spéciaux",
          ],
        },
      ],
    },
    talktherapy: {
      en: [
        "Psychoeducation",
        "Cognitive Behavioural Therapy (CBT)",
        "Interpersonal Therapy (IPT)",
        "Dialectical Behaviour Therapy (DBT)",
        "Marriage and Family Therapy",
      ],
      fr: [
        "Psychoéducation",
        "Thérapie cognitivo-comportementale (TCC)",
        "Thérapie interpersonnelle (TIP)",
        "Thérapie comportementale dialectique (TCD)",
        "Thérapie conjugale et familiale",
      ],
    },
    selfcaretips: {
      en: [
        "Getting enough sleep is essential to controlling the disorder",
        "No alcohol or drugs",
        "A program of guided CBT led by a professional may be helpful",
        "Consider using a mood monitor such as www.findingoptimism.com",
        "Recommended resource: Coping with Bipolar Disorder, a free 8-module workbook developed by the Centre for Clinical Interventions, focused on preventing and managing relapses, mania and other key symptoms associated with Bipolar Disorder",
        "www.beatingbipolar.org (free interactive psychoeducation site)",
        "Recommended book: The Bipolar Disorder Survival Guide (2nd ed.), by David J. Miklowitz",
      ],
      fr: [
        "Dormir suffisamment est essentiel pour contrôler le trouble",
        "Pas d'alcool ni de drogues",
        "Un programme de TCC guidé par un professionnel peut être utile",
        "Envisagez d'utiliser un moniteur d'humeur tel que www.findingoptimism.com",
        "Ressource recommandée: Coping with Bipolar Disorder, un manuel gratuit en 8 modules développé par le Centre for Clinical Interventions, axé sur la prévention et la gestion des rechutes, de la manie et d'autres symptômes clés associés au trouble bipolaire",
        "www.beatingbipolar.org (site gratuit de psychoéducation interactive)",
        "Livre recommandé: The Bipolar Disorder Survival Guide (2ème éd.), par David J. Miklowitz",
      ],
    },
    supports: {
      en: [
        "Surviving Manic Depression: A Manual on Bipolar Disorder for Patients, Families, and Providers",
        "Take Charge of Bipolar Disorder: A 4-Step Plan for You and Your Loved Ones to Manage the Illness and Create Lasting Stability",
        "Relief - Bipolar Disorder",
        "National Institute of Mental Health",
      ],
      fr: [
        "Surviving Manic Depression: A Manual on Bipolar Disorder for Patients, Families, and Providers",
        "Take Charge of Bipolar Disorder: A 4-Step Plan for You and Your Loved Ones to Manage the Illness and Create Lasting Stability",
        "Relief - Trouble bipolaire",
        "Institut national de la santé mentale",
      ],
    },
  },

  {
    disorder: "Alcohol and Substance Abuse",
    symptoms: {
      en: [
        {
          list: [
            "Feeling that you have to use the drug regularly",
            "Having intense urges for the drug",
            "Needing more of the drug to get the same effect",
            "Taking larger amounts of the drug over a longer period of time than intended",
            "Spending money on the drug, even though one can't afford it",
            "Not meeting obligations and work responsibilities, or cutting back on social or recreational activities because of drug use",
            "Continuing to use the drug, even though it's causing problems in one’s life or causing physical or psychological harm",
            "Driving or doing other risky activities when under the influence of the drug",
            "Failing in attempts to stop using the drug",
            "Experiencing withdrawal symptoms when one attempts to stop taking the drug",
          ],
        },
      ],
      fr: [
        {
          list: [
            "Sentiment de devoir utiliser la drogue régulièrement",
            "Avoir des envies intenses pour la drogue",
            "Avoir besoin de plus de drogue pour obtenir le même effet",
            "Prendre des quantités plus importantes de drogue sur une plus longue période que prévu",
            "Dépenser de l'argent pour la drogue, même si on ne peut pas se le permettre",
            "Ne pas remplir ses obligations et responsabilités professionnelles, ou réduire ses activités sociales ou récréatives à cause de la consommation de drogue",
            "Continuer à utiliser la drogue, même si cela cause des problèmes dans la vie ou des dommages physiques ou psychologiques",
            "Conduire ou faire d'autres activités risquées sous l'influence de la drogue",
            "Échouer dans les tentatives d'arrêter d'utiliser la drogue",
            "Éprouver des symptômes de sevrage lorsqu'on tente d'arrêter de prendre la drogue",
          ],
        },
      ],
    },
    talktherapy: {
      en: [
        "Cognitive Behavioural Therapy (CBT)",
        "Motivational Interviewing (MI)",
        "Acceptance and Commitment Therapy (ACT)",
        "Marriage and Family Therapy",
        "Twelve-Step Facilitation",
        "Addiction Counseling",
      ],
      fr: [
        "Thérapie cognitivo-comportementale (TCC)",
        "Entretien motivationnel (MI)",
        "Thérapie d'acceptation et d'engagement (ACT)",
        "Thérapie conjugale et familiale",
        "Facilitation des douze étapes",
        "Counseling en toxicomanie",
      ],
    },
    selfcaretips: {
      en: [
        "The goal for controlling alcohol use must be individualized: either abstinence or reduction to safer amount",
        "There are no “safe” limits for most other substances; a single use of cocaine could cause heart problems, while a single use of an IV drug could cause an infection like hepatitis or HIV; the goal for drug misuse treatment is usually abstinence",
        "Recommended book: The Mindfulness Workbook for Addiction: A Guide to Coping with the Grief, Stress and Anger that Trigger Addictive Behaviors by Rebecca E. Williams & Julie S. Kraft",
      ],
      fr: [
        "L'objectif de la maîtrise de la consommation d'alcool doit être individualisé : soit l'abstinence, soit la réduction à une quantité plus sûre",
        "Il n'y a pas de limites « sûres » pour la plupart des autres substances ; une seule utilisation de cocaïne peut causer des problèmes cardiaques, tandis qu'une seule utilisation d'une drogue IV peut causer une infection comme l'hépatite ou le VIH ; l'objectif du traitement de l'abus de drogues est généralement l'abstinence",
        "Livre recommandé: The Mindfulness Workbook for Addiction: A Guide to Coping with the Grief, Stress and Anger that Trigger Addictive Behaviors par Rebecca E. Williams & Julie S. Kraft",
      ],
    },
    supports: {
      en: [
        "The Addiction Workbook: A Step-by-Step Guide to Quitting Alcohol & Drugs",
        "Alcohol Anonymous Online chat",
        "Alcoholics Anonymous",
        "Canadian Centre on Substance Abuse",
      ],
      fr: [
        "The Addiction Workbook: A Step-by-Step Guide to Quitting Alcohol & Drugs",
        "Chat en ligne des Alcooliques Anonymes",
        "Alcooliques Anonymes",
        "Centre canadien sur les dépendances",
      ],
    },
  },

  {
    disorder: "Panic Disorder",
    symptoms: {
      en: [
        {
          list: [
            "A sense of detachment from the world (derealization)",
            "A detachment from the self (depersonalization)",
            "The fear of dying or losing control",
            "Sense of impending doom or danger",
            "Fear of loss of control or death",
            "Rapid, pounding heart rate",
            "Sweating",
            "Trembling or shaking",
            "Shortness of breath or tightness in your throat",
            "Chills",
            "Hot flashes",
            "Nausea",
            "Abdominal cramping",
            "Chest pain",
            "Headache",
            "Dizziness, lightheadedness or faintness",
            "Numbness or tingling sensation",
            "Feeling of unreality or detachment",
          ],
        },
      ],
      fr: [
        {
          list: [
            "Un sentiment de détachement du monde (déréalisation)",
            "Un détachement de soi-même (dépersonnalisation)",
            "La peur de mourir ou de perdre le contrôle",
            "Sentiment d'imminence de catastrophe ou de danger",
            "Peur de perdre le contrôle ou de mourir",
            "Rythme cardiaque rapide et battant",
            "Transpiration",
            "Tremblements ou secousses",
            "Essoufflement ou sensation de serrement dans la gorge",
            "Frissons",
            "Bouffées de chaleur",
            "Nausées",
            "Crampes abdominales",
            "Douleur thoracique",
            "Maux de tête",
            "Étourdissements, sensation d'être étourdi ou de s'évanouir",
            "Engourdissement ou sensation de picotement",
            "Sentiment d'irréalité ou de détachement",
          ],
        },
      ],
    },
    selfcaretips: {
      en: [
        "Determine if there is danger such as bullying, physical, sexual, or emotional abuse",
        "Reduce caffeine and alcohol intake and keep a diary of anxiety episodes",
        "Practice relaxation methods such as deep breathing exercises",
        "Set goals and homework tasks for reducing anxiety, using online CBT websites or workbooks",
        "Recommended website: www.cci.health.wa.gov.au/resources/infopax.cfm?Info_ID=44, a free Panic Disorder CBT website with information, worksheets, and suggested exercises",
        "Recommended book: Anxiety & Phobia Workbook (6th ed.) by Edmund J. Bourne",
      ],
      fr: [
        "Déterminer s'il y a un danger tel que le harcèlement, les abus physiques, sexuels ou émotionnels",
        "Réduire la consommation de caféine et d'alcool et tenir un journal des épisodes d'anxiété",
        "Pratiquer des méthodes de relaxation comme des exercices de respiration profonde",
        "Fixer des objectifs et des tâches à domicile pour réduire l'anxiété, en utilisant des sites web ou des cahiers de TCC en ligne",
        "Site web recommandé: www.cci.health.wa.gov.au/resources/infopax.cfm?Info_ID=44, un site web gratuit sur le TCC pour le trouble panique avec des informations, des fiches de travail et des exercices suggérés",
        "Livre recommandé: Anxiety & Phobia Workbook (6th ed.) par Edmund J. Bourne",
      ],
    },
    supports: {
      en: [
        "The Anxiety & Phobia Workbook (6th ed.)",
        "Self-help strategies for panic disorder",
        "Centre for Addiction and Mental Health",
        "mindyourmind",
      ],
      fr: [
        "The Anxiety & Phobia Workbook (6th ed.)",
        "Stratégies d'auto-assistance pour le trouble panique",
        "Centre de toxicomanie et de santé mentale",
        "mindyourmind",
      ],
    },
  },

  {
    disorder: "Social Anxiety",
    symptoms: {
      en: [
        {
          list: [
            "Fear of situations in which you may be judged negatively",
            "Worry about embarrassing or humiliating yourself",
            "Intense fear of interacting or talking with strangers",
            "Fear that others will notice that you look anxious",
            "Fear of physical symptoms that may cause you embarrassment, such as blushing, sweating, trembling or having a shaky voice",
            "Avoidance of doing things or speaking to people out of fear of embarrassment",
            "Avoidance of situations where you might be the center of attention",
            "Anxiety in anticipation of a feared activity or event",
            "Intense fear or anxiety during social situations",
            "Analysis of your performance and identification of flaws in your interactions after a social situation",
            "Expectation of the worst possible consequences from a negative experience during a social situation",
          ],
        },
      ],
      fr: [
        {
          list: [
            "Peur des situations où vous pourriez être jugé négativement",
            "Inquiétude à propos de l'embarras ou de l'humiliation de soi",
            "Peur intense d'interagir ou de parler avec des étrangers",
            "Peur que les autres remarquent que vous avez l'air anxieux",
            "Peur des symptômes physiques qui pourraient vous causer de l'embarras, comme rougir, transpirer, trembler ou avoir une voix tremblante",
            "Évitement de faire des choses ou de parler aux gens par peur de l'embarras",
            "Évitement des situations où vous pourriez être au centre de l'attention",
            "Anxiété en anticipation d'une activité ou d'un événement redouté",
            "Peur ou anxiété intense lors des situations sociales",
            "Analyse de votre performance et identification des défauts dans vos interactions après une situation sociale",
            "Attente des pires conséquences possibles d'une expérience négative lors d'une situation sociale",
          ],
        },
      ],
    },
    talktherapy: {
      en: [
        "Cognitive Behavioural Therapy (CBT)",
        "Attention Retraining",
        "Interpersonal Therapy (IPT)",
        "Psychodynamic Therapy",
      ],
      fr: [
        "Thérapie cognitivo-comportementale (TCC)",
        "Reprogrammation de l'attention",
        "Thérapie interpersonnelle (TIP)",
        "Thérapie psychodynamique",
      ],
    },
    selfcaretips: {
      en: [
        "Reduce caffeine and alcohol and keep a diary of anxiety episodes.",
        "Ask your care provider about the availability of guided CBT with the help of a professional.",
        "Recommended website: www.cci.health.wa.gov.au/resources/infopax.cfm?Info_ID=40 (A free CBT program for SAD with information, worksheets, and suggested exercises)",
        "Recommended book: The Shyness & Social Anxiety Workbook: Proven Techniques for Overcoming Your Fears, (2nd ed.) by Martin M. Antony & Richard P. Swinson",
      ],
      fr: [
        "Réduisez la caféine et l'alcool et tenez un journal des épisodes d'anxiété.",
        "Demandez à votre fournisseur de soins la disponibilité de la TCC guidée avec l'aide d'un professionnel.",
        "Site web recommandé: www.cci.health.wa.gov.au/resources/infopax.cfm?Info_ID=40 (Un programme gratuit de TCC pour le trouble anxieux social avec des informations, des fiches de travail et des exercices suggérés)",
        "Livre recommandé: The Shyness & Social Anxiety Workbook: Proven Techniques for Overcoming Your Fears, (2e éd.) par Martin M. Antony & Richard P. Swinson",
      ],
    },
    supports: {
      en: [
        "10 Simple Solutions to Shyness: How to Overcome Shyness, Social Anxiety, & Fear of Public Speaking",
        "Anxiety Canada resources to help",
        "Centre for Addiction and Mental Health",
        "Canadian Mental Health Association",
      ],
      fr: [
        "10 Solutions Simples contre la Timidité : Comment Surmonter la Timidité, l'Anxiété Sociale et la Peur de Parler en Public",
        "Ressources d'Anxiété Canada pour aider",
        "Centre de toxicomanie et de santé mentale",
        "Association canadienne pour la santé mentale",
      ],
    },
  },

  {
    disorder: "PTSD",
    symptoms: {
      en: [
        {
          heading: "Intrusive memories",
          list: [
            "Recurrent, unwanted distressing memories of the traumatic event",
            "Reliving the traumatic event as if it were happening again (flashbacks)",
            "Upsetting dreams or nightmares about the traumatic event",
            "Severe emotional distress or physical reactions to something that reminds you of the traumatic event",
          ],
        },
        {
          heading: "Avoidance",
          list: [
            "Trying to avoid thinking or talking about the traumatic event",
            "Avoiding places, activities or people that remind you of the traumatic event",
          ],
        },
        {
          heading: "Changes in physical and emotional reactions",
          list: [
            "Being easily startled or frightened",
            "Always being on guard for danger",
            "Self-destructive behavior, such as drinking too much or driving too fast",
            "Trouble sleeping",
            "Trouble concentrating",
            "Irritability, angry outbursts or aggressive behavior",
            "Overwhelming guilt or shame",
          ],
        },
        {
          heading: "Negative changes in thinking and mood",
          list: [
            "Negative thoughts about yourself, other people or the world",
            "Hopelessness about the future",
            "Memory problems, including not remembering important aspects of the traumatic event",
            "Difficulty maintaining close relationships",
            "Feeling detached from family and friends",
            "Lack of interest in activities you once enjoyed",
            "Difficulty experiencing positive emotions",
            "Feeling emotionally numb",
          ],
        },
      ],
      fr: [
        {
          heading: "Souvenirs intrusifs",
          list: [
            "Souvenirs récurrents et indésirables du traumatisme",
            "Revivre le traumatisme comme s'il se produisait à nouveau (flashbacks)",
            "Cauchemars perturbants à propos du traumatisme",
            "Détresse émotionnelle sévère ou réactions physiques à quelque chose qui vous rappelle le traumatisme",
          ],
        },
        {
          heading: "Évitement",
          list: [
            "Tenter d'éviter de penser ou de parler du traumatisme",
            "Éviter les lieux, les activités ou les personnes qui rappellent le traumatisme",
          ],
        },
        {
          heading: "Changements dans les réactions physiques et émotionnelles",
          list: [
            "Facilité à être facilement effrayé ou effrayé",
            "Être toujours sur ses gardes contre le danger",
            "Comportement autodestructeur, comme boire trop ou conduire trop vite",
            "Problèmes de sommeil",
            "Difficulté à se concentrer",
            "Irritabilité, explosions de colère ou comportement agressif",
            "Culpabilité ou honte accablante",
          ],
        },
        {
          heading: "Changements négatifs dans la pensée et l'humeur",
          list: [
            "Pensées négatives sur vous-même, les autres personnes ou le monde",
            "Désespoir face à l'avenir",
            "Problèmes de mémoire, y compris ne pas se souvenir des aspects importants du traumatisme",
            "Difficulté à maintenir des relations étroites",
            "Sentiment de détachement de la famille et des amis",
            "Manque d'intérêt pour les activités que vous aimiez autrefois",
            "Difficulté à éprouver des émotions positives",
            "Sentiment d'émoussement émotionnel",
          ],
        },
      ],
    },
    talktherapy: {
      en: [
        "Cognitive Behavioural Therapy (CBT)",
        "Exposure-Based Therapies",
        "Eye Movement Desensitization and Reprocessing (EMDR)",
      ],
      fr: [
        "Thérapie cognitivo-comportementale (TCC)",
        "Thérapies basées sur l'exposition",
        "Désensibilisation et retraitement par les mouvements oculaires (EMDR)",
      ],
    },
    selfcaretips: {
      en: [
        "Psychoeducation can be particularly helpful",
        "Reduce caffeine and alcohol intake and keep a diary of the trigger symptoms",
        "As treatment adjunct to treatment, use standard workbook or online PTSD CBT",
        "Recommended website: www.mentalhealthonline.org.au, a free online CBT program for PTSD (Note: Another screener is required to use this site)",
        "Recommended book: The PTSD Workbook, (2nd ed.), by Mary Beth Williams and Soili Poijula",
      ],
      fr: [
        "La psychoéducation peut être particulièrement utile",
        "Réduisez la consommation de caféine et d'alcool et tenez un journal des symptômes déclencheurs",
        "Comme complément au traitement, utilisez un cahier standard ou une TCC en ligne pour le PTSD",
        "Site web recommandé: www.mentalhealthonline.org.au, un programme gratuit de TCC en ligne pour le PTSD (Note: Un autre évaluateur est requis pour utiliser ce site)",
        "Livre recommandé: The PTSD Workbook, (2e éd.), par Mary Beth Williams et Soili Poijula",
      ],
    },
    supports: {
      en: [
        "PTSD and CPTSD Self-Help Guide",
        "Post-Traumatic Stress Disorder (PTSD) and Complex Post-Traumatic Stress Disorder (CPTSD)",
        "Legion - Mental Health and PTSD",
        "PTSD Association of Canada",
      ],
      fr: [
        "Guide d'auto-assistance pour le PTSD et le CPTSD",
        "Trouble de stress post-traumatique (PTSD) et trouble de stress post-traumatique complexe (CPTSD)",
        "Legion - Santé mentale et PTSD",
        "Association du PTSD du Canada",
      ],
    },
  },

  {
    disorder: "General Anxiety Disorder",
    symptoms: {
      en: [
        {
          list: [
            "Restlessness or feeling 'keyed up' or 'on edge'",
            "Fatigue and feeling easily run down",
            "Difficulty concentrating",
            "Irritability",
            "Muscle tension",
            "Sleep disturbance (difficulty falling or staying asleep or unsatisfying sleep)",
          ],
        },
      ],
      fr: [
        {
          list: [
            "Agitation ou sensation d'être 'sur les nerfs' ou 'tendu'",
            "Fatigue et sensation de fatigue facile",
            "Difficulté à se concentrer",
            "Irritabilité",
            "Tension musculaire",
            "Troubles du sommeil (difficulté à s'endormir ou à rester endormi, sommeil insatisfaisant)",
          ],
        },
      ],
    },
    talktherapy: {
      en: ["Cognitive Behavioural Therapy (CBT)"],
      fr: ["Thérapie cognitivo-comportementale (TCC)"],
    },
    selfcaretips: {
      en: [
        "Bullying or physical, sexual or emotional abuse can all worsen GAD symptoms. If you are experiencing any of these, report the issue to the appropriate authority",
        "Reduce caffeine and alcohol intake as much as possible",
        "Keep a diary of the frequency, severity and content of anxiety episodes",
        "Try relaxation methods such as deep breathing exercises",
        "Recommended workbook: Anxiety & Phobia Workbook, 6th Edition by Edmund J. Bourne",
      ],
      fr: [
        "Le harcèlement ou les abus physiques, sexuels ou émotionnels peuvent aggraver les symptômes du TAG. Si vous rencontrez l'un de ces problèmes, signalez-le à l'autorité compétente",
        "Réduisez autant que possible la consommation de caféine et d'alcool",
        "Tenez un journal de la fréquence, de la gravité et du contenu des épisodes d'anxiété",
        "Essayez des méthodes de relaxation comme des exercices de respiration profonde",
        "Livre recommandé : Anxiety & Phobia Workbook, 6th Edition par Edmund J. Bourne",
      ],
    },
    supports: {
      en: [
        "Anxiety Resources",
        "The Anxiety Toolkit",
        "Clinical Research Unit for Anxiety and Depression",
        "National Mental Health Inclusion Network",
      ],
      fr: [
        "Ressources pour l'anxiété",
        "La trousse à outils de l'anxiété",
        "Unité de recherche clinique sur l'anxiété et la dépression",
        "Réseau national pour l'inclusion en santé mentale",
      ],
    },
  },

  {
    disorder: "Eating Disorder",
    symptoms: {
      en: [
        {
          list: [
            "Noticeably high weight loss",
            "Persistent fear of weight gain",
            "Avoidance of high-calorie foods",
            "Feeling overweight even at lowest weight",
            "Dieting/Vomiting/Over-Exercising",
            "Self-esteem linked to body appearance",
            "Binge Eating",
            "Unusually Rapid Eating",
            "Uncomfortably Full After Eating",
            "Eating while not hungry",
            "Embarrassment Around eating",
            "Depression/Guilt After Overeating",
            "Trouble Controlling Eating",
          ],
        },
      ],
      fr: [
        {
          list: [
            "Perte de poids notable",
            "Peur persistante de prendre du poids",
            "Évitement des aliments riches en calories",
            "Sentiment de surpoids même au poids le plus bas",
            "Régimes/Vomissements/Sur-exercice",
            "Estime de soi liée à l'apparence corporelle",
            "Boulimie",
            "Alimentation anormalement rapide",
            "Inconfort après les repas",
            "Manger sans avoir faim",
            "Embarras lié à l'alimentation",
            "Dépression/Culpabilité après avoir trop mangé",
            "Difficulté à contrôler son alimentation",
          ],
        },
      ],
    },
    talktherapy: {
      en: [
        "Cognitive Behavioural Therapy (CBT)",
        "Interpersonal Therapy (IPT)",
        "Dialectical Behaviour Therapy (DBT)",
        "Marriage and Family Therapy",
        "Psychodynamic Therapy",
      ],
      fr: [
        "Thérapie cognitivo-comportementale (TCC)",
        "Thérapie interpersonnelle (TIP)",
        "Thérapie dialectique comportementale (TDC)",
        "Thérapie de couple et familiale",
        "Thérapie psychodynamique",
      ],
    },
    selfcaretips: {
      en: [
        "Eating disorders are treatable, but often take a long time to treat, with occasional relapses",
        "Ensure client's medical status is being regularly monitored by a physician",
        "Nutritional Rehabilitation is useful, involving nutritional counselling and possibly supervised meals and monitoring to avoid restricting, bingeing or vomiting",
        "Log food intake, feelings after eating, eating disorder behaviours, and coping skills used with a free app like www.recoveryrecord.com/",
        "Set goals and homework for reducing eating disorder symptoms, using a website or workbook",
        "See: www.cci.health.wa.gov.au/resources/infopax.cfm?Info_ID=48 and www.cci.health.wa.gov.au/resources/infopax.cfm?Info_ID=49, a two-part free CBT-based program for disordered eating",
        "Recommended book: 8 Keys to Recovery from an Eating Disorder: Effective Strategies from Therapeutic Practice and Personal Experience by Carolyn Costin & Gwen Grabb",
      ],
      fr: [
        "Les troubles alimentaires sont traitables, mais prennent souvent beaucoup de temps à traiter, avec parfois des rechutes",
        "Assurez-vous que l'état médical du client est régulièrement surveillé par un médecin",
        "La réhabilitation nutritionnelle est utile, impliquant un conseil nutritionnel et éventuellement des repas supervisés et une surveillance pour éviter les restrictions, les crises de boulimie ou les vomissements",
        "Enregistrez l'apport alimentaire, les sentiments après les repas, les comportements liés aux troubles alimentaires et les compétences d'adaptation utilisées avec une application gratuite comme www.recoveryrecord.com/",
        "Fixez des objectifs et des devoirs pour réduire les symptômes des troubles alimentaires, en utilisant un site web ou un cahier d'exercices",
        "Voir : www.cci.health.wa.gov.au/resources/infopax.cfm?Info_ID=48 et www.cci.health.wa.gov.au/resources/infopax.cfm?Info_ID=49, un programme CBT en deux parties gratuit pour les troubles alimentaires",
        "Livre recommandé : 8 Keys to Recovery from an Eating Disorder: Effective Strategies from Therapeutic Practice and Personal Experience par Carolyn Costin & Gwen Grabb",
      ],
    },
    supports: {
      en: [
        "National Association of Anorexia Nervosa and Associated Disorders",
        "National Eating Disorders Association",
        "Eating Disorders Online",
        "National Eating Disorder Information Centre",
      ],
      fr: [
        "Association nationale de l'anorexie nerveuse et des troubles associés",
        "Association nationale des troubles alimentaires",
        "Troubles alimentaires en ligne",
        "Centre d'information national sur les troubles alimentaires",
      ],
    },
  },

  {
    disorder: "ADHD",
    symptoms: {
      en: [
        {
          heading: "Hyperactivity",
          list: [
            "Fidgeting",
            "Being very talkative",
            "Speaking too loudly",
            "Moving constantly",
            "Difficulty remaining quiet",
          ],
        },
        {
          heading: "Emotional Problems",
          list: [
            "Trouble controlling anger",
            "Feeling unmotivated",
            "Sense of underachievement",
            "Feeling frustrated and irritable",
            "Mood swings",
          ],
        },
        {
          heading: "Inattention",
          list: [
            "Being easily distracted or frequently switching from one activity to another",
            "Overlooking details",
            "Trouble focusing on one thing",
            "Getting bored with a task quickly unless it is very enjoyable",
            "Difficulty completing school or work assignments on time",
            "Frequently losing items",
            "Poor organizational skills",
            "Missing appointments or meetings",
            "Daydreaming",
            "Having a hard time processing information as quickly as others",
            "Seeming like they are not listening when being spoken to",
          ],
        },
        {
          heading: "Impulsivity",
          list: [
            "Impatience or having a hard time waiting in lines",
            "Acting without thinking about the consequences",
            "Frequently interrupting others",
            "Speeding or getting into car accidents",
            "Overeating",
            "Substance use or abuse",
            "Problems with other addictions like shopping or gambling",
          ],
        },
      ],
      fr: [
        {
          heading: "Hyperactivité",
          list: [
            "Faire des mouvements incontrôlés",
            "Parler excessivement",
            "Parler trop fort",
            "Bouger constamment",
            "Difficulté à rester tranquille",
          ],
        },
        {
          heading: "Problèmes émotionnels",
          list: [
            "Difficulté à contrôler la colère",
            "Sentiment de démotivation",
            "Sens de sous-performance",
            "Sentiment de frustration et d'irritabilité",
            "Humeurs changeantes",
          ],
        },
        {
          heading: "Inattention",
          list: [
            "Facilement distrait ou changement fréquent d'une activité à une autre",
            "Oublier des détails",
            "Difficulté à se concentrer sur une seule chose",
            "S'ennuyer rapidement d'une tâche à moins qu'elle soit très agréable",
            "Difficulté à terminer les devoirs ou les tâches professionnelles à temps",
            "Perdre fréquemment des objets",
            "Mauvaises compétences en organisation",
            "Manquer des rendez-vous ou des réunions",
            "Rêvasser",
            "Difficulté à traiter l'information aussi rapidement que les autres",
            "Semblant de ne pas écouter lorsqu'on lui parle",
          ],
        },
        {
          heading: "Impulsivité",
          list: [
            "Impatience ou difficulté à attendre dans les files d'attente",
            "Agir sans réfléchir aux conséquences",
            "Interrompre fréquemment les autres",
            "Excès de vitesse ou accidents de voiture",
            "Manger en excès",
            "Usage ou abus de substances",
            "Problèmes avec d'autres dépendances comme le shopping ou le jeu",
          ],
        },
      ],
    },
    talktherapy: {
      en: [
        "Cognitive Behavioural Therapy (CBT)",
        "Dialectical Behaviour Therapy (DBT)",
        "Mindfulness Based Cognitive Therapy (MBCT)",
      ],
      fr: [
        "Thérapie cognitivo-comportementale (TCC)",
        "Thérapie comportementale dialectique (DBT)",
        "Thérapie cognitive basée sur la pleine conscience (MBCT)",
      ],
    },
    selfcaretips: {
      en: [
        "Set specific goals and seek strategies to achieve these goals in small, steady steps",
        "Speak with your care provider about options for guided CBT with a professional",
        "Consider using software or apps that help with organization",
        "Aim to reduce stress, as stress always exacerbates symptoms",
        "Consider a self-help strategy called psychoeducation from websites, such as www.adhdlearning.caddra.ca",
        "Recommended book: Delivered from Distraction, by Edward M. Hallowell & John J. Ratey",
      ],
      fr: [
        "Fixez des objectifs précis et cherchez des stratégies pour les atteindre par petites étapes constantes",
        "Parlez avec votre fournisseur de soins des options pour une TCC guidée avec un professionnel",
        "Envisagez d'utiliser des logiciels ou des applications qui aident à l'organisation",
        "Visez à réduire le stress, car le stress aggrave toujours les symptômes",
        "Considérez une stratégie d'auto-assistance appelée psychoéducation à partir de sites Web, tels que www.adhdlearning.caddra.ca",
        "Livre recommandé : Delivered from Distraction, par Edward M. Hallowell & John J. Ratey",
      ],
    },
    supports: {
      en: [
        "Tips for Managing Adult ADHD",
        "Delivered from Distraction: Getting the Most out of Life with Attention Deficit Disorder",
        "Centre for ADHD Awareness Canada",
        "Canadian ADHD Resource Alliance",
      ],
      fr: [
        "Conseils pour gérer le TDAH adulte",
        "Delivered from Distraction: Obtenir le meilleur de la vie avec le trouble du déficit de l'attention",
        "Centre de sensibilisation au TDAH Canada",
        "Alliance canadienne pour la ressource du TDAH",
      ],
    },
  },

  {
    disorder: "Anger Problems",
    symptoms: {
      en: [
        {
          list: [
            "Irritability",
            "Rage",
            "More energy",
            "Racing thoughts",
            "Feeling pressure in the head",
            "Tightness in the chest",
            "Palpitations",
            "Tremors",
            "Tingling",
          ],
        },
      ],
      fr: [
        {
          list: [
            "Irritabilité",
            "Colère",
            "Plus d'énergie",
            "Pensées rapides",
            "Sensation de pression dans la tête",
            "Sensation de serrage dans la poitrine",
            "Palpitations",
            "Tremblements",
            "Picotements",
          ],
        },
      ],
    },
    selfcaretips: {
      en: [
        "Practice relaxation exercises such as deep breathing",
        "Avoid alcohol and street drugs, as intoxication clearly worsens anger issues",
        "Minimize outbursts by anticipating triggers and avoiding those situations",
        "Aim to reduce stress, as stress exacerbates symptoms",
        "Exercise regularly",
        "Set goals and use resources like websites or workbooks to manage anger",
        "See: www.moodjuice.scot.nhs.uk/anger.asp, a free printable anger management workbook",
        "Recommended book: The Anger Control Workbook by Matthew McKay & Peter Rogers",
      ],
      fr: [
        "Pratiquer des exercices de relaxation comme la respiration profonde",
        "Éviter l'alcool et les drogues de rue, car l'intoxication aggrave clairement les problèmes de colère",
        "Minimiser les explosions en anticipant les déclencheurs et en évitant ces situations",
        "Viser à réduire le stress, car le stress aggrave les symptômes",
        "Faire de l'exercice régulièrement",
        "Fixer des objectifs et utiliser des ressources comme des sites Web ou des livres de travail pour gérer la colère",
        "Voir : www.moodjuice.scot.nhs.uk/anger.asp, un livre de travail gratuit sur la gestion de la colère à imprimer",
        "Livre recommandé : The Anger Control Workbook par Matthew McKay & Peter Rogers",
      ],
    },
    supports: {
      en: [
        "Controlling anger before it controls you",
        "Wellness Module 5: Anger Management",
        "Centre for Addiction and Mental Health",
        "eMentalHealth",
      ],
      fr: [
        "Contrôler la colère avant qu'elle ne vous contrôle",
        "Module de bien-être 5 : Gestion de la colère",
        "Centre de toxicomanie et de santé mentale",
        "eMentalHealth",
      ],
    },
  },

  {
    disorder: "Sleep Disorder (Insomnia)",
    symptoms: {
      en: [
        {
          list: [
            "Difficulty falling asleep at night",
            "Waking up during the night",
            "Waking up too early",
            "Not feeling well-rested after a night's sleep",
            "Daytime tiredness or sleepiness",
            "Irritability, depression or anxiety",
            "Difficulty paying attention, focusing on tasks or remembering",
            "Increased errors or accidents",
            "Ongoing worries about sleep",
          ],
        },
      ],
      fr: [
        {
          list: [
            "Difficulté à s'endormir la nuit",
            "Réveils pendant la nuit",
            "Réveil trop tôt le matin",
            "Ne pas se sentir reposé après une nuit de sommeil",
            "Fatigue ou somnolence diurne",
            "Irritabilité, dépression ou anxiété",
            "Difficulté à prêter attention, à se concentrer sur les tâches ou à se souvenir",
            "Erreurs ou accidents accrus",
            "Préoccupations constantes concernant le sommeil",
          ],
        },
      ],
    },
    talktherapy: {
      en: ["Cognitive Behavioural Therapy for Insomnia (CBT-I)", "Biofeedback"],
      fr: [
        "Thérapie cognitivo-comportementale pour l'insomnie (CBT-I)",
        "Biofeedback",
      ],
    },
    selfcaretips: {
      en: [
        "Practice relaxation exercises such as deep breathing",
        "Reduce alcohol, nicotine, and caffeine intake and get regular exercise",
        "Keep a sleep diary",
        "Set goals and homework for improving sleep hygiene, using a website or workbook",
        "Use a self-help strategy called psychoeducation to learn more about sleep www.nhlbi.nih.gov/files/docs/public/sleep/healthy_sleep.pdf",
        "Recommended book: The Insomnia Workbook: A Comprehensive Guide to Getting the Sleep You Need by Stephanie Silberman",
      ],
      fr: [
        "Pratiquez des exercices de relaxation comme la respiration profonde",
        "Réduisez la consommation d'alcool, de nicotine et de caféine et faites de l'exercice régulièrement",
        "Tenez un journal de sommeil",
        "Fixez des objectifs et des devoirs pour améliorer l'hygiène du sommeil, en utilisant un site web ou un cahier d'exercices",
        "Utilisez une stratégie d'auto-assistance appelée psychoéducation pour en savoir plus sur le sommeil www.nhlbi.nih.gov/files/docs/public/sleep/healthy_sleep.pdf",
        "Livre recommandé : The Insomnia Workbook: A Comprehensive Guide to Getting the Sleep You Need de Stephanie Silberman",
      ],
    },
    supports: {
      en: [
        "Say Good Night to Insomnia: The Six-Week, Drug-Free Program Developed At Harvard Medical School",
        "Talking Sleep - An AASM Podcast",
        "Sleep on It",
        "Canadian Sleep and Circadian Network",
      ],
      fr: [
        "Dites Bonne Nuit à l'Insomnie : Le Programme de Six Semaines sans Médicament Développé à l'École de Médecine de Harvard",
        "Talking Sleep - Un podcast de l'AASM",
        "Sleep on It",
        "Réseau Canadien du Sommeil et du Rythme Circadien",
      ],
    },
  },

  {
    disorder: "OCD",
    symptoms: {
      en: [
        {
          heading: "Obsession symptoms",
          list: [
            "Fear of being contaminated by touching objects others have touched",
            "Doubts that you've locked the door or turned off the stove",
            "Intense stress when objects aren't orderly or facing a certain way",
            "Images of driving your car into a crowd of people",
            "Thoughts about shouting obscenities or acting inappropriately in public",
            "Unpleasant sexual images",
            "Avoidance of situations that can trigger obsessions, such as shaking hands",
          ],
        },
        {
          heading: "Compulsion symptoms",
          list: [
            "Hand-washing until your skin becomes raw",
            "Checking doors repeatedly to make sure they're locked",
            "Checking the stove repeatedly to make sure it's off",
            "Counting in certain patterns",
            "Silently repeating a prayer, word or phrase",
            "Arranging your canned goods to face the same way",
          ],
        },
        {
          heading: "Common compulsion themes",
          list: [
            "Washing and cleaning",
            "Checking",
            "Counting",
            "Orderliness",
            "Following a strict routine",
            "Demanding reassurance",
          ],
        },
        {
          heading: "Common obsession themes",
          list: [
            "Fear of contamination or dirt",
            "Doubting and having difficulty tolerating uncertainty",
            "Needing things orderly and symmetrical",
            "Aggressive or horrific thoughts about losing control and harming yourself or others",
            "Unwanted thoughts, including aggression, or sexual or religious subjects",
          ],
        },
      ],
      fr: [
        {
          heading: "Symptômes d'obsessions",
          list: [
            "Peur d'être contaminé en touchant des objets touchés par d'autres",
            "Doutes quant au fait d'avoir verrouillé la porte ou éteint la cuisinière",
            "Stress intense lorsque les objets ne sont pas rangés ou orientés d'une certaine manière",
            "Images de conduire votre voiture dans une foule de personnes",
            "Pensées de crier des obscénités ou de se comporter de manière inappropriée en public",
            "Images sexuelles désagréables",
            "Évitement des situations pouvant déclencher des obsessions, comme serrer la main",
          ],
        },
        {
          heading: "Symptômes de compulsions",
          list: [
            "Se laver les mains jusqu'à ce que votre peau devienne irritée",
            "Vérifier les portes à plusieurs reprises pour s'assurer qu'elles sont verrouillées",
            "Vérifier la cuisinière à plusieurs reprises pour s'assurer qu'elle est éteinte",
            "Compter selon certains schémas",
            "Répéter silencieusement une prière, un mot ou une phrase",
            "Organiser vos conserves pour qu'elles soient orientées de la même manière",
          ],
        },
        {
          heading: "Thèmes communs des compulsions",
          list: [
            "Lavage et nettoyage",
            "Vérification",
            "Comptage",
            "Ordonnance",
            "Suivre une routine stricte",
            "Exiger des assurances",
          ],
        },
        {
          heading: "Thèmes communs des obsessions",
          list: [
            "Peur de la contamination ou de la saleté",
            "Doutes et difficultés à tolérer l'incertitude",
            "Besoin d'ordre et de symétrie",
            "Pensées agressives ou horrifiques de perdre le contrôle et de se faire du mal à soi-même ou aux autres",
            "Pensées indésirables, y compris l'agression, ou des sujets sexuels ou religieux",
          ],
        },
      ],
    },
    selfcaretips: {
      en: [
        "Ask about options for guided therapy with the help of a professional",
        "Alternatively, use a standard workbook or online CBT program specifically designed for OCD such as www.getselfhelp.co.uk/ocd.htm",
        "Recommended book: Break Free from OCD: Overcoming OCD with CBT by F. Challacombe.",
      ],
      fr: [
        "Renseignez-vous sur les options de thérapie guidée avec l'aide d'un professionnel",
        "Alternativement, utilisez un livre de travail standard ou un programme de TCC en ligne spécialement conçu pour le TOC tel que www.getselfhelp.co.uk/ocd.htm",
        "Livre recommandé : Se libérer du TOC : Surmonter le TOC avec la TCC par F. Challacombe.",
      ],
    },
    supports: {
      en: [
        "Obsessive Compulsive Disorder: Identification and Self-Help Strategies",
        "Lighten Your Load...Lose the OCD: A Guide for College Students",
        "Canadian Institute for OCD",
        "Canadian Treatment Centre for OCD",
      ],
      fr: [
        "Trouble obsessionnel compulsif : Identification et stratégies d'auto-assistance",
        "Allégez votre charge... Perdez le TOC : Un guide pour les étudiants universitaires",
        "Institut canadien du TOC",
        "Centre de traitement canadien du TOC",
      ],
    },
  },

  {
    disorder: "Adjustment Disorder",
    symptoms: {
      en: [
        {
          list: [
            "Feeling sad, hopeless or not enjoying things you used to enjoy",
            "Frequent crying",
            "Worrying or feeling anxious, nervous, jittery or stressed out",
            "Trouble sleeping",
            "Lack of appetite",
            "Difficulty concentrating",
            "Feeling overwhelmed",
            "Difficulty functioning in daily activities",
            "Withdrawing from social supports",
            "Avoiding important things such as going to work or paying bills",
            "Suicidal thoughts or behavior",
          ],
        },
      ],
      fr: [
        {
          list: [
            "Se sentir triste, désespéré ou ne pas profiter des choses que vous aimiez auparavant",
            "Pleurs fréquents",
            "Inquiétude ou sentiment d'anxiété, de nervosité, d'agitation ou de stress",
            "Problèmes de sommeil",
            "Manque d'appétit",
            "Difficulté à se concentrer",
            "Se sentir dépassé",
            "Difficulté à fonctionner dans les activités quotidiennes",
            "Retrait des soutiens sociaux",
            "Éviter des choses importantes comme aller travailler ou payer les factures",
            "Pensées ou comportements suicidaires",
          ],
        },
      ],
    },
    talktherapy: {
      en: [
        "Cognitive Behavioural Therapy (CBT)",
        "Problem-Solving Therapy (PST)",
      ],
      fr: [
        "Thérapie cognitive-comportementale (TCC)",
        "Thérapie de résolution de problèmes (TRP)",
      ],
    },
    selfcaretips: {
      en: [
        "Practice relaxation exercises such as deep breathing",
        "Reduce alcohol, nicotine, and caffeine intake and get regular exercise",
        "Set goals and homework for reducing symptoms, using a website or workbook",
        "Recommended website: www.getselfhelp.co.uk/cbt.html, a free general CBT course for anxiety depression, and anger problems",
        "Recommended book: The Relaxation and Stress Reduction Workbook by Martha Davis, Elizabeth Robbins Eshelman, and Matthew McKay",
      ],
      fr: [
        "Pratiquer des exercices de relaxation comme la respiration profonde",
        "Réduire la consommation d'alcool, de nicotine et de caféine et faire de l'exercice régulièrement",
        "Fixer des objectifs et des devoirs pour réduire les symptômes, en utilisant un site Web ou un livre de travail",
        "Site Web recommandé : www.getselfhelp.co.uk/cbt.html, un cours général gratuit sur la TCC pour l'anxiété, la dépression et les problèmes de colère",
        "Livre recommandé : Le livre du bien-être et de la réduction du stress par Martha Davis, Elizabeth Robbins Eshelman et Matthew McKay",
      ],
    },
    supports: {
      en: [
        "Facing Your Feelings",
        "Causes, Symptoms & Effects of Adjustment Disorder",
        "eMentalHealth",
      ],
      fr: [
        "Affronter ses sentiments",
        "Causes, symptômes et effets du trouble d'adaptation",
        "eMentalHealth",
      ],
    },
  },
]

export const tableData = [
  {
    disorder: "Depression",
    table: [
      {
        tradeName: "Cipralex",
        drug: "Clonazepam",
        dose: "10 mg to 20 mg",
        initialDose: "5 mg to 10 mg",
      },
      {
        tradeName: "Zoloft",
        drug: "Sertraline",
        dose: "50 mg to 200 mg",
        initialDose: "50 mg",
      },
      {
        tradeName: "Cipralex",
        drug: "Clonazepam",
        dose: "1 mg 2 mg twice daily",
        initialDose: "0.5 mg twice daily",
      },
      {
        tradeName: "Prozac",
        drug: "Prozac",
        dose: "10 mg to 80 mg",
        initialDose: "10 mg",
      },
      {
        tradeName: "Luvox",
        drug: "Fluvoxamine",
        dose: "50 mg to 300 mg",
        initialDose: "50 mg",
      },
      {
        tradeName: "Effexor-XR",
        drug: "Venlafaxine XR",
        dose: "75 mg to 375 mg",
        initialDose: "37.5 mg to 75 mg",
      },
      {
        tradeName: "Wellbutrin",
        drug: "Bupropion XL",
        dose: "150 mg to 300 mg",
        initialDose: "150 mg",
      },
      {
        tradeName: "Cymbalta",
        drug: "Duloxetine",
        dose: "30 mg to 120 mg",
        initialDose: "30 mg",
      },
      {
        tradeName: "Lithium Brands",
        drug: "Lithium",
        dose: "600 mg - 1500 mg",
        initialDose: "300 mg",
      },
      {
        tradeName: "Seroquel XR",
        drug: "Quetiapine",
        dose: "150 mg – 300 mg",
        initialDose: "50 mg",
      },
      {
        tradeName: "Latuda",
        drug: "Lurasidone",
        dose: "40 mg - 120 mg",
        initialDose: "20 mg",
      },
      {
        tradeName: "Lamictal (monotherapy)*",
        drug: "Lamotrigine",
        dose: "225 mg – 275 mg",
        initialDose: "25 mg",
      },
      {
        tradeName: "Vraylar",
        drug: "Cariprazine",
        dose: "1.5 mg – 3 mg",
        initialDose: "1.5 mg",
      },
    ],
  },
  {
    disorder: "Mania/Hypomania",
    table: [
      {
        tradeName: "Lithium Brands",
        drug: "Lithium",
        dose: "600 mg - 1500 mg",
        initialDose: "300 mg",
      },
      {
        tradeName: "Epival, Depakote",
        drug: "Divaproex, Valproate",
        dose: "750 mg - 1500 mg",
        initialDose: "250 mg twice daily",
      },
      {
        tradeName: "Tegretol, others",
        drug: "Carbarnazpine",
        dose: "400 mg - 800 mg",
        initialDose: "200 mg twice daily",
      },
      {
        tradeName: "Risperdal",
        drug: "Risperidone",
        dose: "4 mg - 6 mg",
        initialDose: "2 mg - 3 mg",
      },
      {
        tradeName: "Zyprexa",
        drug: "Olanzapine",
        dose: "10 mg 20 mg",
        initialDose: "10 mg 20 mg",
      },
      {
        tradeName: "Abilify",
        drug: "Aripiprazole",
        dose: "15 mg 30 mg",
        initialDose: "10 mg - 15 mg",
      },
      {
        tradeName: "Seroquel",
        drug: "Quetiapine",
        dose: "300 mg 800 mg",
        initialDose: "100 mg 200 mg",
      },
      {
        tradeName: "Seroquel XR",
        drug: "Quetiapine",
        dose: "150 mg 300 mg",
        initialDose: "50 mg",
      },
      {
        tradeName: "Latuda",
        drug: "Lurasidone",
        dose: "40 mg - 120 mg",
        initialDose: "20 mg",
      },
      {
        tradeName: "Lamictal (monotherapy)*",
        drug: "Lamotrigine",
        dose: "225 mg 275 mg",
        initialDose: "25 mg",
      },
      {
        tradeName: "Vraylar",
        drug: "Cariprazine",
        dose: "1.5 mg – 3 mg",
        initialDose: "1.5 mg",
      },
    ],
  },
  {
    disorder: "Panic Disorder",
    table: [
      {
        tradeName: "Rivotril, Klonopin",
        drug: "Clonazepam",
        dose: "1 mg - 2 mg twice daily",
        initialDose: "0.5 mg twice daily",
      },
      {
        tradeName: "Ativan",
        drug: "Lorazepam",
        dose: "1 mg - 2 mg twice or thrice daily",
        initialDose: "1 mg daily",
      },
      {
        tradeName: "Celexa",
        drug: "Citalopram",
        dose: "10 mg - 40 mg",
        initialDose: "10 mg 20 mg",
      },
      {
        tradeName: "Cipralex",
        drug: "Escitalopram",
        dose: "10 mg - 20 mg",
        initialDose: "5 mg - 10 mg",
      },
      {
        tradeName: "Zoloft",
        drug: "Sertraline",
        dose: "50 mg - 200 mg",
        initialDose: "25 mg - 50 mg",
      },
      {
        tradeName: "Prozac",
        drug: "Fluoxetine",
        dose: "20 mg - 60 mg",
        initialDose: "10 mg - 20 mg",
      },
    ],
  },
  {
    disorder: "Social Anxiety",
    table: [
      {
        tradeName: "Rivotril, Klonopin",
        drug: "Clonazepam",
        dose: "0.25 mg - 1 mg",
        initialDose: "0.25 mg",
      },
      {
        tradeName: "Ativan",
        drug: "Lorazepam",
        dose: "0.5 mg - 2 mg",
        initialDose: "0.5 mg",
      },
      {
        tradeName: "Inderal",
        drug: "Propranolol",
        dose: "10 mg - 60 mg",
        initialDose: "10 mg - 20 mg",
      },
      {
        tradeName: "Cipralex",
        drug: "Escitalopram",
        dose: "10 mg - 20 mg",
        initialDose: "5 mg - 10 mg",
      },
      {
        tradeName: "Zoloft",
        drug: "Sertraline",
        dose: "50 mg - 250 mg",
        initialDose: "50 mg",
      },
      {
        tradeName: "Paxil",
        drug: "Paroxetine",
        dose: "20 mg - 60 mg",
        initialDose: "10 mg",
      },
      {
        tradeName: "Effexor-XR",
        drug: "Venlafaxine XR",
        dose: "75 mg - 225 mg",
        initialDose: "37.5 mg - 75 mg",
      },
    ],
  },
  {
    disorder: "PTSD",
    table: [
      {
        tradeName: "Prozac",
        drug: "Fluoxetine",
        dose: "60 mg 80 mg",
        initialDose: "20 mg",
      },
      {
        tradeName: "Effexor XR",
        drug: "Venlafaxine",
        dose: "150 mg 300 mg",
        initialDose: "75 mg",
      },
      {
        tradeName: "Zoloft",
        drug: "Sertraline",
        dose: "150 mg 200 mg",
        initialDose: "50 mg",
      },
      {
        tradeName: "Paxil",
        drug: "Paroxetine",
        dose: "40 mg 60 mg",
        initialDose: "20 mg",
      },
    ],
  },
  {
    disorder: "General Anxiety Disorder",
    table: [
      {
        tradeName: "Rivotril, Klonopin",
        drug: "Clonazepam",
        dose: "1 mg 2 mg twice daily",
        initialDose: "0.5 mg twice daily",
      },
      {
        tradeName: "Ativan",
        drug: "Lorazepam",
        dose: "1 mg 2 mg twice or thrice daily",
        initialDose: "1 mg daily",
      },
      {
        tradeName: "Cipralex",
        drug: "Escitalopram",
        dose: "10 mg 20 mg",
        initialDose: "10 mg",
      },
      {
        tradeName: "Paxil",
        drug: "Paroxetine",
        dose: "20 mg - 50 mg",
        initialDose: "20 mg",
      },
      {
        tradeName: "Zoloft",
        drug: "Sertraline",
        dose: "50 mg - 200 mg",
        initialDose: "25 mg",
      },
      {
        tradeName: "Cymbalta",
        drug: "Duloxetine",
        dose: "30 mg - 60 mg",
        initialDose: "30 mg",
      },
    ],
  },
  {
    disorder: "ADHD",
    table: [
      {
        tradeName: "Focalin XR",
        drug: "Dexmethylphenidate XR*",
        dose: "30 mg - 40 mg",
        initialDose: "10 mg",
      },
      {
        tradeName: "Ritalin LA",
        drug: "Methylphenidate LA**",
        dose: "40 mg – 60 mg",
        initialDose: "10 mg – 20 mg",
      },
      {
        tradeName: "Concerta",
        drug: "Methylphenidate LA**",
        dose: "54 mg - 72 mg",
        initialDose: "18 mg - 36 mg",
      },
      {
        tradeName: "Adderall XR",
        drug: "Dextroamphetamine and amphetamine XR*",
        dose: "10 mg - 40 mg",
        initialDose: "20 mg",
      },
      {
        tradeName: "Strattera",
        drug: "Atomoxetine",
        dose: "80 mg",
        initialDose: "40 mg",
      },
      {
        tradeName: "Wellbutrin XL",
        drug: "Bupropion XL",
        dose: "300 mg",
        initialDose: "150 mg",
      },
    ],
  },
  {
    disorder: "Anger Problems",
    table: [
      {
        tradeName: "Prozac",
        drug: "Fluoxetine",
        dose: "20 mg 60 mg",
        initialDose: "20 mg",
      },
    ],
  },
  {
    disorder: "Sleep Disorder (Insomnia)",
    table: [
      {
        tradeName: "Lunesta",
        drug: "Eszopiclone",
        dose: "3 mg",
        initialDose: "1 mg",
      },
      {
        tradeName: "Sonata",
        drug: "Zaleplon",
        dose: "20 mg",
        initialDose: "5 mg",
      },
      {
        tradeName: "Ambien",
        drug: "Zolpidem",
        dose: "10 mg",
        initialDose: "5 mg",
      },
    ],
  },
  {
    disorder: "OCD",
    table: [
      {
        tradeName: "Prozac",
        drug: "Fluoxetine",
        dose: "40 mg - 80 mg",
        initialDose: "20 mg",
      },
      {
        tradeName: "Cipralex",
        drug: "Escitalopram",
        dose: "20 mg - 40 mg",
        initialDose: "5 mg - 10 mg",
      },
      {
        tradeName: "Zoloft",
        drug: "Sertraline",
        dose: "50 mg - 200 mg",
        initialDose: "50 mg",
      },
      {
        tradeName: "Luvox",
        drug: "Fluvoxamine",
        dose: "200 mg - 300 mg",
        initialDose: "50 mg",
      },
      {
        tradeName: "Paxil",
        drug: "Paroxetine",
        dose: "40 mg – 60 mg",
        initialDose: "20 mg",
      },
    ],
  },
]

export const healthDisorders = [
  {
    text: "Depresssion",
    risk: "High Risk",
  },
  {
    text: "Bipolar Disorder",
    risk: "Low Risk",
  },
  {
    text: "Alcohol and Substance Abuse",
    risk: "Low Risk",
  },
  {
    text: "Panic Disorder",
    risk: "Low Risk",
  },
  {
    text: "Social Anxiety",
    risk: "Moderate Risk",
  },
  {
    text: "PTSD",
    risk: "Moderate Risk",
  },
  {
    text: "General Anxiety Disorder",
    risk: "High Risk",
  },
  {
    text: "Eating Disorder",
    risk: "Low Risk",
  },
  {
    text: "ADHD",
    risk: "High Risk",
  },
  {
    text: "Anger Problem",
    risk: "Low Risk",
  },
  {
    text: "Sleep Disorder (Insomnia)",
    risk: "Low Risk",
  },
  {
    text: "OCD",
    risk: "Moderate Risk",
  },
  {
    text: "Adjustment Disorder",
    risk: "Moderate Risk",
  },
]

export const disorderData = [
  {
    disorder: "Depression",
    subText:
      lang === "en"
        ? "Make an appointment with your physician or a mental health practitioner"
        : "Prenez rendez-vous avec votre médecin ou un praticien en santé mentale",
    heading:
      lang === "en" ? "What is Depression?" : "Qu'est-ce que la dépression?",
    description:
      lang === "en"
        ? "Clinical depression such as major depressive disorder (MDD), is a serious mental illness that can affect every aspect of a persons life. The word “depression is often used to describe sadness, which is problematic because it can lead to a lack of understanding of MDD. Everyone gets sad from time to time, and this is perfectly healthy and normal. The difference with clinical depression is that the individual is overwhelmed by these feelings; and the feelings persist indefinitely."
        : "La dépression clinique, telle que le trouble dépressif majeur (TDM), est une maladie mentale grave qui peut affecter tous les aspects de la vie d'une personne. Le mot « dépression » est souvent utilisé pour décrire la tristesse, ce qui pose problème car il peut conduire à un manque de compréhension du TDM. Tout le monde est triste de temps en temps, et c’est parfaitement sain et normal. La différence avec la dépression clinique est que l’individu est submergé par ces sentiments ; et les sentiments persistent indéfiniment.",
  },
  {
    disorder: "Mania/Hypomania",
    heading:
      lang === "en"
        ? "What is Bipolar Disorder?"
        : "Qu’est-ce que le trouble bipolaire ?",
    description:
      lang === "en"
        ? "Bipolar disorder, (formerly called manic depression) is a mood disorder that is characterized by cycling between depressive (low mood) and manic (high mood) states. The terms mania and manic episode describe a state of mind characterized by high energy, excitement, and euphoria over a continuous period. It s an extreme change in mood and thinking that can interrupt your school, work, or home life and is the main feature of bipolar disorder."
        : "Le trouble bipolaire (anciennement appelé maniaco-dépression) est un trouble de l'humeur caractérisé par un cycle entre dépression (faible humeur) et les états maniaques (bonne humeur). Les termes manie et épisode maniaque décrivent un état d'esprit caractérisé par une forte énergie, excitation et euphorie sur une période continue. C'est un changement extrême d'humeur et de réflexion qui peut interrompre votre à l'école, au travail ou à la maison et constitue la principale caractéristique du trouble bipolaire.",
  },
  {
    disorder: "Alcohol and Substance Abuse",
    heading:
      lang === "en"
        ? "What is Alcohol and Substance Abuse?"
        : "Qu’est-ce que l’abus d’alcool et de substances ?",
    description:
      lang === "en"
        ? "Addiction or substance abuse is a complex mental disorder that causes and in turn is caused by changes in the body, mind, and personality. An individual may have a substance use disorder (SUD) or addiction when they continue to use alcohol or drugs despite significant problems caused by using the substance. The key factors in developing a substance abuse disorder are tolerance, dependence, and addiction."
        : "La toxicomanie ou la toxicomanie est un trouble mental complexe qui provoque et est à son tour causé par des changements dans le corps, l'esprit et personnalité. Une personne peut souffrir d'un trouble lié à l'usage de substances (TUS) ou d'une dépendance lorsqu'elle continue à consommer de l'alcool ou des drogues.malgré les problèmes importants causés par l'utilisation de la substance. Les facteurs clés dans le développement d’un trouble lié à la toxicomanie sont tolérance, dépendance et addiction.",
  },
  {
    disorder: "Panic Disorder",
    heading:
      lang === "en"
        ? "What is Panic Disorder?"
        : "Qu’est-ce que le trouble panique ?",

    description:
      lang === "en"
        ? "A panic attack is a sudden episode of intense fear that triggers severe physical reactions when there is no real danger or appropriate cause. Panic attacks can be very frightening and when they occur, one might think they’re losing control, having a heart attack, or even dying. Panic attacks can also exhibit specific fears and mental states, such as derealization, depersonalization, and a fear of dying, going crazy, or losing control. Derealization is the experience that somehow you are or your reality isn’t real. Depersonalization is the sensation that you have become detached from your memories, identity, or experiences."
        : "Une crise de panique est un épisode soudain de peur intense qui déclenche des réactions physiques sévères alors qu'il n'y a pas de danger réel ou cause appropriée. Les attaques de panique peuvent être très effrayantes et lorsqu’elles surviennent, on pourrait penser qu’elles perdent le contrôle, qu’elles ont un sentiment d’anxiété.crise cardiaque, voire mourir. Les attaques de panique peuvent également révéler des peurs et des états mentaux spécifiques, tels que la déréalisation,la dépersonnalisation et la peur de mourir, de devenir fou ou de perdre le contrôle. La déréalisation est l'expérience selon laquelle vous êtes ou votre réalité n'est pas réelle. La dépersonnalisation est la sensation que vous vous êtes détaché de vos souvenirs, de votre identité ou de votre identité. expériences.",
  },
  {
    disorder: "Social Anxiety",
    heading:
      lang === "en"
        ? "What is Social Anxiety?"
        : "Qu’est-ce que l’anxiété sociale ?",

    description:
      lang === "en"
        ? "It s normal to feel nervous and experience butterflies in your stomach in certain social situations. For example, on a first date, or giving a presentation to a group of coworkers. However, in social anxiety disorder, also called Social Phobia, everyday interactions cause significant anxiety, self-consciousness, and embarrassment. People with social anxiety disorder have an excessive fear of being scrutinized or negatively judged by others."
        : "Il est normal de se sentir nerveux et d'avoir des papillons dans l'estomac dans certaines situations sociales. Par exemple, lors d'un premier rendez-vous ou lors d'une présentation à un groupe de collègues. Cependant, dans le trouble d'anxiété sociale, également appelé phobie sociale, les interactions quotidiennes provoquent une anxiété, une conscience de soi et un embarras importants. Les personnes souffrant de trouble d'anxiété sociale ont une peur excessive d'être scrutées ou jugées négativement par les autres.",
  },
  {
    disorder: "PTSD",
    heading: lang === "en" ? "What is PTSD?" : "Qu’est-ce que le SSPT ?",

    description:
      lang === "en"
        ? "Post-traumatic stress disorder (PTSD) is a mental health condition that's triggered by a terrifying event — either experiencing it or witnessing it. Symptoms may include flashbacks, nightmares, and severe anxiety, as well as uncontrollable thoughts about the event. Most people who go through traumatic events may have temporary difficulty adjusting and coping, but with time and good self-care, they usually get better. If the symptoms get worse, last for months or even years, and interfere with your day-today functioning, you may have PTSD. Getting effective treatment after PTSD symptoms develop can be critical to reducing symptoms and improving function."
        : "Le trouble de stress post-traumatique (SSPT) est un problème de santé mentale déclenché par un événement terrifiant - qu'il soit vécu ouen être témoin. Les symptômes peuvent inclure des flashbacks, des cauchemars et une anxiété sévère, ainsi que des pensées incontrôlables concernant le événement. La plupart des personnes qui traversent des événements traumatisants peuvent avoir des difficultés temporaires à s'adapter et à faire face, mais avec le temps et bons soins personnels, ils s'améliorent généralement. Si les symptômes s’aggravent, durent des mois, voire des années, et interfèrent avec votre fonctionnement quotidien, vous pourriez souffrir du SSPT. Obtenir un traitement efficace après l'apparition des symptômes du SSPT peut être essentiel pour réduire symptômes et amélioration de la fonction.",
  },
  {
    disorder: "General Anxiety Disorder",
    heading:
      lang === "en"
        ? "What is General Anxiety Disorder?"
        : "Qu’est-ce que le trouble d’anxiété générale ?",

    description:
      lang === "en"
        ? "It s normal to feel anxious from time to time especially if your life contains stressors that are out of your control. However,excessive ongoing anxiety and worry that interfere with day-to-day activities may be a sign of generalized anxiety disorder (GAD). Anxiety levels are on the rise, and that can only be explained by external factors"
        : "Il est normal de ressentir de l'anxiété de temps en temps, surtout si votre vie contient des facteurs de stress sur lesquels vous n'avez aucun contrôle. Cependant, une anxiété et une inquiétude excessives et continues qui interfèrent avec les activités quotidiennes peuvent être le signe d'un trouble d'anxiété généralisée (GAD). Les niveaux d'anxiété sont en hausse, et cela ne peut s'expliquer que par des facteurs externes",
  },
  {
    disorder: "Eating Disorder",
    heading:
      lang === "en"
        ? "What is Eating Disorder?"
        : "Qu’est-ce que le trouble de l’alimentation ?",

    description:
      lang === "en"
        ? "An eating disorder is characterized by an abnormal pattern of eating behaviours that interferes with a person's health, relationships, or daily activities. Eating disorders generally involve two types of symptoms, not eating enough, and loss of control over eating. In addition to being preoccupied with food, people with eating disorders often obsess over their body weight or shape. There are three primary eating disorders: anorexia nervosa, bulimia nervosa, and binge-eating disorder."
        : "Un trouble de l'alimentation se caractérise par un comportement alimentaire anormal qui interfère avec la santé, les relations ou les activités quotidiennes d'une personne. Les troubles de l'alimentation impliquent généralement deux types de symptômes : ne pas manger suffisamment et perdre le contrôle de l'alimentation. En plus d'être préoccupées par la nourriture, les personnes souffrant de troubles de l'alimentation sont souvent obsédées par leur poids ou leur forme. Il existe trois principaux troubles de l'alimentation: l'anorexie mentale, la boulimie mentale et l'hyperphagie boulimique.",
  },
  {
    disorder: "ADHD",
    heading: lang === "en" ? "What is ADHD?" : "Qu’est-ce que le TDAH ?",

    description:
      lang === "en"
        ? "Attention-Deficit/Hyperactivity Disorder (ADHD) is one of the most common childhood brain neurodevelopmental disorders and can continue through into adulthood. It shows up as a persistent pattern of inattention, hyperactivity, or impulsive behaviour.Symptoms of inattention include difficulty focusing on or completing a task. Hyperactivity describes behaviours such as difficulty sitting still and excessive talkativeness, and impulsivity refers to difficulty delaying gratification. ADHD always begins in childhood, but may not be recognized or diagnosed until adulthood"
        : "Le trouble déficitaire de l'attention/hyperactivité (TDAH) est l'un des troubles neurodéveloppementaux cérébraux les plus courants chez l'enfant. peut se poursuivre jusqu'à l'âge adulte. Cela se manifeste par un schéma persistant d'inattention, d'hyperactivité ou de comportement impulsif. Les symptômes d'inattention comprennent des difficultés à se concentrer ou à accomplir une tâche. L'hyperactivité décrit des comportements tels que la difficulté rester assis et parler excessivement, et l'impulsivité fait référence à la difficulté à retarder la gratification. Le TDAH commence toujours dans l'enfance, mais peut ne pas être reconnu ou diagnostiqué avant l'âge adulte",
  },
  {
    disorder: "Anger Problems",
    heading:
      lang === "en"
        ? "What is Anger Problems?"
        : "Qu’est-ce que les problèmes de colère ?",

    description:
      lang === "en"
        ? "Everybody gets angry now and then and experiences the occasional outburst. Anger itself is a perfectly healthy emotion, and it is important to be able to express your anger in a healthy way. Anger results from feelings of being mistreated or having your rights as an individual infringed upon. Anger can also be irrational, and sometimes when we are angry, we do things that later surprise us and we regret."
        : "Tout le monde se met en colère de temps en temps et éprouve des explosions occasionnelles. La colère elle-même est une émotion parfaitement saine, et elle est Il est important de pouvoir exprimer sa colère de manière saine. La colère résulte du sentiment d'être maltraité ou d'avoir ses droits en tant qu'individu lésé. La colère peut aussi être irrationnelle, et parfois lorsque nous sommes en colère, nous faisons des choses qui surprennent par la suite. nous et nous le regrettons.",
  },
  {
    disorder: "Sleep Disorder (Insomnia)",
    heading:
      lang === "en"
        ? "What is Sleep Disorder (Insomnia)?"
        : "Qu’est-ce que le trouble du sommeil (insomnie) ?",

    description:
      lang === "en"
        ? "Insomnia refers to trouble with the duration or quality of sleep. This may include problems falling asleep, difficulty staying asleep or waking up too early in the morning, and being unable to fall back asleep. Insomnia can also include nonrestorative sleep, meaning sleep that may be long enough in duration but does not result in the individual feeling rested upon awakening.Insomnia is also a very common symptom in other disorders such as depression, anxiety, adjustment disorder, and bipolar disorder."
        : "L'insomnie fait référence à des problèmes liés à la durée ou à la qualité du sommeil. Cela peut inclure des problèmes d'endormissement, des difficultés à rester endormisou se réveiller trop tôt le matin et être incapable de se rendormir. L'insomnie peut également inclure un sommeil non réparateur, c'est-à-dire un sommeil qui peut être suffisamment long mais qui ne donne pas à l'individu un sentiment de repos au réveil.L'insomnie est également un symptôme très courant dans d'autres troubles tels que la dépression, l'anxiété, le trouble de l'adaptation et la bipolaire.désordre.",
  },
  {
    disorder: "OCD",
    heading: lang === "en" ? "What is OCD?" : "Qu’est-ce que le TOC ?",

    description:
      lang === "en"
        ? "Obsessive-compulsive disorder (OCD) features a pattern of unwanted thoughts and fears (obsessions) that can lead you to do repetitive behaviors (compulsions). These obsessions and compulsions interfere with daily activities and cause significant distress. You may try to ignore or stop your obsessions, but that only increases distress and anxiety. Ultimately, you may feel driven to perform compulsive acts to try to ease your stress."
        : "Le trouble obsessionnel compulsif (TOC) se caractérise par un ensemble de pensées et de peurs indésirables (obsessions) qui peuvent vous amener à faire comportements répétitifs (compulsions). Ces obsessions et compulsions interfèrent avec les activités quotidiennes et provoquent d'importantes détresse. Vous pouvez essayer d'ignorer ou d'arrêter vos obsessions, mais cela ne fait qu'augmenter la détresse et l'anxiété. En fin de compte, vous pourriez ressentir poussé à accomplir des actes compulsifs pour tenter de réduire votre stress.",
  },
  {
    disorder: "Adjustment Disorder",
    heading:
      lang === "en"
        ? "What is Adjustment Disorder?"
        : "Qu’est-ce que le trouble de l’adaptation ?",

    description:
      lang === "en"
        ? "Adjustment disorder is a stress-related condition. You experience more stress than would normally be expected in response to a stressful or unexpected event, and stress causes significant problems in your relationships, at work, or at school. Work problems,going away to school, an illness, the death of a close family member, or any number of life changes can cause stress. Most of the time, people adjust to such changes within a few months. But if you have an adjustment disorder, you continue to have emotional or behavioral reactions that can contribute to feeling anxious or depressed"
        : "Le trouble de l'adaptation est une condition liée au stress. Vous ressentez plus de stress que ce à quoi on pourrait normalement s'attendre en réponse à unévénement stressant ou inattendu, et le stress provoque des problèmes importants dans vos relations, au travail ou à l'école. Problèmes de travail,un départ pour l'école, une maladie, le décès d'un membre de la famille proche ou de nombreux changements dans la vie peuvent provoquer du stress. La plupart Avec le temps, les gens s'adaptent à ces changements en quelques mois. Mais si vous souffrez d'un trouble d'adaptation, vous continuez à avoir réactions émotionnelles ou comportementales qui peuvent contribuer à un sentiment d'anxiété ou de dépression",
  },
]
