import React, { useContext, useEffect, useReducer } from "react"
import { Route, Navigate, Routes, useParams, useNavigate } from "react-router-dom"
import { loginUrl } from "../../lib/constants"
import { StateContext } from "../../store/state/contexts"
import { StateContext as FormContext } from "../../store/form/contexts"

export const ClientAccessRoute = ({
  component: Component,
  accessId,
  ...rest
}) => {
  const { state } = useContext(StateContext)
  const { state: formState } = useContext(FormContext)

  let params = useParams()
  let navigate = useNavigate()
  
  useEffect(() => {
    if (state.auth.authInitialized && !state.auth.isLoggedIn) {
      window.open(loginUrl(localStorage.getItem('tempEnv') || undefined), "_self")
    }
  }, [state.auth.authInitialized, !state.auth.isLoggedIn])

  if (!state.auth.authInitialized) {
    return null
  }

  return state.auth.authInitialized && state.auth.isLoggedIn ? (
    formState !== false ? (
      <Component {...rest} match={{ params }} navigate={navigate} />
    ) : (
      <Navigate
        to={{
          pathname: "/",
        }}
      />
    )
  ) : (
    <Navigate
      to={{
        pathname: "/",
      }}
    />
  )
}
