/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import CryptoJS from 'crypto-js'; 
import generatePassword from './generatePassword';

var ts = new Date()
var code = generatePassword(32 - (+ts).toString().length)

if(localStorage.getItem('ts')) {
    ts = moment(localStorage.getItem('ts')).toDate()
} else {
    localStorage.setItem('ts', ts)
}

if(localStorage.getItem('txt')) {
    code = localStorage.getItem('txt')
} else {
    localStorage.setItem('txt', code)
}

var _key = CryptoJS.enc.Utf8.parse(code + (+ts))
var _iv = _key

const rotateKeyifNeeded = () => {
  if(moment(ts).add(process.env.CACHE_EXP_TIME || 60, 'minutes').isBefore(moment())) {
    ts = new Date()
    code = generatePassword(32 - (+ts).toString().length)
    localStorage.setItem('txt', code)
    localStorage.setItem('ts', ts)
    _key = CryptoJS.enc.Utf8.parse(code + (+ts))
    _iv = _key
  }
}

export const encrypt = (dataToEncrypt) => {

    rotateKeyifNeeded()

    // console.log('encryption text key : ', dataToEncrypt, ' ===> ' , code + (+ts))

    return CryptoJS.AES.encrypt(
        dataToEncrypt, _key, {
          keySize: 32,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        },
    ).toString()
}

export const decrypt = (decryptString) => {
    try {
      rotateKeyifNeeded()
      // console.log('string to decrypt', code + (+ts), ts)
      return CryptoJS.AES.decrypt(
        decryptString, _key, {
          keySize: 32,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        },
      )
      .toString(CryptoJS.enc.Utf8);
    } catch (error) {
      // console.error(error);
      return false;
    }
}
