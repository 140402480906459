import React, { useContext, useEffect, useState, state } from "react"
import moment from "moment"
import { baseUrl } from "../../lib/constants"
import { fetchAssessmentTimestamp } from "../../api/assessment"
import styled from "@emotion/styled"
import "../../css/assessmentTimer.scss"
import { start } from "@popperjs/core"
import { red } from "@material-ui/core/colors"
import { logEvent } from "../../index"
import { withTranslation } from "react-i18next"
import { StateContext } from "../../store/state/contexts"
var store = require("store")

const TimerDiv = styled.div`
  color: ${(prop) => (prop.red ? "red" : "grey")};
`

const AssessmentTimerObj = (screen) => {
  const { t } = screen;
  const [hours, setHours] = useState()
  const [minutes, setMinutes] = useState()
  const [seconds, setSeconds] = useState()
  // const [expireDate, setExpireDate] = useState()
  const [error, setError] = useState(false)
  const { state, dispatch } = useContext(StateContext)
  const inProgress = state.currentAssessment

  var temp = ""
  if (inProgress && inProgress.insertDate) {
    temp = new Date(inProgress.insertDate)
  }

  const expire = new Date(temp)
  expire.setHours(expire.getHours() + 12)
  const expireDate = expire

  // const getTime = async () => {
  //   try {
  //     let res = await fetch(baseUrl + "/assessment/inprogress?from=timer", {
  //       credentials: "include",
  //       mode: "cors",
  //       method: "GET",
  //     })
  //     if (res.ok) {
  //       logEvent("AssessmentTimer /assessment/inprogress successful")
  //       res = await res.json()


  //       var temp = ""
  //       if (res && res.insertDate) {
  //         temp = new Date(res.insertDate)
  //       }

  //       const expire = new Date(temp)
  //       expire.setHours(expire.getHours() + 12)
  //       setExpireDate(expire)
  //     } else {
  //       setError(true)
  //     }
  //   } catch (err) {
  //     console.log("File ==================== src/Modules/Assessment/AssessmentTimer.jsx ============================")
  //     console.log(err)
  //     setError(true)
  //   }
  // }

  useEffect(() => {
    // getTime()
  }, [])

  useEffect(() => {
    let myInterval = setInterval(() => {
      const now = new Date()
      var diff = expireDate - now

      if (diff < 0) {
        setSeconds(0)
        setMinutes(0)
        setHours(0)
      } else {
        setHours(Math.floor(diff / 3600000) % 24)
        diff -= hours * 3600000
        // console.log("hours:", hours)

        setMinutes(Math.floor(diff / 60000) % 60)
        diff -= minutes * 60000
        // console.log("minutse:", minutes)

        setSeconds(Math.ceil(diff / 1000))
        // console.log("seconds: ", seconds)
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  })

  // let dateTime = new Date(store.get("insertDate")).getTime();
  // const storedTimeStamp = dateTime;
  // const timeRemain = Math.floor((moment() - moment(storedTimeStamp)) / 60000);
  // const [timeLeft, setTimeLeft] = React.useState(10 - timeRemain ?? 0);

  // const timePassed = React.useCallback(async () => {
  //   await fetchAssessmentTimestamp();
  //   setTimeLeft(60 - timeRemain);
  //   console.log("timeleft:" + timeLeft)
  //   return storedTimeStamp ? timeRemain : 0;
  // }, [storedTimeStamp, timeRemain]);

  // window.addEventListener("storage", timePassed);

  // React.useEffect(() => {
  //   setInterval(() => setTimeLeft(10 - Math.floor((moment() - moment(storedTimeStamp)) / 60000)), 60000);
  //   setInterval(() => store.set("timeLeftForCompleteAssessment", timeLeft), 60000);
  //   console.log("timeleft:" + timeLeft)
  // });

  // return (
  //   <div className="assessment-timer">
  //     <p className="assessment-timer__text">{`${
  //       timeLeft > 1 ? timeLeft + " minutes left" : "Less than 1 minute left"
  //     }`}</p>
  //   </div>
  // );

  {
    if (screen.screen == "main") {
      return (
        <div
          style={{
            paddingTop: "15px",
            paddingLeft: "15px",
            display: "inline-block",
            fontSize: "16px",
          }}
        >
          {t("assessmentHistory.timeLeft")}: &nbsp;
          {isNaN(seconds) || isNaN(minutes) || isNaN(hours) ? (
            <div style={{ display: "inline-block", color: "grey" }}>
              {t("assessmentHistory.loading")}
            </div>
          ) : (
            error ?
              <TimerDiv red={true} style={{ display: "inline-block" }}>
                {" "}
                Error!
              </TimerDiv>
              : (
                [
                  minutes === 0 && seconds === 0 && hours === 0 ? (
                    <TimerDiv key={0} red={true} style={{ display: "inline-block" }}>
                      {" "}
                      {t("assessmentHistory.timesUp")}
                    </TimerDiv>
                  ) : (
                    <TimerDiv
                      key={0}
                      red={hours === 0 && minutes < 30}
                      style={{ display: "inline-block" }}
                    >
                      {" "}
                      {hours}:{minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </TimerDiv>
                  ),
                ])
          )}
        </div>
      )
    } else if (screen.screen == "history") {
      return (
        <div
          className="timerBlock-alignment"
          style={{

          }}
        >
          {/* {console.log(seconds, ':', minutes, ':', hours, ' ,==== we are timers...')} */}
          {isNaN(seconds) || isNaN(minutes) || isNaN(hours) ? (
            <div style={{ display: "inline-block", color: "grey" }}>
              {t("assessmentHistory.loading")}
            </div>
          ) : (
            [
              minutes === 0 && seconds === 0 && hours === 0 ? (
                <TimerDiv key={0} red={true} style={{ display: "inline-block" }}>
                  {" "}
                  {t("assessmentHistory.timesUp")}
                </TimerDiv>
              ) : (
                <TimerDiv
                  key={0}
                  red={hours === 0 && minutes < 30}
                  style={{ display: "inline-block" }}
                >
                  {" "}
                  {hours}:{minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </TimerDiv>
              ),
            ]
          )}
        </div>
      )
    }
  }
}

export const AssessmentTimer = withTranslation()(AssessmentTimerObj);