import React, { Component, useContext, useEffect, useState } from "react"
/** @jsx jsx */
import { jsx, css, Global } from "@emotion/react"
import { baseUrl } from "../../lib/constants"
import { withTranslation } from "react-i18next"
import "../../css/main.css"
import _get from "lodash/get"
import Footer from "../../components/footer/Footer"
import Navbar from "../../components/navbar/Navbar"
import Header from "../../components/navbar/Header"
import { checkdevice } from "../../utils/checkdevice"
import date from "date-and-time"
import LoadingScreen from "../../components/loading/LoadingScreen"
import { DownloadDetails } from "../../components/printable/PrintableDetails"
import { StateContext } from "../../store/state/contexts"
import { FloatingActionButton } from "../../components/buttons/floatingActionButton"
import { NotificationScheduler } from "../../components/popups/notificationScheduler/NotificationScheduler"
import { ProgressTracker } from "../../components/progressTracker/ProgressTracker"
import { logEvent } from "../../index"
import { useNavigate } from 'react-router-dom';
import AccordionTopIcon from '../../assets/images/circle-angle-top.svg'
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import '../../css/AssessmentDetails.scss'
import { disorderNames } from "../../data"
var store = require("store")

function Summary(props) {
  const { state, dispatch } = useContext(StateContext)
  const [loading, setLoading] = useState(true)
  const { t, i18n } = props
  const [open, setOpen] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const [assessmentDetails, setassessmentDetails] = React.useState({});
  const history = useNavigate();

  const getDetails = async (link) => {
    try {
      // dispatch({ type: 'SET_DETAILS_DATA_LOADING', payload: true })
      var response = await fetch(link, {
        credentials: "include",
        mode: "cors",
      })
      if (response.status >= 200 && response.status <= 299) {
        let details = await response.json()
        logEvent("getDetails /assessment/details successful")
        // dispatch({ type: "SET_DETAILS_DATA", payload: details })
        setassessmentDetails(details)
        // dispatch({ type: 'SET_DETAILS_DATA_LOADING', payload: false })
        return response
      } else {
        // dispatch({ type: 'SET_DETAILS_DATA_LOADING', payload: false })
        setOpen(true);
        setIsError(true);
        setTimeout(() => {
          history.goBack();
        }, 5000);
      }
    } catch (err) {
      // dispatch({ type: 'SET_DETAILS_DATA_LOADING', payload: false })
      setOpen(true);
      setIsError(true);
      setTimeout(() => {
        history.goBack();
      }, 5000);
      console.log("file: ====================== Summary === src/Modules/summary/SummaryDetails.jsx ================")
      console.log(err)
      return {}
    }
  }

  useEffect(() => {
    if (!assessmentDetails || assessmentDetails._id !== props.match.params.id) {
      setLoading(true)
      getDetails(`${baseUrl}/assessment/details/${props.match.params.id}`).then(
        () => setLoading(false)
      ).catch((err) => {
        console.log("file: ====================== useEffect === src/Modules/summary/SummaryDetails.jsx ================")
        console.log(err)
        setLoading(false)
      })
    } else setLoading(false)
  }, [props.match.params.id])
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <SummaryClass
        {...props}
        detailsData={assessmentDetails}
        loading={loading}
        t={t}
        isError={isError}
        i18n={i18n}
      />
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Note archived"
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="error">You're not authorized to access this page</Alert>
      </Snackbar>
    </>
  )
}

class SummaryClass extends Component {
  state = {
    auth: true,
    lang: store.get("lang") ? store.get("lang") : "en",
    loadingIn: true,
    loadingOut: false,
    showNotificationSettings: false,
    showFloatingButton: false,
    showCheckList: false,
  }

  //   ----------------------------------------------------------------------------------------------------------------------

  showInOtherTab = (blob) => {
    const url = window.URL.createObjectURL(blob)
    window.open(url)
  }

  download = (blob, filename) => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.style.display = "none"
    a.href = url
    // the filename you want
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  changeLanguage = () => {
    this.props.i18n.changeLanguage(this.state.lang === "en" ? "fr" : "en")
    this.setState({ lang: this.state.lang === "en" ? "fr" : "en" })
    store.set("lang", this.state.lang === "en" ? "fr" : "en")
  }
  componentDidUpdate() {
    if (this.state.lang !== this.props.i18n.language) {
      this.setState({ lang: this.props.i18n.language })
    }
  }
  handleClickThirdCollapse = (icon, heading) => {
    let favIcon = document.getElementById(icon)
    document.getElementById(heading).attributes[4].value === "false"
      ? favIcon.classList.add("fa-chevron-up")
      : favIcon.classList.remove("fa-chevron-up")
  }
  //   ----------------------------------------------------------------------------------------------------------------------

  componentDidMount() {
    checkdevice(this.props)
    if (this.props.i18n.language !== (store.get('lang') ? store.get('lang') : 'en')) {
      this.setState({ lang: store.get('lang') ? store.get('lang') : 'en' })
      this.props.i18n.changeLanguage(store.get('lang') ? store.get('lang') : 'en')
    }
    // fetch(baseUrl, { credentials: "include", mode: "cors" })
    //   .then((response) => {
    //     if (response.status !== 200) {
    //       this.setState({ auth: false })
    //       this.props.history.push("/home")
    //     } else {
    //       this.userSummary(
    //         baseUrl + "/assessment/details/" + this.props.match.params.id
    //       )
    //     }
    //   })
    //   .catch(() => {
    //     this.setState({ auth: false })
    //     alert("You seems like unauthorised, Please Login")
    //     this.props.history.push("/home")
    //   })
  }
  accordion = (heading, id, target, iconId, data) => {
    return (
      <div className="accordion" id={"summaryDetails" + id}>
        <div className="card my-2 border-bottom radius assessment-details-card">
          <div
            className="card-header border-0 lightBg radius"
            id={id}
            data-toggle="collapse"
            data-target={"#" + target}
            aria-expanded="false"
            aria-controls={target}
            onClick={() => this.handleClickThirdCollapse(iconId, id)}
          >
            <div className="d-flex justify-content-between align-items-center">
              <span className="mt-2 mb-2 accordion-title">{disorderNames[heading] ? disorderNames[heading][this.state.lang] || heading : heading}</span>
              <div id={iconId} className="topIcon" />
              {/* <i id={iconId} className="fas fa-chevron-down"></i> */}
            </div>
          </div>
          <div
            id={target}
            className="collapse"
            aria-labelledby={id}
            data-parent={"#summaryDetails" + id}
          >
            <div className="card-body carddetail-question">{data}</div>
          </div>
        </div>
      </div>
    )
  }
  //   ----------------------------------------------------------------------------------------------------------------------

  GeneralQuestionData = (state) => {
    return this.props.detailsData?.screening_question_responses?.map(
      (question, i) => {
        return (
          <div key={i}>
            <p>
              {`${question.question[state.lang].text}  `}
              <span>{question.response ? this.props.i18n.t('screening.yes') : this.props.i18n.t('screening.no')}</span>
            </p>
          </div>
        )
      }
    )
  }
  //   ----------------------------------------------------------------------------------------------------------------------

  healthHistoryData = (state) => {
    return this.props.detailsData?.health_history_responses?.map(
      (question, i) => {
        return (
          <div key={i}>
            <p>
              {`${question.question[state.lang].text}  `}
              <span>
                {question.response.length > 0
                  ? question.response
                    .map((j) => j[state.lang])
                    .filter((j) => !!j)
                    .join(", ")
                  : this.props.i18n.t('screening.no')}
              </span>
            </p>
          </div>
        )
      }
    )
  }

  followUpData = (state) => {
    return this.props.detailsData?.follow_up_responses?.map(
      (question, i) => {
        return (
          <div key={i}>
            <p>
              {`${question.question[state.lang].text}  `}
              <span style={{ textTransform: 'capitalize' }}>
                {!Array.isArray(question.response) ? question.response : question.response.length > 0
                  ? question.response
                    .map((j) => j[state.lang])
                    .filter((j) => !!j)
                    .join(", ")
                  : this.props.i18n.t('screening.no')}
              </span>
            </p>
          </div>
        )
      }
    )
  }

  getLifeFunctionValueResult = ({ id, response }) => {
    if (id === "lf_1") {
      if (response === -1) {
        return this.props.i18n.t('followUp.none')
      } else {
        return response.text[this.state.lang]
      }
    } else if (id === "lf_2") {
      if (response === -1) {
        return "0/60"
      } else {
        return `${response}/60`
      }
    } else {
      if (response === -1) {
        return "0/100"
      } else {
        return `${Math.trunc(response)}/100`
      }
    }
  }

  lifeFunctionsData = () => {
    return (
      this.props.detailsData &&
      this.props.detailsData.life_functions_responses?.map((question, i) => {
        console.log(question)
        return (
          <div key={i}>
            <p>
              {`${question.question[this.state.lang].text}  `}
              <span>
                {this.getLifeFunctionValueResult({
                  id: question.id,
                  question: question.question,
                  response: question.response,
                })}
              </span>
            </p>
          </div>
        )
      })
    )
  }

  indepthData = (state, disorder, response) => {
    return disorder?.map((question, i) => {
      return (
        <div key={i}>
          <p>
            {`${question.question[state.lang].text}  `}
            <span>
              {question.response === 0
                ? this.props.i18n.t('inDepth.never')
                : question.response === 1
                  ? this.props.i18n.t('inDepth.rarely')
                  : question.response === 2
                    ? this.props.i18n.t('inDepth.sometimes')
                    : question.response === 3
                      ? this.props.i18n.t('inDepth.frequently')
                      : this.props.i18n.t('inDepth.allTheTime')}
            </span>
          </p>
        </div>
      )
    })
  }
  showNotificationSettingsHandler = (isOpen) => {
    console.log(isOpen, "HERE")
    this.setState({ showNotificationSettings: isOpen })
    console.log(this.state.showNotificationSettings)
    return this.state.showNotificationSettings
  }

  showCheckListHandler = (isOpen) => {
    console.log(isOpen)
    this.setState({ showCheckList: isOpen })
    return this.state.showCheckList
  }

  toggleNotifSettings = () => {
    this.setState({
      showNotificationSettings: !this.state.showNotificationSettings,
    })
    return this.state.showNotificationSettings
  }

  progressTrackerSignUpNotifsHandler = async () => {
    // const id = _get(match, ["params", "id"])
    try {
      await fetch(
        `${baseUrl}/progress-tracker/signup-notifs/${this.props.match.params.id}`,
        {
          credentials: "include",
          mode: "cors",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      logEvent(
        "progressTrackerSignUpNotifsHandler /progress-tracker/signup-notifs"
      )
    } catch (err) {
      console.log("file: ====================== progressTrackerSignUpNotifsHandler === src/Modules/summary/SummaryDetails.jsx ================")
      console.log(err)
    }

  }

  //   ----------------------------------------------------------------------------------------------------------------------
  //   ----------------------------------------------------------------------------------------------------------------------

  render() {
    var state = this.state
    var props = this.props
    const { t, i18n, isError } = this.props

    return (
      <>
        <div className="main">
          {/* {topAlert} */}
          {/* <InstructionsModal /> */}
          <div className="main-container">
            <Header
              customWidth="100%"
              customMaxWidth="1440px" />
          </div>
          {/* <Navbar
            auth={state.auth}
            history={props.history}
            lang={this.state.lang}
            changeLanguage={this.changeLanguage}
          /> */}
          <div className="d-flex justify-content-center main-container details-content-part">
            <div
              className="card SummaryMainCard mb-5"
              style={{ width: "100%", marginTop: 10, background: !this.state.loading && !isError ? 'white' : 'transparent' }}
            >

              {!props.loading && !this.state.loading && !isError ? (
                <div className="summary-details">
                  <h2>{t("summaryDetails.header")}</h2>
                  {props.detailsData && (
                    <h3>
                      {t("summaryDetails.subHeader")}{" "}
                      <span>
                        {date.format(
                          new Date(props.detailsData.completed_at),
                          "MMM DD, YYYY"
                        )}
                      </span>{" "}
                      <small className="text-muted">
                        {t("summaryDetails.at")}{" "}
                        {date.format(
                          new Date(props.detailsData.completed_at),
                          "hh:mm A"
                        )}
                      </small>{" "}
                    </h3>
                  )}

                  <div className="card-body cardBody p-0">
                    <div className="row m-1">
                      <div className="my-1 mr-10">
                        {props.detailsData !== undefined && (
                          <DownloadDetails userData={props.detailsData}>
                            <div className="SummaryButton btn btn-block">
                              <i className="fas fa-download mr-10"></i>
                              {t("summaryDetails.download")}
                            </div>
                          </DownloadDetails>
                        )}
                      </div>
                      {/* <div className="my-1 mr-10">
                        
                          <div
                            className="SummaryButton btn btn-block"
                          >
                            <i className="fas fa-download mr-10"></i>
                            Download Action Plan
                          </div>
                      </div> */}
                      {/* <div className="my-1 mr-10">
                        <button className="SummaryButton btn btn-block">
                          <i className="fas fa-share mr-1"></i>
                          {t("summaryDetails.share")}
                        </button>
                      </div> */}
                    </div>
                  </div>
                  <div className="mt-4">
                    {this.accordion(
                      `${t("summaryDetails.generalQ")}`,
                      "GQ_heading",
                      "GQ_collapse",
                      "GQ_icon",
                      this.GeneralQuestionData(state)
                    )}
                    {props.detailsData?.in_deplth_response?.map((indepth, i) => {
                      return (
                        <div key={i}>
                          {this.accordion(
                            indepth.name,
                            indepth.name.slice(0, 2) + i + "_heading",
                            indepth.name.slice(0, 2) + i + "_collapse",
                            indepth.name.slice(0, 2) + i + "_icon",
                            this.indepthData(
                              state,
                              indepth[indepth.name],
                              indepth.response
                            )
                          )}
                        </div>
                      )
                    })}
                    {this.accordion(
                      `${t("summaryDetails.healthHistory")}`,
                      "HH_heading",
                      "HH_collapse",
                      "HH_icon",
                      this.healthHistoryData(state)
                    )}
                    {this.accordion(
                      `${t("summaryDetails.lifeFunc")}`,
                      "LF_heading",
                      "LF_collapse",
                      "LF_icon",
                      this.lifeFunctionsData(state)
                    )}
                    {this.props.detailsData?.follow_up_responses && this.props.detailsData?.follow_up_responses.length > 0 &&
                      this.accordion(
                        `${t("summaryDetails.followUp")}`,
                        "FU_heading",
                        "FU_collapse",
                        "FU_icon",
                        this.followUpData(state)
                      )
                    }
                  </div>
                </div>
              ) : <>
                <div className="text-center" style={{ display: 'flex', height: '100vh', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                  <div
                    className="spinner-border assessmentSpinner"
                    role="status"
                    style={{
                      width: '2rem',
                      height: '2rem',
                      border: '0.25em solid currentColor',
                      borderRightColor: 'transparent'
                    }}
                  ></div>
                  <span className="h1 ml-2">{t("summaryPage.loading")}</span>
                </div>
              </>}
            </div>
            {/* --------- */}
          </div>
          <div className="position-relative">
            <img
              src={require("../../assets/images/BackgroundPattern.png")}
              alt=""
              className="position-absolute"
              style={{ width: "100%", bottom: "0", zIndex: "-1" }}
            />
          </div>

          {/* <FloatingActionButton
            showFloatingButton={this.state.showFloatingButton}
            showNotificationSettings={this.showNotificationSettingsHandler}
            showCheckList={this.showCheckListHandler}
          /> */}

          {this.state.showNotificationSettings && (
            <NotificationScheduler
              onClose={this.toggleNotifSettings}
              signUpNotifsHandler={this.progressTrackerSignUpNotifsHandler}
            />
          )}

          {this.state.showCheckList && (
            <ProgressTracker
              closeCheckList={this.showCheckListHandler}
              assessment_id={this.props.match.params.id}
            />
          )}
          <Footer />
        </div>
      </>
    )
  }
}

export default withTranslation()(Summary);