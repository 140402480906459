import { baseUrl } from "../lib/constants";
import axios from "axios";
import { logEvent } from "../index"
import call from "../utils/call";

const options = {
  withCredentials: true,
  mode: "cors",
};

export const Pap = {
  get(assessmentId) {
    logEvent("Pap get /pap/list/disorders called");
    try {
      return call('get', `${baseUrl}/pap/list/disorders/${assessmentId}`, {}, {});
    } catch (e) {
      console.log("Error in file pap.js", e);
    }
  },
};

export const PrintPap = {
  get(assessmentId) {
    logEvent("PrintPap get /pap/list/disorders called");
    try {
      return axios.get(`${baseUrl}/print/list/${assessmentId}`, options);
    } catch (e) {
      console.log("Error in file pap.js", e);
    }
  }
}
