import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import MagicGrid from 'magic-grid-react';
import GreenCheckmarkSVG from '../../assets/images/green-checkmark.svg';
import { Accordion } from './Accordion';
import {
  SharedAccordionBackground,
  SharedDisorderSimpleText,
  SharedListElement,
  SharedDisorderBackground,
  SharedDisorderHeaderText,
} from '../../utils/shared';
import ReactLinkify from 'react-linkify';
import logClickEvent from '../../utils/logClickEvent';

export const SharedAccordionBackgroundTherapy = styled.div`
  background-color: #f7f7f7;
  padding: 1.1em;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin: auto;
`;

const therapyRaw = {
  'Cognitive Behavioural Therapy (CBT)': 'CBT',
  'Acceptance and Commitment Therapy (ACT)': 'ACT',
  Biofeedback: 'Biofeedback',
  'Dialectical Behaviour Therapy (DBT)': 'DBT',
  'Interpersonal Therapy (IPT)': 'IPT',
  'Marriage and Family Therapy': 'Familytherapy',
  'Psychodynamic Therapy': 'Psychodynamic',
  'Eye Movement Desensitization and Reprocessing (EMDR)': 'EMDR',
  'Group Therapy': 'Grouptherapy',
  'Motivational Interviewing (MI)': 'MI',
  'Brief Interventions': 'Briefinterventions',
  Psychoeducation: 'Psychoeducation',
  'Problem-Solving Therapy (PST)': 'PST',
  'EAP (Employee Assistance Program) Counselling': 'EAP',
  'Internet-based Cognitive Behavioral Therapy (iCBT)': 'iCBT',
  'Mindfulness Based Cognitive Therapy (MBCT)': 'MBCT',
  'Meditation-Based Therapy': 'MBT',
  'Self-Help Groups': 'SHG',
  'Twelve-Step Facilitation': 'TSF',
  'Attention Retraining': 'AR',
  'Behavioural Activation': 'BA',
  'Cognitive Behavioural Therapy for Insomnia (CBT-I)': 'CBTI',
  'Exposure-Based Therapies': 'EBT',
  'Mindfulness Based Stress Reduction': 'MBSR',
  'Addiction Counseling': 'AC',
  'Cognitive Remediation Therapy': 'CRT',
};

const therapyFr = {
  'Cognitive Behavioural Therapy (CBT)': 'Thérapie cognitivo-comportementale',
  'Acceptance and Commitment Therapy (ACT)': 'Thérapie d\'acceptation et d\'engagement',
  Biofeedback: 'Rétroaction biologique',
  'Dialectical Behaviour Therapy (DBT)': 'Thérapie comportementale dialectique',
  'Interpersonal Therapy (IPT)': 'Thérapie interpersonnelle',
  'Marriage and Family Therapy': 'Thérapie conjugale et familiale',
  'Psychodynamic Therapy': 'Thérapie psychodynamique',
  'Eye Movement Desensitization and Reprocessing (EMDR)': 'Désensibilisation et retraitement des mouvements oculaires',
  'Group Therapy': 'Thérapie de groupe',
  'Motivational Interviewing (MI)': 'Entretien motivationnel',
  'Twelve-Step Facilitation': 'Facilitation en douze étapes',
  'Brief Interventions': 'Interventions brèves',
  Psychoeducation: 'Psychoéducation',
  'Problem-Solving Therapy (PST)': 'Thérapie de résolution de problèmes',
  'EAP (Employee Assistance Program) Counselling': 'Counseling du PAE (Programme d\'aide aux employés)',
  'Internet-based Cognitive Behavioral Therapy (iCBT)': 'Thérapie cognitivo-comportementale basée sur Internet',
  'Mindfulness Based Cognitive Therapy (MBCT)': 'Thérapie cognitive basée sur la pleine conscience',
  'Meditation-Based Therapy': 'Thérapie basée sur la méditation',
  'Self-Help Groups': 'Groupes d\'entraide',
  'Attention Retraining': 'Rééducation attentionnelle',
  'Behavioural Activation': 'Activation comportementale',
  'Cognitive Behavioural Therapy for Insomnia (CBT-I)': 'Thérapie cognitivo-comportementale pour l\'insomnie',
  'Exposure-Based Therapies': "Thérapies basées sur l'exposition",
  'Mindfulness Based Stress Reduction': "Réduction du stress basée sur la pleine conscience",
  'Addiction Counseling': 'Conseil en toxicomanie',
  'Cognitive Remediation Therapy': 'Thérapie de remédiation cognitive',
};

export const MentalWellness = ({
  lang, mentalwellness, handleTherapyModalClick, data, t
}) => {
  const therapy = { ...therapyRaw };
  for (const therapyName in therapyRaw) {
    let description = '';
    if (data.data[therapyRaw[therapyName]] && Array.isArray(data.data[therapyRaw[therapyName]]) && data.data[therapyRaw[therapyName]].length > 0) {
      if (typeof data.data[therapyRaw[therapyName]][0].information === 'object' && data.data[therapyRaw[therapyName]][0].information.en) {
        description = data.data[therapyRaw[therapyName]][0].information[lang];
      } else if (Array.isArray(data.data[therapyRaw[therapyName]][0].information)) {
        if (typeof data.data[therapyRaw[therapyName]][0].information[0] === 'string') {
          description = data.data[therapyRaw[therapyName]][0].information[0][lang];
        } else {
          if (Array.isArray(data.data[therapyRaw[therapyName]][0].information[0].content)) {
            description = data.data[therapyRaw[therapyName]][0].information[0].content[0].content[lang]
          } else {
            description = data.data[therapyRaw[therapyName]][0].information[0].content[lang];
          }
        }
      }
    }
    // console.log(therapyName, ' ===> ', description, ' <=== i am desc')
    therapy[therapyName] = description;
  }

  const renderContent = (text) => {
    const cont = Array.isArray(text)
      ? text.map((info) => {
        let args = [];
        // console.log('current d info', info)
        if (Array.isArray(info.content)) {
          args = [...args, ...info.content.map((content) => (
            typeof content === 'object' && content.en ? content[lang]
              : React.createElement(content.tag || 'p', content.attr || {}, content.content[lang])
          ))];
        } else {
          args.push(info.content && info.content[lang]);
        }
        // console.log('current d args', args);
        return (
          React.createElement(info.tag || 'p', info.attr || {}, ...args)
        );
      })
      : ((typeof text === 'string' ? text : (text[lang])));
    return cont;
  }
  return (
    <>
      <Accordion onClick={() => logClickEvent('Total # Clicks on links in Mental Wellness Section', 9, 'Therapy')} key="mentalWellness-1" headerText={t('summaryPage.disorderSection.therapy')}>
        <MagicGrid
          maxColumns={2}
          gutter={16}
          items={Object.keys(therapy).length}
          useMin
          animate={false}
          useTransform={false}
        >
          {/* <DisorderList
                list={i.list}
                caption={i.caption}
                key={`${i.caption}${name + key}listitem`}
                lang={lang}
                handleTherapyModalClick={handleTherapyModalClick}
              /> */}
          {Object.keys(therapy).map((therapyName, index) => (
            <SharedDisorderBackground style={{ position: 'relative' }}>
              <Link
                data-toggle="modal"
                data-target="#therapyModal"
                className="therapy-education-links"
                onClick={
                  () => {
                    handleTherapyModalClick(therapyName);
                  }
                }
              >
                <SharedDisorderHeaderText>{lang === 'en' ? therapyName : therapyFr[therapyName]}</SharedDisorderHeaderText>
              </Link>
              <div>
                <SharedListElement key={index} css={css`
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                `}>
                  {therapy[therapyName]}
                </SharedListElement>
              </div>
            </SharedDisorderBackground>
          ))}
        </MagicGrid>
      </Accordion>
      {mentalwellness.map((i, index) => (
        <Accordion onClick={() => logClickEvent('Total # Clicks on links in Mental Wellness Section', 9, i.title[lang])} key={`mentalWellness${index}`} headerText={i.title[lang]}>
          <SharedAccordionBackground>
            <div
              css={css`
                display: grid;
                grid-template-columns: 1fr;
                grid-row-gap: 0.6em;
                word-break: break-word;
              `}
            >
              <ReactLinkify>
                <SharedDisorderSimpleText css={css` display: block;`}>
                  {renderContent(i.text)}
                </SharedDisorderSimpleText>
              </ReactLinkify>
            </div>
          </SharedAccordionBackground>
        </Accordion>
      ))}
    </>
  );
};
