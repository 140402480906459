import axios from 'axios'
import moment from 'moment'
import { logEvent } from '..'
import { baseUrl } from '../lib/constants'
import { encrypt, decrypt } from './enc'
let opt = {
    withCredentials: true,
    mode: 'cors',   
}

const cache_exeption_list = [
    '_get',
    '/user-profile_get',
    '/plans-goals/goal-default/plan-default/tasks_get',
    '/assessment/event_get',
    '/user/lastAssessment_get',
    '/assessment/inprogress_get',
    '/user/accesscode_get'
]

// console.log(encrypt('some tet'), decrypt('TMx4MceJI6CnSOGsQZZ8EA=='), ' <==== sometext to encrypt')
export const clearAPICache = () => {
    for(let i = 0; i < localStorage.length; i++) {
        let key = localStorage.key(i);
        if(key.startsWith('http')) {
            console.log('removing LS item : ', key)
            localStorage.removeItem(key)
        }
    }
}
export default async (method, url, data, headers, options = opt) => {
    if(!cache_exeption_list.includes(`${url}_${method}`.replace(baseUrl, ''))) {
        let item = localStorage.getItem(`${url}_${method}`)
        try {
            if(item) {
                item = decrypt(item) || 'false'
                item = JSON.parse(item)
            } 
        } catch(error) {

        }
        if(item) {
            let { data, timestamp } = item
            
            if(moment(timestamp).add(process.env.CACHE_EXP_TIME || 60, 'minutes').isAfter(moment())) {
                logEvent(`API CACHE STATUS : ${method} ${url} API CACHED`)
                console.log('API CACHE STATUS : ', method, ' ', url, ' ', 'API CACHED')
                return { data }
            }
        } else {
            localStorage.removeItem(`${url}_${method}`)
        }
    }
    
    try {
        let response = await axios({
            method,
            url,
            data,
            headers,
            ...options,
        })
        if(!cache_exeption_list.includes(`${url}_${method}`.replace(baseUrl, ''))) {
            try {
                let apiData = response.data
                // throw new Error('custom error')
                localStorage.setItem(`${url}_${method}`, encrypt(JSON.stringify({ data: apiData, timestamp: new Date() })))
            } catch (error) {
                console.log(error, '  <==== error while caching API')
                clearAPICache()
            }
        }
        
        console.log('API CACHE STATUS : ', method, ' ', url, ' ', 'API CACHE SKIPPED')
        return Promise.resolve(response)
    } catch(error) {
        
        console.log('API CACHE STATUS : ', method, ' ', url, ' ', 'API CACHE ERROR : ', error)
        return Promise.reject(error)
    }
}