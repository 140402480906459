import React, { Component, createRef, useState, useEffect } from "react"
import { Line } from "react-chartjs-2"
import date from "date-and-time"
import { GrPowerReset } from "react-icons/gr"
import { baseUrl } from "../../lib/constants"
import "chartjs-plugin-zoom"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import RefreshSVG from "../../assets/images/Refresh.svg"
import { logEvent } from "../../index"
import { getRiskStatus } from "../../utils/get-risk"
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import call from "../../utils/call"
import { disorderNames } from "../PAP/PAPMake"
Chart.register(CategoryScale);


const bgColorsArray = [
  "rgba(255, 188, 192, 1)",
  "rgba(169, 164, 177, 1)",
  "rgba(243, 165, 55, 1)",
  "rgba(157, 51, 147, 1)",
  "rgba(43, 119, 222, 1)",
  "rgba(198, 8, 118, 1)",
  "rgba(66, 175, 28, 1)",
  "rgba(255, 255, 0, 1)",
  "rgba(61, 0, 8, 1)",
  "rgba(255, 127, 0, 1)",
  "rgba(228, 228, 106, 1)",
  "rgba(0, 72, 169, 0.8)",
  "rgba(205, 14, 26, 0.8)"
]
const borderColorArray = [
  "rgba(255, 188, 192, 0.5)",
  "rgba(169, 164, 177, 0.5)",
  "rgba(243, 165, 55, 0.5)",
  "rgba(157, 51, 147, 0.5)",
  "rgba(43, 119, 222, 0.5)",
  "rgba(198, 8, 118, 0.5)",
  "rgba(66, 175, 28, 0.5)",
  "rgba(255, 255, 0, 0.5)",
  "rgba(61, 0, 8, 0.5)",
  "rgba(255, 127, 0, 0.5)",
  "rgba(228, 228, 106, 0.5)",
  "rgba(0, 72, 169, 1)",
  "rgba(205, 14, 26, 1)"
]

const LineChart = (props) => {
  const [enable, setEnable] = useState(undefined)
  const [historyData, setHistoryData] = useState(undefined)

  useEffect(() => {
    handleEnable()
  }, [])
  useEffect(() => {
    if (historyData) {
      if (historyData.dates.length >= 10) {
        setEnable(true)
      } else {
        setEnable(false)
      }
      console.log("enable: ", enable)
    }
  }, [historyData])

  const handleEnable = async () => {
    try {
      var { dates, chartInfo } = (await call('get', baseUrl + `/assessment/chart/${props._id}`, {}, {})).data
      logEvent("handleEnable /assessment/chart successful")
      setHistoryData({
        dates: dates.reverse(),
        chartInfo: chartInfo.map(({ name, scores }) => ({
          name,
          scores: scores.reverse(),
        })),
      })
    } catch (error) {
      console.log("file: ===================== src/Modules/summary/LineChart.jsx =====================")
      console.log(error)
    }

  }
  return enable === true || enable === false ? (
    <div>
      <LineChartClass
        _id={props._id}
        history={props.history}
        enable={enable}
        historyData={historyData}
        lang={props.lang}
      />
    </div>
  ) : null
}

export default LineChart

class LineChartClass extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // historyData: null,
      ids: [],
    }
    this.chart = createRef()
  }

  componentDidMount() {
    fetch(baseUrl, { credentials: "include", mode: "cors" }).then(
      (response) => {
        if (response.status === 200) {
          // this.getAllData(`${baseUrl}/assessment/chart/${this.props._id}`)
          this.getHistory(`${baseUrl}/assessment/history`)
        }
      }
    ).catch((err) => {
      console.log("file: ===================== src/Modules/summary/LineChart.jsx =====================")
      console.log(err);
    })
  }

  getHistory = async (link) => {
    try {
      const history = (await call('get', link, {}, {})).data

      let ids = []
      if (history != null) logEvent("getHistory /assessment/history successful")
      else logEvent("getHistory /assessment/history failed")
      history.forEach((assessment) => ids.push(assessment._id))
      this.setState({ ids })
    } catch (error) {
      console.log("file: ===================== src/Modules/summary/LineChart.jsx =====================")
      console.log(error)
    }
  }

  // getAllData = async (link) => {
  //   const response = await fetch(link, {
  //     credentials: "include",
  //     mode: "cors",
  //   })
  //   let { dates, chartInfo } = await response.json()
  //   this.setState({
  //     historyData: {
  //       dates: dates.reverse(),
  //       chartInfo: chartInfo.map(({ name, scores }) => ({
  //         name,
  //         scores: scores.reverse(),
  //       })),
  //     },
  //   })
  // }

  data = () => {
    const { lang } = this.props
    const dates =
      this.props.historyData &&
      this.props.historyData.dates.map((dates, index) => {
        return `${index + 1} (${date.format(new Date(dates), "DD/MM/YY")})`
      })

    const dataSetObj =
      this.props.historyData &&
      this.props.historyData.chartInfo.map((data, index) => {
        return {
          data: data.scores,
          label: disorderNames[data.name] ? disorderNames[data.name][lang] || data.name : data.name,
          fill: false,
          backgroundColor: bgColorsArray[index],
          borderColor: borderColorArray[index],
          pointBackgroundColor: "#fff",
          pointRadius: 5,
          pointBorderWidth: 2,
          lineTension: 0.2,
        }
      })

    return {
      labels: dates,
      datasets: dataSetObj,
    }
  }

  options = {
    tooltips: {
      backgroundColor: "#ffffff",
      titleFontColor: "#72738D",
      bodyFontColor: "#747587",
      caretSize: 8,
      displayColors: false,
      borderColor: "#d9d9d9",
      borderWidth: "1",
      boxShadow: "0px 7px 35px rgba(0, 0, 0, 0.09)",
      titleFontSize: 14,
      bodyFontSize: 14,
      enabled: false,
      callbacks: {
        title: (tooltipItem, data) => {
          let labels = []
          data.datasets.forEach((dataset, index) =>
            tooltipItem.forEach(
              (tooltip) =>
                tooltip.datasetIndex === index && labels.push(dataset.label === 'Mania/Hypomania' ? 'Bipolar Disorder' : dataset.label)
            )
          )
          return labels
        },
        label: (tooltipItem, data) => {
          const score = parseInt(
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
          )
          return `Score ${score} (${getRiskStatus(data.datasets[tooltipItem.datasetIndex].label, Math.round(score))})`
        },
      },
      custom: (tooltipModel) => {
        // Tooltip Element
        let tooltipEl = document.getElementById("chartjs-tooltip")

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement("div")
          tooltipEl.id = "chartjs-tooltip"
          tooltipEl.innerHTML = "<table></table>"
          document.body.appendChild(tooltipEl)
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0
          return
        }

        // Set caret Position
        tooltipEl.classList.remove("above", "below", "no-transform")
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign)
        } else {
          tooltipEl.classList.add("no-transform")
        }

        const getBody = (bodyItem) => {
          return bodyItem.lines
        }

        // Set Text
        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || []
          const bodyLines = tooltipModel.body.map(getBody)

          var innerHtml = '<thead style="color: #72738D;">'

          titleLines.forEach(function (title) {
            innerHtml += `<tr><th>${title}</th></tr>`
          })
          innerHtml += "</thead><tbody>"

          bodyLines.forEach(function (body, i) {
            innerHtml += `<tr style="color: #747587;"><td>${body}</td></tr>`
          })
          innerHtml += "</tbody>"

          let tableRoot = tooltipEl.querySelector("table")
          tableRoot.innerHTML = innerHtml
        }

        const position =
          this.chart.current.chartInstance.canvas.getBoundingClientRect()
        // var positionY = this._chart.canvas.offsetTop;
        var positionX = this.chart.current.chartInstance.canvas.offsetLeft;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1
        tooltipEl.style.position = "absolute"
        tooltipEl.style.left =
          position.left + window.pageXOffset + tooltipModel.caretX + "px"
        tooltipEl.style.top =
          position.top + window.pageYOffset + tooltipModel.caretY + "px"
        tooltipEl.style.font = tooltipModel.font
        tooltipEl.style.padding = "15px"
        tooltipEl.style.pointerEvents = "none"
        tooltipEl.style.backgroundColor = "#ffffff"
        tooltipEl.style.border = "1px #d9d9d9 solid"
        tooltipEl.style.boxShadow = "0px 7px 35px rgba(0, 0, 0, 0.09)"
        tooltipEl.style.borderRadius = "5px"

        var offset = tooltipModel.caretX + 20;
        if (offset < tooltipModel.width)
          offset = tooltipModel.width;
        else if (tooltipModel.caretX > this.chart.current.chartInstance.width - tooltipModel.width)
          offset = this.chart.current.chartInstance.width - tooltipModel.width;

        // Hidden Code
        tooltipEl.style.left = positionX + offset + 'px';
      },
    },
    scales: {
      xAxis:
      {
        gridLines: { display: false },
        scaleLabel: {
          display: true,
          labelString: "Assessment (Date)",
        },
      },
      yAxes:
      {
        min: 0,
        max: 100,
        stepSize: 10,
        ticks: {
          type: 'time',
          beginAtZero: true,
          weight: 100,
          suggestedMax: 100,
          padding: 0
        },
      },
    },
    onClick: (event, activeElements) => {
      // if (activeElements.length !== 0)
      //   this.props.history.push(
      //     `/summary/${this.state.ids[activeElements[0]._index]}`
      //   )
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
          speed: 10,
        },
        zoom: {
          enabled: this.props.enable,
          mode: "x",
          speed: 0.1,
        },
      },
      beforeDraw: function (c) {
        var reset_zoom = document.getElementById("reset_zoom") //reset button
        var ticks = c.scales["x-axis-0"].ticks.length //x-axis ticks array
        var labels = c.data.labels.length //labels array
        if (ticks < labels) reset_zoom.hidden = false
        else reset_zoom.hidden = true
      },
    }
  }

  render() {
    return (
      <div
        id="risk-tracker-chart"
        data-tut="risk-tracker-chart"
        className="d-flex justify-content-center"
        css={css`
          position: relative;
        `}
      >
        <Line
          id="graph"
          data={this.data()}
          options={this.options}
          width={100}
          height={30}
          legend={{
            align: "start",
            display: true,
            labels: {
              textAlign: "left",
              boxWidth: 40,
              boxHeight: 40,
            }
          }}
          ref={this.chart}
        />
        {/* <button
          id="reset_zoom"
          style={{ outline: "none" }}
          onClick={() => this.chart.current.chartInstance.resetZoom()}
          css={css`
            right: 0;
            top: -4.5em;
            width: 36px;
            position: absolute;
            cursor: pointer;
            border: none;
            background: white;
          `}
        >
          <img src={RefreshSVG} />
        </button> */}
      </div>
    )
  }
}

// measure x-axis, if the range
