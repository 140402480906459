import React, { useRef } from "react"
import Plan from "./Plan"
import EditIcon from "../../assets/images/edit-icon.svg"
import classnames from "classnames"
import Select from "react-select"

const customDropDownStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 2,
  }),
}

// const Action = ({ deleteTask, ControlComponent, updateTaskDays, deleteGoal, setAddNewPlan, planName, setPlanName, addPlan, addNewPlan, symptoms, onInputChange, goal, goalIndex, i18n, updateTaskStatus, updateGoalLoading, updateTaskType, getTaskDueDate, onDateChange, updateWeekDays, deletePlan, addTask, settaskTitle, taskTitle, getTaskTitle, editTitle, goalTitles, setGoalTitles, toggleTitleInput, updateGoalTitle, setEditTitle, setMenuIsOpen, menuIsOpen, CustomMenu, addSymptomToGoal, isActive,showTooltipOnhover }) => {
const Action = ({
  googleLogIn,
  deleteTask,
  updateTaskEventId,
  getAllEventsIn,
  setUpdateGoalLoading,
  allEvents,
  googleLoggedin,
  setGoogleLoggedin,
  ControlComponent,
  updateTaskDays,
  deleteGoal,
  setAddNewPlan,
  planName,
  setPlanName,
  addPlan,
  addNewPlan,
  symptoms,
  onInputChange,
  goal,
  goalIndex,
  i18n,
  updateTaskStatus,
  updateGoalLoading,
  updateTaskType,
  getTaskDueDate,
  onDateChange,
  updateWeekDays,
  deletePlan,
  addTask,
  settaskTitle,
  taskTitle,
  getTaskTitle,
  editTitle,
  goalTitles,
  setGoalTitles,
  toggleTitleInput,
  updateGoalTitle,
  setEditTitle,
  setMenuIsOpen,
  menuIsOpen,
  CustomMenu,
  addSymptomToGoal,
  validateToken,
  isActive,
  showTooltipOnhover,
  t,
  LOAD_INCENTIVES,
  LOAD_GOOGLE_CALENDAR,
  editDescription,
  setEditDescription,
  setGoalDescriptions,
  goalDescriptions,
}) => {
  const lang = i18n.language
  const planFormRef = useRef()

  return (
    <div className="card" style={{ overflow: "visible" }}>
      <div
        className="card-head collapsed"
        data-toggle="collapse"
        data-target={`#collapse_${goal.id}`}
        aria-expanded="true"
        aria-controls={`collapse_${goal.id}`}
        id={`heading_${goal.id}`}
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <h2 className="mb-0 remove-bg" id={`goal_title_${goalIndex}`}>
          {editTitle[goalIndex] ? (
            <input
              disabled={updateGoalLoading}
              className="title-input"
              id={`goal_title_input_${goalIndex}`}
              style={{ maxWidth: "90%", minWidth: "250px" }}
              value={goalTitles[goalIndex] || goal.title[lang]}
              onChange={(e) =>
                setGoalTitles({ ...goalTitles, [goalIndex]: e.target.value })
              }
            />
          ) : (
            <>
              {goal.title[lang]}
              {/* <img
                                onClick={() => toggleTitleInput(goalIndex)}
                                src={EditIcon}
                                className="ml-2"
                                data-tut="edit-btn"
                            /> */}
            </>
          )}
        </h2>
        <div
          className="collapse-icon collapsed"
          data-toggle="collapse"
          data-target={`#collapse_${goal.id}`}
          aria-expanded="true"
          aria-controls={`collapse_${goal.id}`}
          style={{ flex: 1 }}
        ></div>
      </div>
      <div className="save-title-btn-padding row ml-0">
        {editTitle[goalIndex] && (
          <>
            <button
              disabled={!goalTitles[goalIndex] || updateGoalLoading}
              onClick={() => updateGoalTitle(goalIndex, goal)}
              className={`btn-rect mt-3 ${
                (!goalTitles[goalIndex] || updateGoalLoading) && "btn-disabled"
              }`}
            >
              <span>{t("goalPage.goalAction.btnText1")}</span>
            </button>
            <button
              onClick={() => setEditTitle({ ...editTitle, [goalIndex]: false })}
              className={`btn-rect mt-3 btn-disabled ml-2`}
            >
              <span>{t("goalPage.goalAction.btnText2")}</span>
            </button>
          </>
        )}
      </div>
      <div
        id={`collapse_${goal.id}`}
        className={`collapse ${goalIndex === 0 && "show"}`}
        aria-labelledby={`heading_${goal.id}`}
        data-parent="#accordionExample"
      >
        <div className="card-body">
          <div className="chips-drodown-part">
            <div className="chips-part">
              <ul>
                {goal.symptoms &&
                  Object.keys(goal.symptoms).map((symptomKey) => (
                    <>
                      <li>{goal?.symptoms[symptomKey]?.title[lang]}</li>
                    </>
                  ))}
                <Select
                  defaultValue={{}}
                  isClearable
                  components={{
                    Control: (props) => (
                      <ControlComponent
                        {...props}
                        setMenuIsOpen={() => setMenuIsOpen(!menuIsOpen)}
                        updateGoalLoading={updateGoalLoading}
                      />
                    ),
                    Menu: CustomMenu,
                  }}
                  styles={customDropDownStyles}
                  isSearchable
                  onChange={(e) => addSymptomToGoal(e, goal)}
                  onInputChange={onInputChange}
                  menuIsOpen={menuIsOpen}
                  name="color"
                  options={symptoms
                    .filter((symptom) => !goal?.symptoms[symptom.id])
                    .map((symptom) => ({
                      label: symptom.title && symptom.title[lang],
                      value: symptom.id,
                    }))}
                />
              </ul>
            </div>
          </div>
          <p className="content">
            {editDescription[goalIndex] ? (
              <textarea
                disabled={updateGoalLoading}
                className="hidden-textarea"
                id={`textarea_${goalIndex}`}
                style={{ resize: "none" }}
                // onBlur={() => setEditDescription({ ...editDescription, [goalIndex]: false })}
                rows={1}
                placeholder="Add Description"
                onChange={(e) =>
                  setGoalDescriptions({
                    ...goalDescriptions,
                    [goalIndex]: e.target.value,
                  })
                }
              >
                {(goal.description && goal.description[lang]) || ""}
              </textarea>
            ) : (
              (goal.description && goal.description[lang]) || (
                <span className="text-muted"></span>
              )
            )}
            {editDescription[goalIndex] && (
              <div className="row ml-0">
                <button
                  disabled={!goalDescriptions[goalIndex] || updateGoalLoading}
                  onClick={() => updateDescription(goalIndex, goal)}
                  className={`btn-rect mt-3 ${
                    (!goalDescriptions[goalIndex] || updateGoalLoading) &&
                    "btn-disabled"
                  }`}
                >
                  <span>Save</span>
                </button>
                <button
                  onClick={() =>
                    setEditDescription({
                      ...editDescription[goalIndex],
                      [goalIndex]: false,
                    })
                  }
                  className={`btn-rect mt-3 btn-disabled ml-2`}
                >
                  <span>Cancel</span>
                </button>
              </div>
            )}
            {/* {!editDescription[goalIndex]
                                              && (
                                              <img
                                                onClick={() => {
                                                  setEditDescription({ ...editDescription[goalIndex], [goalIndex]: !editDescription[goalIndex] });
                                                  setTimeout(() => {
                                                    const el = document.getElementById(`textarea_${goalIndex}`);
                                                    el && el.focus();
                                                  }, 100);
                                                }}
                                                src={EditIcon}
                                                className="ml-2"
                                              />
                                            )} */}
          </p>
          <div className="full-divide-block">
            {goal.plans &&
              Object.keys(goal.plans).map((planKey) => (
                // <Plan updateTaskDays={updateTaskDays} goal={goal} deleteTask={deleteTask} goalIndex={goalIndex} planKey={planKey} i18n={i18n} updateTaskStatus={updateTaskStatus} updateGoalLoading={updateGoalLoading} updateTaskType={updateTaskType} getTaskDueDate={getTaskDueDate} onDateChange={onDateChange} updateWeekDays={updateWeekDays} deletePlan={deletePlan} addTask={addTask} settaskTitle={settaskTitle} taskTitle={taskTitle} getTaskTitle={getTaskTitle}  isActive={isActive} showTooltipOnhover={showTooltipOnhover} />
                <Plan
                  setUpdateGoalLoading={setUpdateGoalLoading}
                  getAllEventsIn={getAllEventsIn}
                  updateTaskEventId={updateTaskEventId}
                  validateToken={validateToken}
                  allEvents={allEvents}
                  googleLoggedin={googleLoggedin}
                  setGoogleLoggedin={setGoogleLoggedin}
                  updateTaskDays={updateTaskDays}
                  goal={goal}
                  deleteTask={deleteTask}
                  goalIndex={goalIndex}
                  planKey={planKey}
                  i18n={i18n}
                  updateTaskStatus={updateTaskStatus}
                  updateGoalLoading={updateGoalLoading}
                  updateTaskType={updateTaskType}
                  getTaskDueDate={getTaskDueDate}
                  onDateChange={onDateChange}
                  updateWeekDays={updateWeekDays}
                  deletePlan={deletePlan}
                  addTask={addTask}
                  settaskTitle={settaskTitle}
                  taskTitle={taskTitle}
                  getTaskTitle={getTaskTitle}
                  isActive={isActive}
                  showTooltipOnhover={showTooltipOnhover}
                  t={t}
                  googleLogIn={googleLogIn}
                  LOAD_INCENTIVES={LOAD_INCENTIVES}
                  LOAD_GOOGLE_CALENDAR={LOAD_GOOGLE_CALENDAR}
                />
              ))}
          </div>
          <div className="footer-btn row">
            {addNewPlan[goalIndex] && (
              <div className="col-md-6">
                <div className="add-btn">
                  <form
                    ref={planFormRef}
                    onSubmit={(e) => addPlan(e, goal, goalIndex)}
                  >
                    <input
                      disabled={updateGoalLoading}
                      type="text"
                      id="search-bar"
                      placeholder="Add Plan"
                      value={planName}
                      onChange={(e) => setPlanName(e.target.value)}
                    />
                    <a
                      disabled={updateGoalLoading}
                      onClick={(e) => addPlan(e, goal, goalIndex)}
                      href="#"
                    >
                      {updateGoalLoading ? (
                        <i className="fas fa-circle-notch fa-spin"></i>
                      ) : (
                        <span>Add</span>
                      )}
                    </a>
                  </form>
                </div>
              </div>
            )}
            <div className="col-md-6">
              <button
                disabled={updateGoalLoading}
                data-tut="add-plan-btn"
                onClick={() =>
                  setAddNewPlan({
                    ...addNewPlan,
                    [goalIndex]: !addNewPlan[goalIndex],
                  })
                }
                className={classnames(
                  "btn",
                  "z-index-0",
                  { "outline-btn": !addNewPlan[goalIndex] },
                  { "filled-btn": addNewPlan[goalIndex] }
                )}
              >
                {t("goalPage.goalAction.btnText3")}
              </button>
              <button
                disabled={updateGoalLoading}
                data-tut="delete-action-btn"
                onClick={() => deleteGoal(goal.id)}
                className="btn z-index-0"
              >
                {goal.is_default
                  ? t("goalPage.goalAction.btnText4")
                  : t("goalPage.goalAction.btnText4")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Action
