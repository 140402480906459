import React, {
  Component,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react"

import { Link, useNavigate } from "react-router-dom"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import { withTranslation } from "react-i18next"
import print from "print-js"
import date from "date-and-time"
import _get from "lodash/get"
import { AccordionCollapse } from "react-bootstrap"
import { openPopupWidget } from "react-calendly"
import { baseUrl } from "../../lib/constants"
import "../../css/main.css"
import "../../css/Summary.scss"
import LineChart from "./LineChart"
import Footer from "../../components/footer/Footer"
// import { InstructionsModal } from "../../components/InstructionsModal";
import Navbar from "../../components/navbar/Navbar"
import { FloatingActionButton } from "../../components/buttons/floatingActionButton"
import { ProgressTracker } from "../../components/progressTracker/ProgressTracker"
import { getSettings, fetchAssessmentTimestamp } from "../../api/assessment"
import { SummaryHealthHistory } from "./SummaryHealthHistory"
import { SummaryLifeFunctions } from "./SummaryLifeFunctions"
import { MentalWellness } from "./MentalWellness"
import { TopButtons } from "./TopButtons"
import { NotificationScheduler } from "../../components/popups/notificationScheduler/NotificationScheduler"
import { SendAssessmentPdf } from "../../components/popups/shareAssessment/SendAssessmentPdf"
import ShareAssessment from "../../components/popups/shareAssessment/ShareAssessment"
import BgImg from "../../assets/images/top-bg-bottom-gradient2.png"
import { SharedDivider } from "../../utils/shared"
import GreenCheckmarkSVG from "../../assets/images/green-checkmark.svg"
import { Accordion } from "./Accordion"
import { Disorder } from "./Disorder"
import ClinicalAdvisoryCard from "../../components/about/ClinicalAdvisoryCard"
import DisorderTherapyModal from "./DisorderTherapyModal"
import { StateContext } from "../../store/state/contexts"
import LearnMoreModal from "./LearnMoreModal"
import { makepdf } from '../PAP/PDFFile';
import { Pap } from "../../api/pap"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorScreen } from "../../components/error/ErrorScreen"
import { logEvent } from "../../index"
import axios from "axios"
import Header from "../../components/navbar/Header"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import Tour from "reactour"
import ScrollToID from "../Assessment/ScrollToCard"

import Joyride, { STATUS } from "react-joyride"
import { useGoogleLogin } from "@react-oauth/google"
import DatePicker from "react-datepicker"

import Snackbar from "@mui/material/Snackbar"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Alert from "@mui/material/Alert"
import {
  validateToken,
  fetchGoogleAccessToken,
  GOOGLE_CALENDAR_SCOPES,
} from "../../utils/googleCalendar"
import moment from "moment/moment"
import call from "../../utils/call"
import logClickEvent from "../../utils/logClickEvent"
import ReactSlidingOutcomePanel from "../../components/bottom-slider/ReactSlidingOutcomePanel"

const store = require("store")

export const EBlockWrapper = styled.div`
  margin: 10px auto 0 auto;
  max-width: 90em;
  padding: 0 2em;
  position: relative;
  font-size: 18px;
  color: #505050;
  // font-family: 'Poppins';
  @media (max-width: 1440px) {
    max-width: 90em;
  }
  @media (max-width: 1024px) {
    margin: 1em auto 0 auto;
  }
  @media (max-width: 991px) {
    margin: 1em auto 0 auto;
  }
  @media (max-width: 768px) {
    margin: 0em auto 0 auto;
  }
`

export const EMainBlock = styled.div`
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 5px 20px rgba(48, 62, 56, 0.0660238);
  display: flex;
  flex-direction: column;
  min-height: 60vh;
`

export const ETopMainCaption = styled.div`
  background-color: #f7f7f7;
  border-bottom: 1px solid #eee;
  margin: 0;
  padding: 1em 2em;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  h1 {
    color: #151852;
    font-size: 1.3em;
    font-weight: 600;
    margin: 0;
    @media screen and (max-device-width: 640px) {
      font-size: 1.2em;
      text-align: center;
    }
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-device-width: 640px) {
    display: block;
  }
`

export const EH2 = styled.h2`
  color: #151852;
  font-size: 1.3em;
  font-weight: 600;
  margin: 0;
`

const EH3 = styled.h4`
  color: #151852;
  font-size: 1.3em;
  font-weight: 200;
  margin: 0;
`

const EGreyDividerLine = styled.div`
  background-color: #eee;
  height: 2px;
  width: 100%;
`

export const EDateCaption = styled.p`
  margin: 0;
  color: #69696f;
`
export const SharedDisorderSimpleText = styled.span`
  font-size: 0.99em;
  margin-left: 0;
  margin: 1em 0 1.5em 0;
  float: right;
  text-align: left;
  display: flex;
  align-self: flex-start;
  color: rgb(80, 80, 80);
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
`

function Summary(props) {
  const { state, dispatch } = useContext(StateContext)
  const [loading, setLoading] = useState(true)
  const [lastDate, setDate] = useState(null)
  const [AssessmentDisorders, setAssessmentDisorders] = useState([])
  const [fullHistory, setFullHistory] = useState([])
  const [tourOpen, setTourOpen] = useState(false)
  const [anchor, setAnchor] = useState("")
  const [currentStep, setCurrentStep] = useState(1)
  const tour = useRef(null)
  const [open, setOpen] = React.useState(false)
  const history = useNavigate()
  const [run, setRun] = useState(false)
  const { t, i18n } = props
  const [defaultTasks, setDefaultTasks] = useState([])
  const [googleLoggedin, setGoogleLoggedin] = useState(false)
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [syncedEvent, setSyncedEvent] = useState(false)
  const [syncEventLoading, setSyncEventLoading] = useState(false)
  const [hh, setHH] = useState("")
  const [mm, setMM] = useState("")
  const [ss, setSS] = useState("")
  const [assessmentEventDate, setAssessmentEventDate] = useState("")
  const syncNowMinRef = useRef()
  const syncNowSecRef = useRef()

  const isEventValid = () => {
    if (assessmentEventDate && hh && mm && ss) {
      return true
    }
    return false
  }

  const getDefaultTasks = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/plans-goals/goal-default/plan-default/tasks`,
        {
          withCredentials: true,
          mode: "cors",
        }
      )
      setDefaultTasks(response.data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getDefaultTasks()
  }, [])

  useEffect(() => {
    if (ss.length > 1) {
    } else if (mm.length > 1) {
      syncNowSecRef && syncNowSecRef.current && syncNowSecRef.current.focus()
    } else if (hh.length > 1) {
      syncNowMinRef && syncNowMinRef.current && syncNowMinRef.current.focus()
    }
  }, [hh, mm, ss])

  useEffect(() => {
    if (parseInt(hh) > 23) {
      setHH(23)
    }
  }, [hh])

  useEffect(() => {
    if (parseInt(mm) > 59) {
      setMM(59)
    }
  }, [mm])

  useEffect(() => {
    if (parseInt(ss) > 59) {
      setSS(59)
    }
  }, [ss])

  const handleSyncEvent = async () => {
    setSyncEventLoading(true)
    const eventMoment = moment(assessmentEventDate)
    eventMoment.hours(hh)
    eventMoment.minutes(mm)
    eventMoment.seconds(ss)

    const event = {
      summary: "Your assessment is ready!",
      start: {
        dateTime: eventMoment.toISOString(),
        timeZone: timezone,
      },
      end: {
        dateTime: eventMoment.toISOString(),
        timeZone: timezone,
      },
    }

    try {
      const res = await addEventIn(event)
      if (res) {
        const assessmentEvent = {
          eventId: res.id,
          eventDate: res.start.dateTime,
        }
        const response = await axios.post(
          `${baseUrl}/assessment/event`,
          assessmentEvent,
          {
            withCredentials: true,
            mode: "cors",
          }
        )
        console.log("response", response)
        await validateAssessmentDate()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setSyncEventLoading(false)
    }
  }

  const addEventIn = async (event) => {
    try {
      const res = await axios.post(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events?access_token=${localStorage.getItem(
          "google_access_token"
        )}`,
        event
      )
      await validateToken()
      console.log(res)
      return res.data
    } catch (e) {
      console.log(e)
    }
  }

  const handleLoginSuccess = async (tokenResponse) => {
    try {
      const fetchResponse = await fetchGoogleAccessToken(tokenResponse)
      if (fetchResponse == true) {
        const validateResponse = await validateToken()
        if (validateResponse == true) {
          setGoogleLoggedin(true)
          console.log("Authorized")
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      setSyncEventLoading(false)
    }
  }

  const googleLogIn = useGoogleLogin({
    onSuccess: handleLoginSuccess,
    onError: (error) => {
      console.log("login error :- ", error)
      setSyncEventLoading(false)
    },
    flow: "auth-code",
    ux_mode: "popup",
    scope: GOOGLE_CALENDAR_SCOPES,
  })

  useEffect(() => {
    ;(async () => {
      if (localStorage.getItem("google_access_token")) {
        const response = await validateToken()
        if (response == true) {
          setGoogleLoggedin(true)
          console.log("Authorized")
        }
      } else {
        console.log("Access token not found login required")
      }
    })()
  }, [])

  const getAssessmentEvent = async () => {
    try {
      const response = await axios.get(`${baseUrl}/assessment/event`, {
        withCredentials: true,
        mode: "cors",
      })
      if (!response) {
        return null
      }
      return response.data
    } catch (e) {
      console.log(e)
    }
  }

  const validateAssessmentDate = async () => {
    try {
      const event = await getAssessmentEvent()
      if (event) {
        const currentMoment = moment(new Date())
        const assessmentMoment = moment(event.eventDate)
        if (currentMoment.isBefore(assessmentMoment)) {
          setSyncedEvent(true)
          setAssessmentEventDate(new Date(event.eventDate))
          setHH(assessmentMoment.hours())
          setMM(assessmentMoment.minutes())
          setSS(assessmentMoment.seconds())
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        await validateAssessmentDate()
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  const setTourTooltipViewed = () => {
    let tourTooltipCount = localStorage.getItem(
      "__FBN_SUMMARY_TOURTOOLTIP_SHOW__"
    )
    if (tourTooltipCount) {
      tourTooltipCount = parseInt(tourTooltipCount)
      localStorage.setItem(
        "__FBN_SUMMARY_TOURTOOLTIP_SHOW__",
        tourTooltipCount + 1
      )
    } else {
      localStorage.setItem("__FBN_SUMMARY_TOURTOOLTIP_SHOW__", 1)
    }
  }

  // useEffect(() => {
  //   if (run) {
  //     // if (document.querySelector('#top-btn-1')) document.querySelector('#top-btn-1').classList.add('hover');
  //     // if (document.querySelector('#top-btn-2')) document.querySelector('#top-btn-2').classList.add('hover');
  //     // if (document.querySelector('#top-btn-3')) document.querySelector('#top-btn-3').classList.add('hover');
  //     // if (document.querySelector('#top-btn-4')) document.querySelector('#top-btn-4').classList.add('hover');
  //     // if (document.querySelector('#top-btn-5')) document.querySelector('#top-btn-5').classList.add('hover');
  //   } else {
  //     // if (document.querySelector('#top-btn-1')) { document.querySelector('#top-btn-1').classList.remove('hover') }
  //     // if (document.querySelector('#top-btn-2')) { document.querySelector('#top-btn-2').classList.remove('hover') }
  //     // if (document.querySelector('#top-btn-3')) { document.querySelector('#top-btn-3').classList.remove('hover') }
  //     // if (document.querySelector('#top-btn-4')) { document.querySelector('#top-btn-4').classList.remove('hover') }
  //     // if (document.querySelector('#top-btn-5')) { document.querySelector('#top-btn-5').classList.remove('hover') }
  //   }
  // }, [run]);

  // const closeTour = () => {
  //   setTourOpen(false);
  //   // if(menuIsOpen) closeSelect();
  //   setTourTooltipViewed();
  // };

  const disableBody = (target) => disableBodyScroll(target)
  const enableBody = (target) => {
    enableBodyScroll(target)

    // document.querySelector('#top-btn-1').classList.remove('hover');
    // document.querySelector('#top-btn-2').classList.remove('hover');
    // document.querySelector('#top-btn-3').classList.remove('hover');
    // document.querySelector('#top-btn-4').classList.remove('hover');
    // document.querySelector('#top-btn-5').classList.remove('hover');
  }

  const anchorSelector = (el) => {
    if (el == anchor || !document.querySelector(el)) return false

    setAnchor(el)

    const currentEl = document.querySelector(el)
    const rect = currentEl.getBoundingClientRect()

    if (rect.top + rect.height > window.innerHeight / 1.5 || rect.top < 50) {
      document.scrollingElement.scrollTop += rect.top - 50
    }
  }

  const tourConfig = [
    {
      disableBeacon: true,
      target: '[data-tut="top-btn-1"]',
      content: t("summaryPage.tour.step1"),
      action: () => {
        // document.querySelector('#top-btn-1').classList.add('hover');
        // document.querySelector('#top-btn-2').classList.remove('hover');
        // document.querySelector('#top-btn-3').classList.remove('hover');
        // document.querySelector('#top-btn-4').classList.remove('hover');
        // document.querySelector('#top-btn-5').classList.remove('hover');
      },
    },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="top-btn-1-plans-goals"]',
    //   content: t('summaryPage.tour.step2'),
    //   action: () => {
    //     document.querySelector('#top-btn-1').classList.add('hover');
    //     document.querySelector('#top-btn-2').classList.remove('hover');
    //     document.querySelector('#top-btn-3').classList.remove('hover');
    //     document.querySelector('#top-btn-4').classList.remove('hover');
    //     document.querySelector('#top-btn-5').classList.remove('hover');
    //   },
    // },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="top-btn-1-download-pap"]',
    //   content: t('summaryPage.tour.step3'),
    //   action: () => {
    //     document.querySelector('#top-btn-1').classList.add('hover');
    //     document.querySelector('#top-btn-2').classList.remove('hover');
    //     document.querySelector('#top-btn-3').classList.remove('hover');
    //     document.querySelector('#top-btn-4').classList.remove('hover');
    //     document.querySelector('#top-btn-5').classList.remove('hover');
    //   },
    // },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="top-btn-1-share-pap"]',
    //   content: t('summaryPage.tour.step4'),
    //   action: () => {
    //     document.querySelector('#top-btn-1').classList.add('hover');
    //     document.querySelector('#top-btn-2').classList.remove('hover');
    //     document.querySelector('#top-btn-3').classList.remove('hover');
    //     document.querySelector('#top-btn-4').classList.remove('hover');
    //     document.querySelector('#top-btn-5').classList.remove('hover');
    //   },
    // },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="top-btn-1-review"]',
    //   content: t('summaryPage.tour.step5'),
    //   action: () => {
    //     document.querySelector('#top-btn-1').classList.add('hover');
    //     document.querySelector('#top-btn-2').classList.remove('hover');
    //     document.querySelector('#top-btn-3').classList.remove('hover');
    //     document.querySelector('#top-btn-4').classList.remove('hover');
    //     document.querySelector('#top-btn-5').classList.remove('hover');
    //   },
    // },
    // Button 2
    {
      disableBeacon: true,
      target: '[data-tut="top-btn-2"]',
      content: t("summaryPage.tour.step6"),
      action: () => {
        // document.querySelector('#top-btn-2').classList.add('hover');
        // document.querySelector('#top-btn-1').classList.remove('hover');
        // document.querySelector('#top-btn-3').classList.remove('hover');
        // document.querySelector('#top-btn-4').classList.remove('hover');
        // document.querySelector('#top-btn-5').classList.remove('hover');
      },
    },
    {
      disableBeacon: true,
      target: '[data-tut="top-btn-3"]',
      content: t("summaryPage.tour.step17"),
      action: () => {
        // document.querySelector('#top-btn-2').classList.add('hover');
        // document.querySelector('#top-btn-1').classList.remove('hover');
        // document.querySelector('#top-btn-3').classList.remove('hover');
        // document.querySelector('#top-btn-4').classList.remove('hover');
        // document.querySelector('#top-btn-5').classList.remove('hover');
      },
    },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="top-btn-2-download-assessment"]',
    //   content: t('summaryPage.tour.step7'),
    //   action: () => {
    //     document.querySelector('#top-btn-2').classList.add('hover');
    //     document.querySelector('#top-btn-1').classList.remove('hover');
    //     document.querySelector('#top-btn-3').classList.remove('hover');
    //     document.querySelector('#top-btn-4').classList.remove('hover');
    //     document.querySelector('#top-btn-5').classList.remove('hover');
    //   },
    // },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="top-btn-2-share-assessment"]',
    //   content: t('summaryPage.tour.step8'),
    //   action: () => {
    //     document.querySelector('#top-btn-2').classList.add('hover');
    //     document.querySelector('#top-btn-1').classList.remove('hover');
    //     document.querySelector('#top-btn-3').classList.remove('hover');
    //     document.querySelector('#top-btn-4').classList.remove('hover');
    //     document.querySelector('#top-btn-5').classList.remove('hover');
    //   },
    // },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="top-btn-2-review"]',
    //   content: t('summaryPage.tour.step9'),
    //   action: () => {
    //     document.querySelector('#top-btn-2').classList.add('hover');
    //     document.querySelector('#top-btn-1').classList.remove('hover');
    //     document.querySelector('#top-btn-3').classList.remove('hover');
    //     document.querySelector('#top-btn-4').classList.remove('hover');
    //     document.querySelector('#top-btn-5').classList.remove('hover');
    //   },
    // },
    // Button 3
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="top-btn-3"]',
    //   content: t('summaryPage.tour.step10'),
    //   action: () => {
    //     document.querySelector('#top-btn-3').classList.add('hover');

    //     document.querySelector('#top-btn-1').classList.remove('hover');
    //     document.querySelector('#top-btn-2').classList.remove('hover');
    //     document.querySelector('#top-btn-4').classList.remove('hover');
    //     document.querySelector('#top-btn-5').classList.remove('hover');
    //   },
    // },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="top-btn-3-review"]',
    //   content: t('summaryPage.tour.step11'),
    //   action: () => {
    //     document.querySelector('#top-btn-3').classList.add('hover');

    //     document.querySelector('#top-btn-1').classList.remove('hover');
    //     document.querySelector('#top-btn-2').classList.remove('hover');
    //     document.querySelector('#top-btn-4').classList.remove('hover');
    //     document.querySelector('#top-btn-5').classList.remove('hover');
    //   },
    // },
    // Button 4
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="top-btn-4"]',
    //   content: t('summaryPage.tour.step12'),
    //   action: () => {
    //     document.querySelector('#top-btn-4').classList.add('hover');

    //     document.querySelector('#top-btn-1').classList.remove('hover');
    //     document.querySelector('#top-btn-2').classList.remove('hover');
    //     document.querySelector('#top-btn-3').classList.remove('hover');
    //     document.querySelector('#top-btn-5').classList.remove('hover');
    //   },
    // },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="top-btn-4-review"]',
    //   content: t('summaryPage.tour.step13'),
    //   action: () => {
    //     document.querySelector('#top-btn-4').classList.add('hover');

    //     document.querySelector('#top-btn-1').classList.remove('hover');
    //     document.querySelector('#top-btn-2').classList.remove('hover');
    //     document.querySelector('#top-btn-3').classList.remove('hover');
    //     document.querySelector('#top-btn-5').classList.remove('hover');
    //   },
    // },
    // Button 5
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="top-btn-5"]',
    //   content: t('summaryPage.tour.step14'),
    //   action: () => {
    //     document.querySelector('#top-btn-5').classList.add('hover');

    //     document.querySelector('#top-btn-1').classList.remove('hover');
    //     document.querySelector('#top-btn-2').classList.remove('hover');
    //     document.querySelector('#top-btn-3').classList.remove('hover');
    //     document.querySelector('#top-btn-4').classList.remove('hover');
    //   },
    // },
    // {
    //   disableBeacon: true,
    //   target: '[data-tut="top-btn-5-schedule"]',
    //   content: t('summaryPage.tour.step15'),
    //   action: () => {
    //     document.querySelector('#top-btn-5').classList.add('hover');

    //     document.querySelector('#top-btn-1').classList.remove('hover');
    //     document.querySelector('#top-btn-2').classList.remove('hover');
    //     document.querySelector('#top-btn-3').classList.remove('hover');
    //     document.querySelector('#top-btn-4').classList.remove('hover');
    //   },
    // },
    {
      disableBeacon: true,
      target: '[data-tut="risk-tracker-chart"]',
      content: t("summaryPage.tour.step16"),
      observe: "#risk-tracker-chart",
      delay: 2000,
      action: () => {
        // ScrollToID('risk-tracker-chart');
        // window.scrollTo('#risk-tracker-chart');
        document.getElementById("scroll-to-chart").scrollIntoView()
        // document.querySelector('#top-btn-5').classList.remove('hover');
        setCurrentStep(10)

        // document.querySelector('#top-btn-1').classList.remove('hover');
        // document.querySelector('#top-btn-2').classList.remove('hover');
        // document.querySelector('#top-btn-3').classList.remove('hover');
        // document.querySelector('#top-btn-4').classList.remove('hover');
        // document.querySelector('#top-btn-5').classList.remove('hover');
      },
    },
  ]

  const w = async (link) => {
    try {
      dispatch({ type: "SET_DETAILS_DATA_LOADING", payload: true })
      const details = (await call("get", link, {}, {})).data
      logEvent("w /assessment/details called")
      console.log("detailsData:", details)
      dispatch({ type: "SET_DETAILS_DATA", payload: details })
      dispatch({ type: "SET_DETAILS_DATA_LOADING", payload: false })
      return details
    } catch (err) {
      setOpen(true)
      dispatch({ type: "SET_DETAILS_DATA_LOADING", payload: false })
      setTimeout(() => {
        history.goBack()
      }, 5000)
      console.log(
        "file: ======================== w === src/Modules/summary/Summary.jsx ========================"
      )
      console.log(err)
      return {}
    }
  }

  const getPAPDetails = async (id) => {
    Pap.get(id).then((data) => setAssessmentDisorders(data.data))
    return AssessmentDisorders
  }

  const getHistory = async () => {
    try {
      const history = (await call("get", `${baseUrl}/assessment/history`)).data
      logEvent("getHistory /assessment/history called")
      setFullHistory(history)
    } catch (err) {
      console.log(
        "file: ======================== getPAPDetails === src/Modules/summary/Summary.jsx ========================"
      )
      console.log(err)
    }
  }

  const showTourTooltipBool = () => {
    let tourTooltipCount = localStorage.getItem(
      "__FBN_SUMMARY_TOURTOOLTIP_SHOW__"
    )
    if (tourTooltipCount) {
      tourTooltipCount = parseInt(tourTooltipCount)
      if (tourTooltipCount < 4 && tourTooltipCount != 3) {
        return true
      }
      return false
    }
    return true
  }

  const lastAssessmentDate = async () => {
    try {
      const res = await fetch(`${baseUrl}/user/lastAssessment`, {
        credentials: "include",
        mode: "cors",
        method: "GET",
      })
      const lastAssessment = await res.json()
      logEvent("lastAssessmentDate /user/lastAssessment called")
      setDate(
        new Date(
          new Date(lastAssessment).setDate(
            new Date(lastAssessment).getDate() + 14
          )
        )
      )
      return lastAssessment
    } catch (err) {
      console.log(
        "file: ======================== lastAssessmentDate === src/Modules/summary/Summary.jsx ========================"
      )
      console.log(err)
      return {}
    }
  }

  const [progressTracker, setProgressTracker] = useState({
    downloadPAPComplete: false,
    sharePAPComplete: false,
    viewPAPComplete: false,
    downloadAssessmentComplete: false,
    shareAssessmentComplete: false,
    viewAssessmentComplete: false,
    reviewDisordersComplete: false,
    reviewScoresComplete: false,
    signUpNotifsComplete: false,
  })

  const getProgressTracker = async () => {
    try {
      const res = await fetch(
        `${baseUrl}/progress-tracker/${props.match.params.id}`,
        {
          credentials: "include",
          mode: "cors",
          method: "GET",
        }
      )
      const _progressTracker = await res.json()
      logEvent("getProgressTracker /assessment/details called")
      setProgressTracker(_progressTracker)
    } catch (err) {
      console.log(
        "file: ======================== getProgressTracker === src/Modules/summary/Summary.jsx ========================"
      )
      console.log(err)
    }
  }

  // useEffect(() => {
  //   console.log(progressTracker);
  // }, [progressTracker]);

  const progressTrackerDownloadPAPHandler = async () => {
    try {
      await fetch(
        `${baseUrl}/progress-tracker/download-action-plan/${props.match.params.id}`,
        {
          credentials: "include",
          mode: "cors",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      logEvent(
        "progressTrackerDownloadPAPHandler /progress-tracker/download-action-plan called"
      )
      setProgressTracker({ ...progressTracker, downloadPAPComplete: true })
    } catch (err) {
      console.log(
        "file: ======================== progressTrackerDownloadPAPHandler ===== src/Modules/summary/Summary.jsx ========================"
      )
      console.log(err)
    }
  }

  const progressTrackerSharePAPHandler = async () => {
    try {
      await fetch(
        `${baseUrl}/progress-tracker/share-action-plan/${props.match.params.id}`,
        {
          credentials: "include",
          mode: "cors",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      logEvent(
        "progressTrackerSharePAPHandler /progress-tracker/share-action-plan called"
      )
      setProgressTracker({ ...progressTracker, sharePAPComplete: true })
    } catch (err) {
      console.log(
        "file: ======================== progressTrackerSharePAPHandler === src/Modules/summary/Summary.jsx ========================"
      )
      console.log(err)
    }
  }

  const progressTrackerViewPAPHandler = async () => {
    try {
      await fetch(
        `${baseUrl}/progress-tracker/view-action-plan/${props.match.params.id}`,
        {
          credentials: "include",
          mode: "cors",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      logEvent(
        "progressTrackerViewPAPHandler /progress-tracker/view-action-plan called"
      )
      setProgressTracker({ ...progressTracker, viewPAPComplete: true })
    } catch (err) {
      console.log(
        "file: ======================== progressTrackerViewPAPHandler === src/Modules/summary/Summary.jsx ========================"
      )
      console.log(err)
    }
  }

  const progressTrackerDownloadAssessmentHandler = async () => {
    try {
      await fetch(
        `${baseUrl}/progress-tracker/download-assessment/${props.match.params.id}`,
        {
          credentials: "include",
          mode: "cors",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      logEvent(
        "progressTrackerDownloadAssessmentHandler /progress-tracker/download-assessment called"
      )
      setProgressTracker({
        ...progressTracker,
        downloadAssessmentComplete: true,
      })
    } catch (err) {
      console.log(
        "file: ======================== progressTrackerDownloadAssessmentHandler === src/Modules/summary/Summary.jsx ========================"
      )
      console.log(err)
    }
  }

  const progressTrackerShareAssessmentHandler = async () => {
    try {
      await fetch(
        `${baseUrl}/progress-tracker/share-assessment/${props.match.params.id}`,
        {
          credentials: "include",
          mode: "cors",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      logEvent(
        "progressTrackerShareAssessmentHandler /progress-tracker/share-assessment called"
      )
      setProgressTracker({ ...progressTracker, shareAssessmentComplete: true })
    } catch (err) {
      console.log(
        "file: ======================== progressTrackerShareAssessmentHandler === src/Modules/summary/Summary.jsx ========================"
      )
      console.log(err)
    }
  }

  const progressTrackerViewAssessmentHandler = async () => {
    try {
      await fetch(
        `${baseUrl}/progress-tracker/view-assessment/${props.match.params.id}`,
        {
          credentials: "include",
          mode: "cors",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      logEvent(
        "progressTrackerViewAssessmentHandler /progress-tracker/view-assessment called"
      )
      setProgressTracker({ ...progressTracker, viewAssessmentComplete: true })
    } catch (err) {
      console.log(
        "file: ======================== progressTrackerViewAssessmentHandler === src/Modules/summary/Summary.jsx ========================"
      )
      console.log(err)
    }
  }

  const progressTrackerReviewDisorderHandler = async () => {
    try {
      await fetch(
        `${baseUrl}/progress-tracker/review-disorders/${props.match.params.id}`,
        {
          credentials: "include",
          mode: "cors",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      logEvent(
        "progressTrackerReviewDisorderHandler /progress-tracker/review-disorders called"
      )
      setProgressTracker({ ...progressTracker, reviewDisordersComplete: true })
    } catch (err) {
      console.log(
        "file: ======================== progressTrackerReviewDisorderHandler === src/Modules/summary/Summary.jsx ========================"
      )
      console.log(err)
    }
  }

  const progressTrackerReviewScoresHandler = async () => {
    try {
      await fetch(
        `${baseUrl}/progress-tracker/review-scores/${props.match.params.id}`,
        {
          credentials: "include",
          mode: "cors",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      logEvent(
        "progressTrackerReviewScoresHandler /progress-tracker/review-scores called"
      )
      setProgressTracker({ ...progressTracker, reviewScoresComplete: true })
    } catch (err) {
      console.log(
        "file: ======================== progressTrackerReviewScoresHandler === src/Modules/summary/Summary.jsx ========================"
      )
      console.log(err)
    }
  }

  const progressTrackerSignUpNotifsHandler = async () => {
    try {
      await fetch(
        `${baseUrl}/progress-tracker/signup-notifs/${props.match.params.id}`,
        {
          credentials: "include",
          mode: "cors",
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      logEvent(
        "progressTrackerSignUpNotifsHandler /progress-tracker/signup-notifs called"
      )
      setProgressTracker({ ...progressTracker, signUpNotifsComplete: true })
    } catch (err) {
      console.log(
        "file: ======================== progressTrackerSignUpNotifsHandler === src/Modules/summary/Summary.jsx ========================"
      )
      console.log(err)
    }
  }

  const userSummary = async (link) => {
    try {
      const user = (await call("get", link, {}, {})).data
      logEvent("userSummary /assessment/history called")
      dispatch({ type: "SET_USER_DATA", payload: user })
      return user
    } catch (err) {
      setOpen(true)
      setTimeout(() => {
        history.goBack()
      }, 5000)
      console.log(
        "file: ======================== userSummary === src/Modules/summary/Summary.jsx ========================"
      )
      console.log(err)
      return {}
    }
  }

  const resolvePromises = async () => {
    setLoading(true)
    const p1 = userSummary(
      `${baseUrl}/assessment/history/${props.match.params.id}?test=passed`
    )
    const p2 = w(`${baseUrl}/assessment/details/${props.match.params.id}`)
    const p3 = lastAssessmentDate()

    const p4 = getPAPDetails(props.match.params.id)

    const p5 = getHistory()

    await Promise.all([p1, p2, p3, p4, p5])
    setLoading(false)
    // ScrollToID('top-btn-1');

    // if (state.userData?.disorders?.length > 0) {
    //   tourConfig.push(

    //   );
    // }
    if (showTourTooltipBool()) {
      setTimeout(() => {
        setRun(true)
        // document.querySelector('#top-btn-1').classList.add('hover');
        // document.querySelector('#top-btn-2').classList.add('hover');
        // document.querySelector('#top-btn-3').classList.add('hover');
        // document.querySelector('#top-btn-4').classList.add('hover');
        // document.querySelector('#top-btn-5').classList.add('hover');
        setTourOpen(true)
      }, 100)
    }
  }

  useEffect(() => {
    resolvePromises()
    getProgressTracker()
  }, [props.match.params.id])

  const handleJoyrideCallback = (data) => {
    const { status, type } = data
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]

    if (finishedStatuses.includes(status)) {
      setRun(false)
      setTourTooltipViewed()
      // document.querySelector('#top-btn-1').classList.remove('hover');
      // document.querySelector('#top-btn-2').classList.remove('hover');
      // document.querySelector('#top-btn-3').classList.remove('hover');
      // document.querySelector('#top-btn-4').classList.remove('hover');
      // document.querySelector('#top-btn-5').classList.remove('hover');
    }
    // console.log('step occured');
    // console.groupCollapsed(type);
    // console.log('callback', data);
    // console.groupEnd();
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )

  if (state.userData && state.detailsData) {
    return (
      <>
        <SummaryDefinition
          setRun={setRun}
          userData={state.userData}
          detailsData={state.detailsData}
          AssessmentDisorders={AssessmentDisorders}
          fullHistory={fullHistory}
          match={props.match}
          history={props.history}
          loading={loading}
          lastAssessment={lastDate}
          progressTracker={progressTracker}
          downloadPAPHandler={progressTrackerDownloadPAPHandler}
          sharePAPHandler={progressTrackerSharePAPHandler}
          viewPAPHandler={progressTrackerViewPAPHandler}
          downloadAssessmentHandler={progressTrackerDownloadAssessmentHandler}
          shareAssessmentHandler={progressTrackerShareAssessmentHandler}
          viewAssessmentHandler={progressTrackerViewAssessmentHandler}
          reviewDisorderHandler={progressTrackerReviewDisorderHandler}
          reviewScoresHandler={progressTrackerReviewScoresHandler}
          signUpNotifsHandler={progressTrackerSignUpNotifsHandler}
          t={t}
          i18n={i18n}
          defaultTasks={defaultTasks}
          setDefaultTasks={setDefaultTasks}
          googleLoggedin={googleLoggedin}
          googleLogIn={googleLogIn}
          handleSyncEvent={handleSyncEvent}
          syncedEvent={syncedEvent}
          syncEventLoading={syncEventLoading}
          hh={hh}
          mm={mm}
          ss={ss}
          setHH={setHH}
          setMM={setMM}
          setSS={setSS}
          assessmentEventDate={assessmentEventDate}
          setAssessmentEventDate={setAssessmentEventDate}
          isEventValid={isEventValid}
          syncNowMinRef={syncNowMinRef}
          syncNowSecRef={syncNowSecRef}
        />
        {!loading /* &&  showTourTooltipBool() */ && (
          <Joyride
            locale={{
              back: t("assessmentHistory.prevButton"),
              close: "Close",
              last: t("assessmentHistory.finishButton"),
              next: t("assessmentHistory.nextButton"),
              open: "Open the dialog",
              skip: t("assessmentHistory.skipButton"),
            }}
            callback={handleJoyrideCallback}
            continuous={!!true}
            run={false}
            autoStart={false}
            scrollToFirstStep={!!true}
            showProgress={!!false}
            showSkipButton={!!true}
            steps={tourConfig}
            floaterProps={{
              options: { disableAnimation: true },
              disableAnimation: true,
            }}
            styles={{
              options: {
                zIndex: 10000,
                backgroundColor: "#e3ffeb",
                primaryColor: "#33CA8F",
              },
            }}
          />
        )}
      </>
    )
  }
  return (
    <>
      <div
        className="text-center"
        style={{
          display: "flex",
          height: "100vh",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="spinner-border assessmentSpinner"
          role="status"
          style={{
            width: "2rem",
            height: "2rem",
            border: "0.25em solid currentColor",
            borderRightColor: "transparent",
          }}
        />
        <span className="h1 ml-2">{t("summaryPage.loading")}</span>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Note archived"
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity="error">
          You're not authorized to access this page
        </Alert>
      </Snackbar>
    </>
  )
}

export default withTranslation()(Summary)

export class SummaryDefinition extends Component {
  constructor(props) {
    super(props)
  }

  state = {
    click: true,
    readMoreLessButton: true,
    modalState: true,
    auth: true,
    lang: store.get("lang") ? store.get("lang") : "en",
    userData: null,
    detailsData: null,
    showChecklist: false,
    showFloatingButton: true,
    showNotificationSettings: false,
    notificationFrequency: "every week",
    url: "",
    showSendAssessmentForm: false,
    showShareAssessmentForm: false,
    loading: true,
    lastAssessment: null,
    modalMessage: "",
    currentTherapyModal: "",
    currentTileName: "",
    therapyData: {
      loading: true,
      data: {},
      error: "",
      completed: false,
    },
  }

  handleTherapyModalClick = (therapy) => {
    this.setState({ currentTherapyModal: { therapy } })
  }

  handleTileClick = (tile) => {
    console.log(tile)
    this.setState({ currentTileName: { tile } })
  }

  handleToggleSendAssessmentForm = () => {
    this.setState((prevState) => ({
      showSendAssessmentForm: !prevState.showSendAssessmentForm,
    }))
  }

  handleToggleNotificationSettings = () => {
    this.setState((prevState) => ({
      showNotificationSettings: !prevState.showNotificationSettings,
    }))
  }

  handleToggleShareAssessmentForm = () => {
    this.setState((prevState) => ({
      showShareAssessmentForm: !prevState.showShareAssessmentForm,
    }))
  }

  downloadSummary = () => {
    const readMoreButton = document
      .getElementsByClassName("description")[0]
      .parentNode.getElementsByTagName("button")[0]
    this.description("toPDF")
      .then(() =>
        // print after waiting for blurb to open
        setTimeout(() => print("descripbox", "html"), 100)
      )
      .then(() =>
        setTimeout(() => (readMoreButton.style.display = "block"), 100)
      ) // put the button back after print
  }

  downloadActionPlan = async () => {
    try {
      console.log("call")
      const response = await fetch(
        `${baseUrl}/pdf/pap/${this.state.userData._id}`,
        {
          credentials: "include",
          // mode: "cors",
          method: "get",
        }
      )
      console.log(response, "RESPONSE")
      const blob = await response.blob()
      logEvent("downloadActionPlan /pdf/pap called")
      console.log(blob, "BLOB")
      this.download(blob, `ActionPlan_${this.state.userData._id}.pdf`)
    } catch (err) {
      console.log(
        "file: ======================== downloadActionPlan === src/Modules/summary/Summary.jsx ========================"
      )
      console.log(err)
    }
  }

  showInOtherTab = (blob) => {
    const url = window.URL.createObjectURL(blob)
    window.open(url)
  }

  download = (blob, filename) => {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.style.display = "none"
    a.href = url
    // the filename you want
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  changeLanguage = () => {
    this.props.i18n.changeLanguage(this.state.lang === "en" ? "fr" : "en")
    this.setState({ lang: this.state.lang === "en" ? "fr" : "en" })
    store.set("lang", this.state.lang === "en" ? "fr" : "en")
  }

  mwAccordion = (id) => {
    const icon = document.getElementById(`icon${id}`)
    document.getElementById(`heading${id}`).attributes[4].value === "false"
      ? icon.classList.add("fa-chevron-up")
      : icon.classList.remove("fa-chevron-up")
  }

  description = (toPDF) => {
    if (toPDF) {
      this.setState({ readMoreLessButton: false }) // open the Descriptions blurb
      document
        .getElementsByClassName("description")[0]
        .parentNode.getElementsByTagName("button")[0].style.display = "none"
      return new Promise((resolve) => {
        resolve("ready!")
      })
    }
    this.setState({
      readMoreLessButton: !this.state.readMoreLessButton,
    })
  }

  componentDidMount() {
    getSettings(this.getInitialSetting)
    if (
      store.get("lang") ? store.get("lang") : this.props.i18n.language !== "en"
    ) {
      this.setState({ lang: store.get("lang") ? store.get("lang") : "en" })
      this.props.i18n.changeLanguage(
        store.get("lang") ? store.get("lang") : "en"
      )
    }
    call("post", `${baseUrl}/education/get/all`, {}, {})
      .then((res) => {
        const { data } = res
        this.setState({
          therapyData: {
            loading: false,
            data,
            error: "",
            completed: true,
          },
        })
      })
      .catch((error) => {
        console.error(error)
        this.setState({
          therapyData: {
            loading: false,
            data: {},
            error: error.message,
            completed: true,
          },
        })
      })
  }

  componentDidUpdate() {
    if (this.state.lang !== this.props.i18n.language) {
      this.setState({ lang: this.props.i18n.language })
      store.set("lang", this.props.i18n.language)
    }
  }

  handleMainCollapse = (icon, heading) => {
    const favIcon = document.getElementById(icon)
    const cardHeader = document.getElementById(heading)
    if (cardHeader.attributes[3].value === "false") {
      cardHeader.classList.add("bgColorCard-header")
      favIcon.classList.add("fa-chevron-up", "noneBorder")
      cardHeader.classList.remove("summaryMainAccordionBackground")
    } else {
      cardHeader.classList.remove("bgColorCard-header")
      favIcon.classList.remove("fa-chevron-up")
      cardHeader.classList.add("summaryMainAccordionBackground")
    }
  }

  showFloatingButton = () => {
    this.setState((state) => ({ showFloatingButton: false }))
  }

  showCheckList = (isOpen) => {
    this.setState((state) => ({ showChecklist: isOpen }))
  }

  getInitialSetting = (freq, url) => {
    this.setState({ notificationFrequency: freq, url })
  }

  showNotificationSettings = (isOpen) => {
    getSettings(this.getInitialSetting)
    this.setState((state) => ({ showNotificationSettings: isOpen }))
  }

  closeEndSessionNotification = () => {
    const setTimerWrapper = this.setTimer.bind(this)
    const viewPopupWrapper = this.viewPopup.bind(this)
    const getAllData = this.getAllData.bind(this)

    fetchAssessmentTimestamp(setTimerWrapper, viewPopupWrapper, getAllData)

    this.setState((state) => ({ isPopupVisible: false }))
  }

  reviewScoreModalMessage = (disorders) => {
    if (disorders != "") {
      this.setState({ modalMessage: "This is the progress chart" })
    } else {
      this.setState({ modalMessage: "Not at Risk for Any Disorders" })
    }
  }

  fall = () => <div>aad</div>

  render() {
    const { props } = this
    const { t, i18n, setRun } = this.props
    const { modalMessage, lang } = this.state
    moment.locale(lang)
    const assessment_id = this.props.match.params.id
    return (
      <ErrorBoundary FallbackComponent={ErrorScreen}>
        <>
          <section
            css={css`
              background-color: #ecf8f8;
            `}
          >
            <div
              css={css`
                position: relative;
                z-index: 20;
              `}
            >
              <div className="no-print">
                <Header
                  customWidth="95%"
                  customMaxWidth="95em"
                  education_id={this.props.detailsData?._id}
                  setRun={setRun}
                  fromSummary={!!true}
                />
              </div>
            </div>

            <img
              src={BgImg}
              css={css`
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
              `}
              loading="lazy"
            />

            <EBlockWrapper>
              <EMainBlock>
                <ETopMainCaption>
                  <h1>{t("summaryPage.cardTitle")}</h1>

                  {props.lastAssessment && (
                    <EDateCaption
                      css={css`
                        font-size: 18px;
                        flex: 1;
                        @media (min-width: 1201px) {
                          justify-self: center;
                        }
                      `}
                    >
                      <div className="assessment-event-container">
                        <span className="summary-center-title-span">
                          {moment(props.lastAssessment).isBefore(moment()) ? (
                            <>{t("summaryPage.cardDatePast")}</>
                          ) : (
                            <>
                              {t("summaryPage.cardDate")}{" "}
                              {
                                moment(new Date(props.lastAssessment)).format(
                                  "MMM DD, YYYY"
                                )
                                // date.format(
                                //   new Date(props.lastAssessment),
                                //   'MMM DD, YYYY',
                                // )
                              }
                            </>
                          )}
                          .
                          <div
                            className="sync-event-container"
                            style={{ display: "none" }}
                          >
                            {moment(props.lastAssessment).isBefore(moment()) ? (
                              <span>
                                <Link to="/assessment">{t("startNow")}</Link>
                              </span>
                            ) : (
                              <span>
                                {/* {(props.syncEventLoading
                                    ?
                                    <i class="fas fa-circle-notch fa-spin"></i>
                                    :
                                    !props.googleLoggedin
                                      ?
                                      <span style={{ cursor: "pointer" }} onClick={() => { props.googleLogIn(); props.setSyncEventLoading(true) }} >
                                        {t('login')}
                                      </span>
                                      :
                                      props.syncedEvent
                                        ?
                                        <div className='synced-tooltip-part' style={{ cursor: 'pointer' }} >
                                          <span style={{ color: 'rgb(51, 202, 143)' }}>{t('scheduled')}</span>
                                          <div className='synced-tooltip tooltip-top'>
                                            <p>on {props.assessmentEventDate.toDateString()} at {`${props.hh}:${props.mm}`}</p>
                                          </div>
                                        </div>
                                        :
                                        <nav className="navbar-expand more-dropdown-part summary-part-more" data-tut="more-btn">
                                          <div className="collapse navbar-collapse">
                                            <ul className="navbar-nav">
                                              <li className="nav-item dropdown" id='sync-calendar-btn'>
                                                <a className="nav-link dropdown-toggle p-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                  <span style={{ color: 'rgb(51, 202, 143)' }}>
                                                    {t('scheduleNow')}
                                                  </span>
                                                </a>
                                                <div className="dropdown-menu">
                                                  <form className="accordion">
                                                    <div className={`link-part schedule-hover-part `} data-tut="schedule-more-btn">
                                                      <a
                                                        className={`schedule-link`}
                                                        // data-toggle="collapse"
                                                        data-target={`#collapse_assessment_event`}
                                                        aria-expanded="true"
                                                        aria-controls={`collapse_assessment_event`}
                                                      >
                                                        {t('selectDateTime')}
                                                      </a>
                                                      <div id={`collapse_assessment_event`} className="collapse inner-lock show" data-parent={`#ax-example_assessment_event`}>
                                                        <div className='input-part'>
                                                          <DatePicker
                                                            // disabled={updateGoalLoading}
                                                            selected={props.assessmentEventDate}
                                                            onChange={(date, e) => { props.setAssessmentEventDate(date); e.stopPropagation() }}
                                                            customInput={<input type='text' placeholder={`Custom placeholder`} className='dropdown-input' />}
                                                            minDate={props.lastAssessment}
                                                          // customInput={<button className="btn z-index-0" data-tut="calendar-btn"/>}
                                                          />
                                                        </div>
                                                        <div className='input-part time-part'>
                                                          <input type='text' placeholder='hh' value={props.hh} onChange={e => props.setHH(e.target.value)} />
                                                          <input type='text' placeholder='mm' value={props.mm} onChange={e => props.setMM(e.target.value)} ref={props.syncNowMinRef} />
                                                          <input type='text' placeholder='ss' value={props.ss} onChange={e => props.setSS(e.target.value)} ref={props.syncNowSecRef} />
                                                        </div>
                                                        <button
                                                          onClick={() => props.handleSyncEvent()}
                                                          className={`sync-btn ${!props.isEventValid() || props.syncedEvent ? 'disable' : ''}`}
                                                          disabled={!props.isEventValid()}
                                                        >
                                                          {t('scheduleNow')}
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </form>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </nav>)} */}
                              </span>
                            )}
                          </div>
                        </span>
                      </div>
                    </EDateCaption>
                  )}

                  {props.userData && (
                    <EDateCaption
                      css={css`
                        @media (min-width: 1201px) {
                          justify-self: flex-end;
                        }
                      `}
                    >
                      <img
                        src={GreenCheckmarkSVG}
                        css={css`
                          height: 18px;
                          width: 18px;
                          margin: 0 0.3em 0 0;
                        `}
                      />
                      <span style={{ textTransform: "capitalize" }}>
                        {moment(new Date(props.userData.completed_at)).format(
                          "MMM DD, YYYY"
                        )}
                        {/* {date.format(
                          // new Date(state.userData.completed_at),
                          new Date(props.userData.completed_at),
                          'MMM DD, YYYY',
                        )} */}{" "}
                        {lang === "en" ? "at" : "à"}{" "}
                        {moment(new Date(props.userData.completed_at)).format(
                          "hh:mm A"
                        )}
                      </span>
                    </EDateCaption>
                  )}
                </ETopMainCaption>

                {this.props.loading && (
                  <div className="text-center my-5">
                    <div
                      className="spinner-border assessmentSpinner"
                      role="status"
                      style={{
                        width: "2rem",
                        height: "2rem",
                        border: "0.25em solid currentColor",
                        borderRightColor: "transparent",
                      }}
                    />
                    <span className="h1 ml-2">{t("summaryPage.loading")}</span>
                  </div>
                )}

                {!this.props.loading && (
                  <>
                    <TopButtons
                      // id={this.state.userData._id}
                      id={this.props.userData._id}
                      assessment_id={assessment_id}
                      detailsData={this.props.detailsData}
                      userData={this.props.userData}
                      progressTracker={this.props.progressTracker}
                      downloadPAPHandler={this.props.downloadPAPHandler}
                      sharePAPHandler={this.props.sharePAPHandler}
                      viewPAPHandler={this.props.viewPAPHandler}
                      downloadAssessmentHandler={
                        this.props.downloadAssessmentHandler
                      }
                      viewAssessmentHandler={this.props.viewAssessmentHandler}
                      shareAssessmentHandler={this.props.shareAssessmentHandler}
                      reviewDisorderHandler={this.props.reviewDisorderHandler}
                      reviewScoresHandler={this.props.reviewScoresHandler}
                      signUpNotifsHandler={this.props.signUpNotifsHandler}
                      reviewScoreModalMessage={this.reviewScoreModalMessage}
                      fullHistory={this.props.fullHistory}
                      assessmentDisorders={this.props.AssessmentDisorders}
                      currentTileHandler={this.handleTileClick}
                      onToggleNotifsForm={this.handleToggleNotificationSettings}
                      onToggleShareAssessmentForm={
                        this.handleToggleShareAssessmentForm
                      }
                      onToggleSendAssessmentForm={
                        this.handleToggleSendAssessmentForm
                      }
                      onToggleScoreModal={this.handleToggleScoreModal}
                      t={t}
                      i18n={i18n}
                      status={this.props.userData.status}
                    />
                    <EGreyDividerLine />
                    
                    {!!this.props.userData && !!this.props.userData.disorders && (
                      <div
                        css={css`
                          margin: 1.5em;
                        `}
                      >
                        <EH2
                          css={css`
                            margin: 0.5em 0 0.5em 0;
                          `}
                        >
                          {t("summaryPage.disorder")}
                        </EH2>
                        <EH3>{t("summaryPage.disorderDescription")}</EH3>
                        <div className="result-data-display">
                          <p className="mt-3">
                            {t(
                              "summaryPage.tilesInfoModal.pap.description.line1.text1"
                            )}
                            <strong>
                              {t(
                                "summaryPage.tilesInfoModal.pap.description.line1.strong"
                              )}
                            </strong>
                            {t(
                              "summaryPage.tilesInfoModal.pap.description.line1.text2"
                            )}
                          </p>
                          <p className="mt-3">
                            {t(
                              "summaryPage.tilesInfoModal.pap.description.line2"
                            )}
                          </p>
                          <p className="mt-3">
                            {t(
                              "summaryPage.tilesInfoModal.pap.description.line3"
                            )}
                          </p>
                          <p className="mt-3">
                            <strong>
                              {t(
                                "summaryPage.tilesInfoModal.pap.description.point1A.title"
                              )}
                            </strong>
                          </p>
                          <p>
                            {t(
                              "summaryPage.tilesInfoModal.pap.description.point1A.a1"
                            )}{" "}
                            &nbsp;
                            {t(
                              "summaryPage.tilesInfoModal.pap.description.point1A.text"
                            )}
                          </p>
                          <p className="mt-3">
                            <strong>
                              {t(
                                "summaryPage.tilesInfoModal.pap.description.point2A.title"
                              )}
                            </strong>
                          </p>
                          <p>
                            {t(
                              "summaryPage.tilesInfoModal.pap.description.point2A.a2"
                            )}{" "}
                            &nbsp;
                            {t(
                              "summaryPage.tilesInfoModal.pap.description.point2A.text"
                            )}
                          </p>
                          <p className="mt-3">
                            <strong>
                              {t(
                                "summaryPage.tilesInfoModal.pap.description.point3A.title"
                              )}
                            </strong>
                          </p>
                          <p>
                            {t(
                              "summaryPage.tilesInfoModal.pap.description.point3A.a3"
                            )}{" "}
                            &nbsp;
                            {t(
                              "summaryPage.tilesInfoModal.pap.description.point3A.text"
                            )}
                          </p>
                        </div>

                        {this.props.userData.disorders.map((i, index) => (
                          <Accordion
                            key={`disordersAccordion${index}`}
                            headerText={i.name}
                            riskStatus={i.riskStatus}
                            score={i.score}
                            lang={i18n.language}
                          >
                            <Disorder
                              description={i.description}
                              medication={i.medication}
                              name={i.name}
                              questions={i.questions}
                              riskStatus={i.riskStatus}
                              score={i.score}
                              support={i.support}
                              symptomes={i.symptomes}
                              therapy={i.therapy}
                              tips={i.tips}
                              lang={this.state.lang}
                              resources={i.resources || []}
                              handleTherapyModalClick={
                                this.handleTherapyModalClick
                              }
                              defaultTasks={props.defaultTasks}
                              setDefaultTasks={props.setDefaultTasks}
                            />
                            <Link
                              onClick={() => {
                                logClickEvent(
                                  "Total # Clicks on “Show More” Link",
                                  8,
                                  i.name
                                )
                              }}
                              className="ml-2"
                              style={{
                                padding: "1.1em",
                                textDecoration: "underline",
                              }}
                              state={{
                                name:
                                  i.name == "Mania/Hypomania"
                                    ? "Bipolar Disorder"
                                    : i.name,
                              }}
                              to={{
                                pathname: `/pap/${assessment_id}`,
                                state: { name: i.name },
                              }}
                            >
                              {t("summaryPage.showMore")}
                            </Link>
                          </Accordion>
                        ))}
                        {this.props.userData?.disorders.length > 0 ? (
                          <div />
                        ) : (
                          <EH3>{t("summaryPage.notAtRisk")}</EH3>
                        )}
                      </div>
                    )}

                    <EGreyDividerLine />

                    {!!this.props.userData && !!this.props.userData.disorders && (
                      <div
                        css={css`
                          margin: 1.5em;
                        `}
                      >
                        <EH2
                          css={css`
                            margin: 0.5em 0 0.5em 0;
                          `}
                        >
                          {t("summaryPage.mentalWellness")}
                        </EH2>

                        {this.props.userData &&
                          this.props.userData.mentalwellness && (
                            <MentalWellness
                              t={t}
                              mentalwellness={
                                this.props.userData.mentalwellness
                              }
                              data={this.state.therapyData}
                              lang={this.state.lang}
                              handleTherapyModalClick={
                                this.handleTherapyModalClick
                              }
                            />
                          )}
                      </div>
                    )}
                    <EGreyDividerLine />

                    <div
                      css={css`
                        margin: 1.5em;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-column-gap: 2em;
                        @media (max-width: 1200px) {
                          grid-template-columns: 1fr;
                        }
                      `}
                    >
                      {this.props.userData &&
                        this.props.userData.health_history_responses && (
                          <div>
                            <EH2
                              css={css`
                                margin: 0.5em 0 0.5em 0;
                              `}
                            >
                              {t("summaryPage.healthHistory")}
                            </EH2>
                            <SummaryHealthHistory
                              health_history_responses={
                                this.props.userData.health_history_responses
                              }
                              lang={this.state.lang}
                            />
                          </div>
                        )}

                      {this.props.userData &&
                        this.props.userData.life_functions_responses && (
                          <div>
                            <EH2
                              css={css`
                                margin: 0.5em 0 0.5em 0;
                              `}
                            >
                              {t("summaryPage.lifeFunctions")}
                            </EH2>

                            <SummaryLifeFunctions
                              life_functions_responses={
                                this.props.userData.life_functions_responses
                              }
                              lang={this.state.lang}
                            />
                          </div>
                        )}
                    </div>
                  </>
                )}
              </EMainBlock>
            </EBlockWrapper>
          </section>
          <ClinicalAdvisoryCard
            name="Assessment Scores"
            bio={modalMessage}
            imgSource="summary/modalImage.png"
          />
          <DisorderTherapyModal
            lang={this.state.lang}
            therapyName={this.state.currentTherapyModal}
            data={this.state.therapyData}
          />
          <LearnMoreModal tileName={this.state.currentTileName} />

          {/* {this.state.showFloatingButton && (
         <FloatingActionButton
           showFloatingButton={this.showFloatingButton}
           showCheckList={this.showCheckList}
           showNotificationSettings={this.showNotificationSettings}
         />
       )} */}
          {this.state.showNotificationSettings && (
            <NotificationScheduler
              onClose={this.handleToggleNotificationSettings}
              signUpNotifsHandler={this.props.signUpNotifsHandler}
              t={t}
              i18n={i18n}
            />
          )}
          {this.state.showSendAssessmentForm && (
            <SendAssessmentPdf
              onClose={() => {
                this.handleToggleSendAssessmentForm()
              }}
              assessment_id={assessment_id}
              detailsData={this.props.detailsData}
              userData={this.props.userData}
              sharePAPHandler={this.props.sharePAPHandler}
              assessmentDisorders={this.props.AssessmentDisorders}
              fullHistory={this.props.fullHistory}
            />
          )}
          {this.state.showShareAssessmentForm && (
            <ShareAssessment
              onClose={() => this.handleToggleShareAssessmentForm()}
              assessment_id={assessment_id}
              // userData={this.props.detailsData}
              detailsData={this.props.detailsData}
              userData={this.props.userData}
              fullHistory={this.props.fullHistory}
              assessmentDisorders={this.props.AssessmentDisorders}
              shareAssessmentHandler={this.props.shareAssessmentHandler}
              t={t}
              i18n={i18n}
            />
          )}
          {this.state.showChecklist && (
            <ProgressTracker
              closeCheckList={this.showCheckList}
              assessment_id={assessment_id}
            />
          )}

          <Footer customClass="custom-w" fromSummary={!!true} />
          <ReactSlidingOutcomePanel />
        </>
      </ErrorBoundary>
    )
  }
}
