import React, { useContext, useLayoutEffect, useEffect, useState } from "react"
import { StateContext } from "../../store/state/contexts"
import _get from "lodash/get"
import { withTranslation } from "react-i18next"
import { clearAPICache } from "../../utils/call"

class Component extends React.Component {
  state = { finishDisable: false, isDisabled: false, skipDisable: false }
  handlePrevClick = () => {
    this.props.nextPrevBtnMethod(
      this.props.skipInDepth && this.props.tab === 3
        ? this.props.tab - 2
        : this.props.tab - 1
    )
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
  handlePrevClickInDepthTab1 = () => {
    alert(
      "Sorry, you cannot edit the screening questions after they have been submitted."
    )
    this.setState({ isDisabled: true })
  }

  prevBtn = (props) => {
    return (
      <button className="btn ml-3 prevBtn" onClick={this.handlePrevClick}>
        {props.t("assessmentHistory.prevButton") }
      </button>
    )
  }
  prevBtnInDepthTab1 = (props) => {
    return (
      <button
        className="btn ml-3 prevBtn"
        onClick={this.handlePrevClickInDepthTab1}
        disabled={this.state.isDisabled}
      >
        {props.t("assessmentHistory.prevButton") }
      </button>
    )
  }

  // componentDidMount() {
  //   this.props.handleTabSet(2)
  // }

  clickHandler = () => {
    if (
      _get(this.props, ["firstUnansweredQuestion", "tab"]) === this.props.tab
    ) {
      if (_get(this, ["props", "firstUnansweredQuestion", "subTab"])) {
        this.props.onSetIndepthTab(
          _get(this, ["props", "firstUnansweredQuestion", "subTab"])
        )
      }

      this.props.handleTabSet(this.props.firstUnansweredQuestion.tab)
    } else if(this.props.tab == 2 && this.props.indepthTab < this.props.inDepthQuestionCount) {
      this.props.onSetIndepthTab(this.props.indepthTab + 1)
    } else {
      this.props.nextPrevBtnMethod(
        this.props.skipInDepth && this.props.tab === 1
          ? this.props.tab + 2
          : this.props.tab + 1
      )
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }

    if (_get(this.props, ["firstUnansweredQuestion", "subTab"])) {
      if (
        _get(this.props, ["firstUnansweredQuestion", "tab"]) ===
          this.props.tab &&
        _get(this.props, ["firstUnansweredQuestion", "subTab"]) ===
          this.props.indepthTab
      ) {
        this.props.onScrollToFirstUnansweredQuestion()
      }
    } else {
      if (
        _get(this.props, ["firstUnansweredQuestion", "tab"]) === this.props.tab
      ) {
        this.props.onScrollToFirstUnansweredQuestion()
      }
    }
  }

  calcNextButtonStyle = () => {
    if (
      _get(this.props, ["firstUnansweredQuestion", "tab"]) === this.props.tab &&
      this.props.tab !== 2
    ) {
      return "btn ml-3 prevBtn"
    }

    if (
      this.props.tab === 2 &&
      _get(this, ["props", "firstUnansweredQuestion", "subTab"]) ===
        this.props.indepthTab
    ) {
      return "btn ml-3 prevBtn"
    }

    return "btn ml-3 nextBtnEnabled"
  }

  render() {
    const props = this.props
    const nextButtonStyle = this.calcNextButtonStyle()
    const { fromFollowUp, history } = props
    // console.log(props.mainProgress, ' <=== tab in next prev btn...')


    return (
      <div>
        {props.loading ? null : (
          <div className="row my-3 nextPrevBtnDiv">
            {props.tab <= 1 ? null : (
              <div className="d-flex">
                {props.tab === 2 ? (
                  props.indepthTab < 1 ? (
                    this.prevBtn(props)
                  ) : props.indepthTab === 1 ? (
                    this.prevBtnInDepthTab1(props) //indepth prev button
                  ) : (
                    <button
                      className="btn ml-3 prevBtn"
                      onClick={() => {
                        props.nextPrevIndepthBtnMethod(props.indepthTab - 1)
                        document.body.scrollTop = 0
                        document.documentElement.scrollTop = 0
                      }}
                    >
                      {props.t("assessmentHistory.prevButton") }
                    </button>
                  )
                ) : (
                  this.prevBtn(props)
                )}

                {((props.mainProgress === 100 || props.lastAnswered) && props.tab === 5) || (history && history.length < 1 && props.tab === 4) ? (
                  this.state.finishDisable ? (
                    <button className="btn ml-3 CompleteAssessmentBtnAfter">
                      <div className="d-flex align-items-center justify-content-center">
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span> {props.t("assessmentHistory.finishingLabel") }</span>
                      </div>
                    </button>
                  ) : (
                    <button
                      className="btn ml-3 CompleteAssessmentBtn"
                      onClick={() => {
                        if(props.mainProgress === 100 || props.lastAnswered) {
                          props.CompleteAssessment()
                          this.setState({ finishDisable: true })
                        }
                      }}
                    >
                      {props.t("assessmentHistory.finishButton") }
                    </button>
                  )
                ) : null}

                {(props.mainProgress !== 100 && !props.lastAnswered) && props.tab === 5 ? (
                  this.state.skipDisable ? (
                    <button className="btn ml-3 CompleteAssessmentBtnAfter">
                      <div className="d-flex align-items-center justify-content-center">
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span>{props.t("assessmentHistory.skippingLabel") }</span>
                      </div>
                    </button>
                  ) : (
                    <button
                      className="btn ml-3 CompleteAssessmentBtn"
                      onClick={() => {
                        props.CompleteAssessment()
                        this.setState({ skipDisable: true })
                      }}
                    >
                      {props.t("assessmentHistory.skipButton") }
                    </button>
                  )
                ) : null}

              </div>
            )}

            {props.tab < (history && history.length > 0 ? 5 : 4) && (
              <button className={nextButtonStyle} onClick={this.clickHandler}>
                {props.t("assessmentHistory.nextButton") }
              </button>
            )}
            {!fromFollowUp && 
              <p style={{ margin: 10 }}>{props.t("assessmentHistory.changeQuestionHint") }</p>
            }
          </div>
        )}
      </div>
    )
  }
}

const NextPrevBtn = (props) => {
  const { state, dispatch } = useContext(StateContext)

  let skipInDepth = false

  try {
    const indepth =
      state.questionsToShow.questions.in_depth_questions.questions[
        props.indepthTab - 1
      ].questions
  } catch (err) {
    skipInDepth = props.tab === 1 || props.tab === 3
  }

  let lastAnswered = false
  let followUpQestions = (state?.questionsToShow?.questions?.follow_up_questions?.questions) || []
  if(followUpQestions && Array.isArray(followUpQestions)){
    followUpQestions.map((q, index) => {
      if(index === followUpQestions.length - 1 && q.isAnswered){
        lastAnswered = true
      }
    })
  }

  // console.log(skipInDepth)
  if (!_get(state, "assessment.questions.health_history.questions")) {
    return null
  }

  return (
    <Component
      mainProgress={state.percentage.main}
      skipInDepth={skipInDepth}
      firstUnansweredQuestion={state.firstUnansweredQuestion}
      lastAnswered={lastAnswered}
      {...props}
    />
  )
}

const NextPrevFun = (props) => {
  const { state, dispatch } = useContext(StateContext);
  let indepthQuestions = []

  try {
    indepthQuestions = state.questionsToShow.questions.in_depth_questions.questions;
  } catch (err) {}
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <NextPrevBtn {...props} inDepthQuestionCount={indepthQuestions.length} />
  );
};

export default withTranslation()(NextPrevFun);
