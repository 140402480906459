import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { baseUrl } from '../../../lib/constants';

function getQuestion(from, t) {
  switch (from) {
    case 'summary': return t('rating.question1');
    case 'pap': return t('rating.question3');
    case 'resources': return t('rating.question3');
    default: return '';
  }
}

function getGeneralQuestion(from, t) {
  switch (from) {
    case 'summary': return t('rating.generalQuestion1');
    case 'pap': return t('rating.generalQuestion1');
    case 'resources': return t('rating.generalQuestion2');
    default: return '';
  }
}

export default function FeedbackModel({ 
  show, handleClose, from = 'summary', t, assessmentId
}) {
  const [notes, setNotes] = useState('');
  const [rating, setRating] = useState(5);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if(!show) {
      const el = document.getElementsByTagName('body')
      if(el) {
        el.style = null
        el.class = null
      }
    }
  }, [show])

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await axios.post('/feedbacks', {
        rating,
        comment: notes,
        from,
        assessmentId:assessmentId || null,
      }, { baseURL: baseUrl });
      toast.success('Feedbacks submitted successfully');
    } catch (error) {
      console.log(error, ' <=== Error while submitting feedbacks');
      toast.error(error?.response?.data?.message || error?.message);
      setError(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose} className="rating-emoji-modal" size="md">
      <Modal.Header closeButton>
        <Modal.Title>{t("rating.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="body-data-title">{getQuestion(from, t)}</p>
        <div className="radio-btn-group">
          <form id="smileys">
            <input
              type="radio"
              name="rating"
              value={1}
              checked={rating === 1}
              onChange={() => setRating(1)}
              className="vsad"
            />
            <input
              type="radio"
              name="rating"
              value={2}
              checked={rating === 2}
              onChange={() => setRating(2)}
              className="sad"
            />
            <input
              type="radio"
              name="rating"
              value={3}
              checked={rating === 3}
              onChange={() => setRating(3)}
              className="neutral"
            />
            <input
              type="radio"
              name="rating"
              value={4}
              checked={rating === 4}
              onChange={() => setRating(4)}
              className="happy"
            />
            <input
              type="radio"
              name="rating"
              value={5}
              checked={rating === 5}
              onChange={() => setRating(5)}
              className="vhappy"
            />
          </form>

        </div>
        <p>{getGeneralQuestion(from, t)}</p>
        <textarea
          onChange={(e) => setNotes(e.target.value)}
          value={notes}
          placeholder="Notes"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="submit-btn" onClick={handleSubmit} disabled={loading}>
          <p className="mb-0">{loading ? 'Loading..' : t('rating.btnText')}</p>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
